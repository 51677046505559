/***************************************************************************************************************
||||||||||||||||||||||||||       MASTER STYLESHEET FOR SOLARTECH             |||||||||||||||||||||||||||||||||||
****************************************************************************************************************
||||||||||||||||||||||||||                TABLE OF CONTENT               |||||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
****************************************************************************************************************

01. Imported styles
02. Flaticon reset styles
03. Header styles
04. Mainmenu styles
05. Rev_slider_wrapper styles
66. Call to action area Style
07. Categories area style
08. Fact counter area style
09. Latest Blog area style
10. Latest blog area style 
11. Testimonial area style
12. Brand area style
13. Single service style
14. Pricing table area style
15. About page style
16. Account page style
17. 404 page style style
18. Blog page style
19. Blog single style
20. Contact page style

****************************************************************************************************************
||||||||||||||||||||||||||||            End TABLE OF CONTENT                ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************/

/*==============================================
   Base Css
===============================================*/

html,
body { 
    height: 100%;
}
body {
    font-family: 'TrebuchetMS', sans-serif;
    color: #83888d;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
}
button:focus{
    outline: none;
}
button {
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
}
h1, h2, h3, h4, h5, h6 {
    color: #d16111;
    font-weight: 700;
    font-family: 'TrebuchetMS', sans-serif;
    margin: 0;
}
h3 {
    font-size: 20px; 
    line-height: 26px;
}
h4 {

}
a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    outline: none;
    border: none;
}
.fix{
    position: relative;
    display: block;
    overflow: hidden;
}





img {
	max-width: 100%;
    height: auto;
}
i {
	font-style: normal;
}
ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}
ol,
li{
    margin: 0;
    padding: 0;    
}
.clr1{
    color: #f36727;
}
.bgclr1{
    background: #f36727;    
}


.secpd1{
    padding: 100px 0;
}
.secpd2{
    padding: 100px 0 60px;
}
.secpd3{
    padding: 100px 0 70px;
}


.btn-one {
    position: relative;
    display: inline-block;
    background: #f3f7fa;
    border-radius: 5px;
    transition: all 500ms ease;
}
.btn-one b {
    position: relative;
    display: inline-block;
    float: left;
    color: #d16111;
    font-size: 14px;
    line-height: 55px;
    text-transform: uppercase;
    font-family: 'TrebuchetMS', sans-serif;
    padding: 0 20px 0;
    transition: all 500ms ease;
}
.btn-one span:before {
    position: relative;
    display: inline-block;
    float: left;
    width: 55px;
    height: 55px;
    border-left: 1px solid #dbe5ec;
    color: #409e35;
    font-size: 20px;
    line-height: 55px;
    font-weight: 400;
    text-align: center;
}
.btn-one:hover{
    background: #dbe5ec;
}
.btn-one:hover span:before{
    border-color: #d1dce4;    
}
.btn-one:hover b{
    color: #409e35;
}

.btn-two{
    color: #d16111;
    font-size: 14px;
    line-height: 20px;;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
}
.btn-two span:before {
    position: relative;
    display: inline-block;
    top: 4px;
    padding-left: 8px;
    font-size: 20px;
    line-height: 14px;
}
.btn-two:hover{
    color: #e9b531;
}


.btn-three {
    position: relative;
    display: inline-block;
    background: #ffffff;
    color: #d16111;
    font-size: 14px;
    line-height: 62px;
    font-weight: 700;
    height: 60px;
    text-transform: initial;
    font-family: 'TrebuchetMS', sans-serif;
    padding: 0 30px 0;
    border-radius: 5px;
    transition: all 500ms ease;
}
.btn-three span:before {
    position: relative;
    display: inline-block;
    top: 4px;
    padding-left: 9px;
    font-size: 20px;
    font-weight: 400;
}
.btn-three:hover{
    color: #e9b531;
}



.sec-title {
    position: relative;
    display: block;
    margin-top: -4px;
    padding-bottom: 50px;
}
.sec-title.with-text{
    max-width: 770px;
    margin: -4px auto 0;
    padding-bottom: 42px;
}
.sec-title p{
    color: #a0a7ae;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 11px;
}
.sec-title.clrwhite p{
    color: #ffffff;
}
.sec-title .title {
    position: relative;
    display: block;
    color: #d16111;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    margin: 0 0 19px;
    text-transform: inherit;
    font-family: 'TrebuchetMS', sans-serif;
    z-index: 1;
}
.sec-title.clrwhite .title{
    color: #ffffff;    
}
.sec-title .border-box{
    position: relative;
    display: block;
    width: 80px;
    height: 7px;
}
.sec-title .border-box.center{
    margin: 0 auto;
}
.sec-title .border-box:before{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    content: "";
    background: #e9b531;
}
.sec-title .border-box:after{
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 3px;
    content: "";
    background: #e9b531;
}
.sec-title .border-box.center:after{
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.sec-title span {
    display: block;
    color: #83888d;
    font-size: 18px;
    line-height: 28px;
    margin: 24px 0 0;
}
.sec-title.clrwhite span{
    color: #b3cde4;
}

.sec-title .border-box.bgblue{}
.sec-title .border-box.bgblue:before{
    background: #409e35;    
}
.sec-title .border-box.bgblue:after{
    background: #409e35;      
}


.sec-title-style2{
    position: relative;
    display: block;
    padding-bottom: 52px;    
}
.sec-title-style2 .title {
    position: relative;
    top: -9px;
    color: #3a3a3a;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0 0 4px;
}
.sec-title-style2 p{
    color: #83888d;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
}



@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}


.home-page-five-body {
    background-color: #FBFBFB;
}

hr.style-one {
    margin: 0;
    border-top: 2px solid #e5e4e4;
}

.dark-bg {
    background-color: #021f3d;
}

.dark-bg-two {
    background-color: #061F2E;
}

.dark-bg-three {
    background-color: #0a0a0a;
}

.gray-bg {
    background-color: #f5f5f5;
}

.gray-bg-two {
    background-color: #F2F7F5;
}

.gradient-bg {
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #185F8B 0%, #34964D 42%, #91C942 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#185F8B', endColorstr='#91C942', GradientType=1);
    /* ie6-9 */
}

hr.style-one {
    margin: 0;
    border-top: 1px solid #f2f2f2;
}
.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
}

.bg-pos-left-center {
    background-position: left center;
}

.bg-pos-right-center {
    background-position: right center;
}

.sec-pad {
    padding: 100px 0;
}

.sec-pad-with-content-margin-30 {
    padding: 100px 0;
    padding-bottom: 70px;
}

.sec-pad-with-content-margin-70 {
    padding: 100px 0;
    padding-bottom: 30px;
}

.sec-pad-bottom {
    padding-bottom: 100px;
}

.thm-btn {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    background: #FFBA00;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 14px 48px;
    transition: all .4s ease;
}

.thm-btn:hover {
    background: #fff;
    color: #2E2E2E;
}

.thm-btn.style-two {
    background: #fff;
    color: #2E2E2E;
}

.thm-btn.style-two:hover {
    background: #FFBA00;
    color: #2E2E2E;
}

.thm-btn.black-hover:hover {
    color: #fff;
    background: #2E2E2E;
}

.thm-btn.home-three {
    background-color: #F9A03B;
    color: #fff;
}

.thm-btn.home-three i {
    margin-left: 7px;
    font-size: 20px;
    display: inline-block;
    vertical-align: -4px;
}

.thm-btn.home-three:hover {
    background: #28B381;
}

.thm-btn.home-four {
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #185F8B 0%, #34964D 42%, #91C942 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#185F8B', endColorstr='#91C942', GradientType=1);
    /* ie6-9 */
    color: #fff;
    overflow: hidden;
    padding-left: 35px;
    padding-right: 35px;
}

.thm-btn.home-four i {
    margin-left: 7px;
    font-size: 20px;
    display: inline-block;
    vertical-align: -4px;
}

.thm-btn.home-four:before {
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #91C942 0%, #34964D 42%, #185F8B 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#91C942', endColorstr='#185F8B', GradientType=1);
    /* ie6-9 */
}

.thm-btn.home-five {
    border-radius: 0;
    background-color: transparent;
    padding: 15.5px 39px;
    color: #2E2E2E;
    position: relative;
    transition: all .4s ease;
}

.thm-btn.home-five i {
    font-size: 20px;
    display: inline-block;
    vertical-align: -3px;
    margin-left: 10px;
}

.thm-btn.home-five .shape-two,
.thm-btn.home-five .shape-one {
    position: absolute;
    top: 0;
    width: 58.82%;
    height: 100%;
    border: 2px solid #F2C307;
    border-left: 0;
    border-right: 0;
    transition: all .4s ease;
}

.thm-btn.home-five .shape-two::before,
.thm-btn.home-five .shape-one::before {
    content: '';
    width: 23.53%;
    height: 2px;
    background-color: #F2C307;
    position: absolute;
    transition: all .4s ease;
}

.thm-btn.home-five .shape-two:after,
.thm-btn.home-five .shape-one:after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    background: #F2C307;
    transition: all .4s ease;
}

.thm-btn.home-five .shape-one {
    left: 0;
    border-right: 0;
    border-top: 0;
}

.thm-btn.home-five .shape-one::before {
    top: 0;
    left: 0;
}

.thm-btn.home-five .shape-one::after {
    top: 0;
    left: 0;
}

.thm-btn.home-five .shape-two {
    right: 0;
    border-left: 0;
    border-bottom: 0;
}
.thm-btn.home-five .shape-two::before {
    bottom: 0;
    right: 0;
}
.thm-btn.home-five .shape-two:after {
    bottom: 0;
    right: 0;
}
.thm-btn.home-five:hover .shape-one,
.thm-btn.home-five:hover .shape-two {
    border-color: #2E2E2E;
}
.thm-btn.home-five:hover .shape-one::before,
.thm-btn.home-five:hover .shape-two::before {
    width: 100%;
    background-color: #2E2E2E;
}
.thm-btn.home-five:hover .shape-one::after,
.thm-btn.home-five:hover .shape-two::after {
    height: 50%;
    background-color: #2E2E2E;
}
.thm-btn.home-five.style-two {
    background-color: #FFBA00;
}
.thm-btn.home-five.style-two:hover {
    background-color: #2E2E2E;
    color: #fff;
}
.thm-btn.home-five.style-three {
    color: #FFBA00;
    padding-left: 60.5px;
    padding-right: 60.5px;
}
.thm-btn.home-five.style-three:hover {
    color: #2E2E2E;
}
.thm-btn.home-five.style-four {
    color: #2E2E2E;
}
.thm-btn.home-five.style-four .shape-one,
.thm-btn.home-five.style-four .shape-two {
    border-color: #2E2E2E;
}
.thm-btn.home-five.style-four .shape-one::before,
.thm-btn.home-five.style-four .shape-two::before {
    background-color: #2E2E2E;
}
.thm-btn.home-five.style-four .shape-one::after,
.thm-btn.home-five.style-four .shape-two::after {
    background-color: #2E2E2E;
}


.sec-title-two {
    margin-bottom: 40px;
}
.sec-title-two span.tag-line {
    color: #01B9E6;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    font-size: 14px;
}
.sec-title-two h2 {
    margin: 0;
    color: #2E2E2E;
    font-size: 40px;
    line-height: 50px;
    font-weight: 400;
    margin-top: 5px;
}
.sec-title-two h2.light {
    color: #fff;
}
.sec-title-two h2 span {
    font-weight: bold;
}
.sec-title-two h2+p {
    margin-top: 14px;
}
.sec-title-two p {
    color: #83888D;
    font-size: 18px;
    line-height: 28px;
}
.sec-title-three {
    margin-bottom: 45px;
}
.sec-title-three span.tag-line {
    color: #F9A03B;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    font-size: 14px;
}
.sec-title-three h2 {
    margin: 0;
    color: #3A3A3A;
    font-size: 40px;
    line-height: 50px;
    font-weight: 400;
    margin-top: 5px;
}
.sec-title-three h2.light {
    color: #fff;
}
.sec-title-three h2 span {
    font-weight: bold;
}
.sec-title-three h2+p {
    margin-top: 14px;
}
.sec-title-three p {
    color: #83888D;
    font-size: 18px;
    line-height: 28px;
}
.sec-title-three .line {
    display: inline-block;
    vertical-align: middle;
    width: 76px;
    height: 15px;
    border: 3px solid #28b381;
    border-top: 0;
    margin-top: 17px;
}
.sec-title-four {
    margin-bottom: 40px;
}

.sec-title-four h2 {
    margin: 0;
    color: #3A3A3A;
    font-size: 40px;
    line-height: 50px;
    font-weight: 400;
}

.sec-title-four h2.light {
    color: #fff;
}

.sec-title-four h2 span {
    font-weight: bold;
}

.sec-title-four h2+p {
    margin-top: 14px;
}

.sec-title-four p {
    color: #83888D;
    font-size: 18px;
    line-height: 28px;
}

.sec-title-five {
    margin-bottom: 50px;
}

.sec-title-five h2,
.sec-title-five p {
    margin: 0;
}

.sec-title-five h2 {
    color: #2E2E2E;
    font-size: 40px;
    font-weight: 400;
}

.sec-title-five h2 span.text-line {
    background-color: transparent;
    background-image: url(../../../assets/images/home-pages/home-5-sec-title-shape.png);
    background-repeat: no-repeat;
    background-position: center left;
    position: relative;
    padding-left: 50px;
}

.sec-title-five h2 span.bold-text {
    font-weight: 700;
}

.sec-title-five h2.light {
    color: #fff;
}

.sec-title-five h2+p {
    margin-top: 15px;
}

.sec-title-five p {
    color: #83888D;
    font-size: 18px;
    list-style: 28px;
}

.sec-title-six {
    margin-bottom: 65px;
}

.sec-title-six h2 {
    position: relative;
    color: #2E2E2E;
    font-size: 30px;
    font-weight: bold;
}

.sec-title-six h2::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #f2f2f2;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.sec-title-six h2 span {
    background: #FBFBFB;
    position: relative;
    padding-left: 45px;
    padding-right: 45px;
}

.scroll-to-top-home-three {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #F9A03B;
    border-radius: 5px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    text-align: center;
    transition: all .4s ease;
    display: none;
}

.scroll-to-top-home-three i {
    font-size: 31px;
    line-height: 60px;
    color: #fff;
}

.scroll-to-top-home-three:hover {
    background: #3A3A3A;
}

.scroll-to-top-home-three.home-five {
    background-color: #F9C807;
    transition: all .4s ease;
}

.scroll-to-top-home-three.home-five i {
    color: #2E2E2E;
    transition: all .4s ease;
}

.scroll-to-top-home-three.home-five:hover {
    background-color: #2E2E2E;
}

.scroll-to-top-home-three.home-five:hover i {
    color: #fff;
}

.preloader.style-two {
    background-image: url(../../../assets/images/home-pages/preloader-2.gif);
}

.preloader.style-three {
    background-image: url(../../../assets/images/home-pages/preloader-3.gif);
}

.preloader.style-four {
    background-image: url(../../../assets/images/home-pages/preloader-4.gif);
}

.preloader.style-five {
    background-image: url(../../../assets/images/home-pages/preloader-5.gif);
}



/*** 
============================================
	Hidden Sidebar style
============================================
***/
.hidden-bar{
	position: fixed;
	top: 0;
	width: 320px;
	height: 100%;
	background: #061f2e;
	z-index: 9999999999;
	transition: all 700ms ease;
}
.hidden-bar .mCSB_inside > .mCSB_container{
	margin-right:0px;	
}
.hidden-bar.right-align {
	right: -400px;
}
.hidden-bar.right-align.visible-sidebar{
	right:0px;
	transition-delay: 300ms;
}
.hidden-bar .hidden-bar-closer {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    background: none;
    color: #ffffff;
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    transition: all 300ms ease;
    z-index: 999999;
}
.hidden-bar .hidden-bar-closer button {
	background: none;
	display:block;
	font-size: 40px;
	color:#ffffff;
	width:40px;
	height:40px;
	line-height:40px;
}
.hidden-bar .hidden-bar-closer button span:before{
    font-size: 30px;
    line-height: 30px;
}
.hidden-bar .hidden-bar-closer:hover button {
	color: #ffffff;
}

.hidden-bar-wrapper {
	height: 100%;
	padding:30px 10px 30px 30px;
}
.hidden-bar .logo {
	padding:0px 0px 30px;
}
.hidden-bar .logo a{
    display: inline-block;
}
.hidden-bar .logo img{
	display: inline-block;
	max-width: 100%;	
}


.hiddenbar-about-us{
    position: relative;
    display: block;
}
.hiddenbar-about-us h3{
    font-size: 20px;
    color: #ffffff;
    margin: 0 0 18px;
}
.hiddenbar-about-us .text{
    display: block;
}
.hiddenbar-about-us .text p{
    color: #8b9397;
    margin: 0;
}


.hidden-bar .contact-info-box{
    position: relative;
    display: block;
    padding-top: 36px;
}
.hidden-bar .contact-info-box h3{
    font-size: 20px;
    color: #ffffff;
    margin: 0 0 23px;    
}
.hidden-bar .contact-info-box ul{
    position: relative;
    display: block;
}
.hidden-bar .contact-info-box ul li{
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 16px;
}
.hidden-bar .contact-info-box ul li:last-child{
    margin-bottom: 0;
}
.hidden-bar .contact-info-box ul li h5{
    color: #ffffff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    margin: 0 0 11px;
}
.hidden-bar .contact-info-box ul li p{
    color: #8b9397;
    margin: 0;
}


.hidden-bar .newsletter-form-box {
    position: relative;
    display: block;
    padding-top: 36px;
    padding-right: 20px;
}
.hidden-bar .newsletter-form-box h3{
    font-size: 20px;
    color: #ffffff;
    margin: 0 0 24px;  
}
.hidden-bar .newsletter-form-box form{
    position: relative;
    display: block;
}
.hidden-bar .newsletter-form-box form input[type="email"] {
    position: relative;
    display: block;
    width: 100%; 
    height: 50px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    color: #8b8b8b;
    font-size: 15px;
    padding: 0 20px;
    padding-right: 60px;
    transition: all 500ms ease;
}
.hidden-bar .newsletter-form-box form button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    width: 50px;
    text-align: center;
}
.hidden-bar .newsletter-form-box form button:before{
    position: absolute;
    top: 12px;
    left: 0;
    bottom: 12px;
    width: 2px;
    background: #eeeeee;
    content: "";
}
.hidden-bar .newsletter-form-box form button i{
    position: relative;
    top: 2px;
    color: #061f2e;
    font-size: 20px;
    line-height: 20px;
}

.hidden-bar .copy-right-text{
    position: relative;
    display: block;
    padding-top: 44px;
}
.hidden-bar .copy-right-text p{
    color: #8b9397;
    margin: 0;    
}





.hidden-bar.style2{
    background: #0a0a0a;
}




.parallax-bg-one {
    background-attachment: fixed;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.parallax-bg-one::before {
    background: rgba(18, 32, 0, 0.90) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
} 
.parallax-bg-two {
    background-attachment: fixed;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.parallax-bg-two::before {
    background: rgba(9, 23, 33, 0.90) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
} 

.flex-box {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.flex-box-two {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}
.flex-box-three {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.flex-box-four {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.flex-box-five {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.owl-nav-style-one{}
.owl-nav-style-one .owl-controls { }
.owl-nav-style-one.owl-theme .owl-nav [class*="owl-"] {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 50%;
    color: #909090;
    font-size: 24px;
    font-weight: 700;
    height: 50px;
    line-height: 46px;
    margin: 0 0 0 0px;
    padding: 0;
    width: 50px;
    border: 1px solid #f3f3f3;    
    transition: all 700ms ease 0s;
}
.owl-nav-style-one.owl-theme .owl-nav .owl-next {
    margin-left: 10px;
}
.owl-nav-style-one.owl-theme .owl-nav [class*="owl-"]:hover{
    border-color: #172272;
    color: #172272;
}

.owl-nav-style-two{}
.owl-nav-style-two .owl-controls { }
.owl-nav-style-two.owl-theme .owl-nav [class*="owl-"] {
    background: #fff none repeat scroll 0 0;
    border-radius: 0%;
    color: #3740b0;
    font-size: 24px;
    font-weight: 700;
    height: 50px;
    line-height: 50px;
    margin: 0 0 0 0px;
    padding: 0;
    width: 50px;
    transition: all 700ms ease 0s;
}
.owl-nav-style-two.owl-theme .owl-nav .owl-next {
    margin-left: 0px;
}
.owl-nav-style-two.owl-theme .owl-nav [class*="owl-"]:hover{
    color: #ffffff;
    background: #3740b0;
}


.accordion-box {
    position: relative;
    display: block;
    overflow: hidden;
    border-top: 1px solid #f3f7fa;
}
.accordion-box .accordion {
    position: relative;
    display: block;
    overflow: hidden;
}
.accordion-box .accordion .accord-btn {
    position: relative;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid #f3f7fa;
    padding-left: 0px;
    padding-right: 50px;
    padding-top: 21px;
    padding-bottom: 21px;
    transition: all 500ms ease;
}
.accordion-box .accordion .accord-btn h4 {
    color: #d16111;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    transition: all 500ms ease;
}
.accordion-box .accordion .accord-btn::after {
    font-family: "Flaticon";
    position: absolute;
    top: 22px;
    right: 0;
    color: #83888d;
    content: "\f102";
    font-size: 25px;
    line-height: 25px;
    font-weight: 300;
    transition: all 500ms ease 0s;
}
.accordion-box .accordion .accord-btn.active{
    border-bottom: 1px solid #f3f7fa;
    transition: all 500ms ease;
}
.accordion-box .accordion .accord-btn.active h4 {
    color: #e9b531;
}
.accordion-box .accordion .accord-btn.active:after{
    color: #e9b531;
}
.accordion-box .accordion .accord-content {
    position: relative;
    display: block;
    background: #f3f7fa;
    padding-top: 34px;
    padding-bottom: 33px;
    padding-left: 30px;
    padding-right: 60px;
    display: none;
}
.accordion-box .accordion .accord-content.collapsed {
    display: block;
}
.accordion-box .accordion .accord-content p {
    margin: 0;
}





.slick-dots {
	position: relative;
	display: inline-block;
	top: 0px;
    margin-top: 31px;
}
.slick-dots li {
    position: relative;
    display:  inline-block;
    margin: 0 15px;
}
.slick-dots li:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -17px;
    width: 6px;
    height: 1px;
    background: #555555;
    content: "";
    margin: 16px 0;
}
.slick-dots li:last-child:before {
    display: none;
}
.slick-dots li button {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: transparent;
    color: #c2c2c2;
    font-size: 17px;
    font-weight: 500;
    padding-bottom: 0;
    font-family: 'Rubik', sans-serif;
}
.slick-dots li.slick-active button {
    background: #fec144;
	color: #222222;
}


.boxed_wrapper {
    background: #fff none repeat scroll 0 0;
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 320px;
    min-height: 400px;
    overflow: hidden;  
}

/*________________Preloader_______________ */
.preloader{ 
    position:fixed; 
    left:0px; 
    top:0px; 
    width:100%; 
    height:100%; 
    z-index:999999999999999999; 
    background-color:#ffffff; 
    background-position:center center; 
    background-repeat:no-repeat; 
    background-image:url(../../../assets/images/home-pages/preloader-1.gif);
}


/* Update header Style */
@keyframes menu_sticky {
    0%   {margin-top:-100px;}
    50%  {margin-top: -74px;}
    100% {margin-top: 0;}
}
.mainmenu-area.stricky-fixed {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999999999;
    background: #f2cb42;
    margin: 0;
    animation-name: menu_sticky;
    animation-duration: 0.60s;
    animation-timing-function: ease-out;
    transition: all .25s ease-in-out;
}




/* scroll to top styles */
.scroll-to-top {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 60px;
    background: #e9b531;
    border-radius: 0%;
    color: #ffffff;
    font-size: 25px;
    line-height: 56px;
    font-weight: 400;
    cursor: pointer;
    display: none;
    text-align: center;
    transition: all 500ms ease 0s;
    z-index: 999;
    box-shadow: 0px 5px 10px -10px rgba(27,27,27,1);
}
.scroll-to-top:hover {
    opacity: .5;
}


.scroll-to-top-style2 {
    position: fixed;
    bottom: 25px;
    right: 15px;
    background: transparent;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 2px solid #009865;
    color: #009865;
    font-size: 25px;
    line-height: 50px;
    font-weight: normal;
    cursor: pointer;
    display: none;
    text-align: center;
    transition: all 500ms ease 0s;
    z-index: 9999999999999;
    box-shadow: 0px 8px 23px -6px rgba(27,27,27,1);
}
.scroll-to-top-style2:hover {
    opacity: .5;
}



/* Form validation styles */
input:focus,
textarea:focus,
select:focus {
    border-color: #43c3ea;
    outline: none;
}

#contact-form input[type="text"].error{
    border-color: red;    
}
#contact-form input[type="email"].error{
  border-color: red;    
}
#contact-form select.error {
  border-color: red;
}
#contact-form textarea.error{
  border-color: red;    
}


/* Post pagination styles */
.post-pagination{
    position: relative;
    display: block;
}
.post-pagination li {
    display: inline-block;
    margin: 0 3px;
}
.post-pagination li a {
    position: relative;
    display: block;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #edf2f7;
    color: #d16111;
    font-size: 18px;
    font-weight: 500;
    height: 50px;
    width: 50px;
    padding: 11px 0;
    transition: all 500ms ease 0s;
    font-family: 'TrebuchetMS', sans-serif;
}
.post-pagination li a i {
    position: relative;
    top: 1px;
    color: #83888d;
    font-size: 20px;
}
.post-pagination li:hover a i{
    color: #fff;
}
.post-pagination li.active a, 
.post-pagination li:hover a {
    color: #fff;
    background: #409e35;
    border: 1px solid #409e35;
}




/* Post pagination styles 2 */
.post-pagination2{
    display: block;
    margin-top: 20px;
}
.post-pagination2 li {
    display: inline-block;
    margin: 0 3px;
}
.post-pagination2 li a {
    position: relative;
    display: block;
    color: #222222;
    font-size: 18px;
    font-weight: 600;
    height: 32px;
    width: 32px;
    line-height: 32px;
    text-align: center;
    transition: all 500ms ease 0s;
    font-family: 'Poppins', sans-serif;
    border-radius: 50%;
}
.post-pagination2 li.active a, 
.post-pagination2 li:hover a {
    background: #f36727;
    color: #ffffff;
}
.post-pagination2 li a.left {
    width: auto;
    height: auto;
    display: block;
    color: #868298;
    padding-right: 10px;
    transition: all 500ms ease;
}
.post-pagination2 li:hover a.left{
    background: none;
}
.post-pagination2 li a.left i {
    position: relative;
    top: 1px;
    display: inline-block;
    padding-right: 8px;
    font-size: 21px;
}
.post-pagination2 li a.left:hover{
    color: #f36727;
}


.post-pagination2 li a.right {
    width: auto;
    height: auto;
    display: block;
    color: #868298;
    padding-left: 10px;
    transition: all 500ms ease;
}
.post-pagination2 li a.right:hover{
    color: #f36727;    
}
.post-pagination2 li:hover a.right{
    background: none;
}
.post-pagination2 li a.right i {
    position: relative;
    top: 1px;
    display: inline-block;
    padding-left: 8px;
    font-size: 21px;
}






/* Overlay styles */
.overlay-style-one{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(233, 181, 49, 0.90);
    transition: all 500ms ease;
    transition-delay: 0.10s;    
    opacity: 0;
    z-index: 2;
}
.overlay-style-one .box{
    display: table;
    height: 100%;
    width: 100%;    
}
.overlay-style-one .box .content{
    display: table-cell;
    text-align: center;
    vertical-align: middle;    
}

.overlay-style-two{
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    top: 0px;
    z-index: 2;
    opacity: 0;
    transition: all 900ms ease;    
}
.overlay-style-two:before{
	position: absolute;
	content: '';
	top: 0px;
	left: 0px;
	width: 100%;
	height: 50%;
	display: block;
	opacity: 0;
	text-align: center;
    transform: perspective(400px) rotateX(-90deg);
    transform-origin: top;
    transition: all 0.5s;
	background-color: rgba(233, 181, 49, 0.90);
}
.overlay-style-two:after{
	position: absolute;
	content: '';
	left: 0px;
	bottom: 0px;
	width: 100%;
	height: 50%;
	display: block;
	opacity: 0;
	text-align: center;
    transform: perspective(400px) rotateX(90deg);
    transform-origin: bottom;
    transition: all 0.5s;
	background-color: rgba(233, 181, 49, 0.90);
}



.review-box {
    position: relative;
    display: block;
    overflow: hidden;
    line-height: 16px;
}
.review-box ul {
    display: block;
    overflow: hidden;
}
.review-box ul li {
    display: inline-block;
    float: left;
    margin-right: 4px;
}
.review-box ul li:last-child{
    margin-right: 0px;    
}
.review-box ul li i {
    font-size: 14px;
    color: #f36727;
}

.sociallinks-style-one{
}
.sociallinks-style-one li{
    display: inline-block;
    float: left;
    margin-right: 20px;
}
.sociallinks-style-one li:last-child{
    margin-right: 0px;
}
.sociallinks-style-one li a i{
    color: #9491a2;
    font-size: 15px;
    font-weight: 400;
    transition: all 500ms ease;
}
.sociallinks-style-one li a:hover i{
    color: #39345a;
}

.sociallinks-style-two{
    overflow: hidden;
}
.sociallinks-style-two li {
    display: inline-block;
    float: left;
    margin-right: 20px;
}
.sociallinks-style-two li:last-child{
    margin-right: 0px;    
}
.sociallinks-style-two li a i {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    background: #d16111;
    border: 1px solid #e9b531;
    border-radius: 50%;
    color: #ffffff;
    font-size: 14px;
    line-height: 48px;
    text-align: center;
    transition: all 500ms ease 0s;
}
.sociallinks-style-two li a:hover i{
    color: #e9b531;
    transform: rotate(360deg);
}



/*==============================================
    Top Bar Style1 Css
===============================================*/
.top-bar-style1 {
    position: relative;
    display: block;
    background: #d16111;
    padding: 0px 0 0px;
}
.top-style1{
    position: relative;
    display: block;
    width: 100%;
}
.top-left-style1 {
    position: relative;
    display: block;
    max-width: 500px;
    width: 100%;
}


.find-text {
    position: relative;
    display: block;
    margin: 15px 0 14px;
}
.find-text p {
    color: #ffffff;
    margin: 0;
    transition: all 100ms ease;
}
.find-text p span:before {
    position: relative;
    display: inline-block;
    top: 2px;
    padding-left: 10px;
    font-size: 18px;
    line-height: 18px;
}
.find-text p:hover{
    color: #e9b531;
}



.top-right-style1 {
    position: relative;
    display: block;
    max-width: 650px;
    width: 100%;
}
.for-enquiry {
    position: relative;
    display: block;
    margin: 14px 0;
}
.for-enquiry p{
    color: #ffffff;
    margin: 0;    
}
.for-enquiry p span:before{
    position: relative;
    display: inline-block;
    top: 2px;
    left: 1px;
    padding-right: 9px;
    color: #e9b531;
    font-size: 20px;
    line-height: 20px;
    transition: all 500ms ease;
}
.for-enquiry p:hover span:before{
    color: #ffffff;    
}


.top-social-style1{
    
}
.top-social-style1 li{
    margin: 0;    
}
.top-social-style1 li a{
    position: relative;
    display: block;
    width: 50px;
    height: 55px;
    text-align: center;
    border-right: 1px solid #e9b531;
}
.top-social-style1 li:first-child a{
    border-left: 1px solid #e9b531;
}
.top-social-style1 li a:before {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: 0px;
    content: "";
    height: 3px;
    background: #409e35;
    transform: scaleX(0);
    transition: .9s ease;
}
.top-social-style1 li:hover a:before {
    transform: scaleY(1);
    transition: .5s ease;
}
.top-social-style1 li a i {
    display: block;
    color: #ffffff;
    font-size: 14px;
    line-height: 55px;
}
.top-social-style1 li a:hover i{
    color: #409e35;
}



/*==============================================
    Header Style1 Area Css        
===============================================*/
.header-style1-area {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 35px 0 35px;
}
.header-style1{
    position: relative;
    display: block;
}
.headers1-logo {
    position: relative;
    display: block;
    margin: 1px 0;
}
.headers1-logo a {
    display: inline-block;
}

.headers1-logo a img {
    width: 180px;
}

.headers1-header-right{
    position: relative;
    display: block;
}
.header-contact-info {
    position: relative;
    display: block;
    float: left;
    margin-right: 50px;
}
.header-contact-info li {
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 40px;
    min-height: 60px;
}
.header-contact-info li:last-child{
    margin-right: 0;
}
.header-contact-info li .single-item{
    position: relative;
    display: block;
}
.header-contact-info li .single-item .icon{
    width: 70px;
}
.header-contact-info li .single-item .icon {
    position: relative;
    text-align: right;
    height: 60px;
    z-index: 1;
}
.header-contact-info li .single-item .icon:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 45px;
    height: 45px;
    background: #f3f7fa;
    content: "";
    border-radius: 9px;
    z-index: -1;
    margin: 7px 0;
    transition: all 0.5s ease-in-out 0s;
    transform-style: preserve-3d;
    transform: perspective(0px) rotateX(0deg) rotate(45deg);
}
.header-contact-info li:hover .single-item .icon:before{
    background: #ebf0f4;
    transform: perspective(0px) rotateX(0deg) rotate(-45deg);    
}

.header-contact-info li .single-item .icon,
.header-contact-info li .single-item .text{
    display: table-cell;
    vertical-align: middle;
}
.header-contact-info li .single-item .text{
    padding-left: 20px;
}
.header-contact-info li .single-item .text h3{
    font-size: 18px;
    text-transform: capitalize;
}
.header-contact-info li .single-item .text p{
    margin: 6px 0 0;
    line-height: 18px;
}

.headers1-button {
    position: relative;
    display: block;
    float: right;
    width: 200px;
}
.headers1-button a {
    position: relative;
    display: inline-block;
    float: right;
    margin: 2px 0 3px;
}



/*==============================================
    Header Style2 Area Css        
===============================================*/
.header-style2-area {
    position: relative;
    display: block;
    background: transparent;
    z-index: 9999999;
    margin-bottom: -152px;
}
.header-top-style2 {
    position: relative;
    display: block;
    border-bottom: 1px solid rgba(239, 239, 239, 0.16);
    padding: 19px 0;
}
.header-top-style2-left{
    position: relative;
    display: block;      
}
.header-top-style2-left p{
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin: 0;
}
.header-top-style2-left p span:before {
    position: relative;
    top: 3px;
    font-size: 20px;
    line-height: 14px;
    display: inline-block;
    padding-right: 9px;
}

.header-top-style2-right{
    position: relative;
    display: block;     
}
.header-top-style2-right .contact-info{
    position: relative;
    display: block;       
}
.header-top-style2-right .contact-info li{
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 30px;
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}
.header-top-style2-right .contact-info li:last-child{
    margin-right: 0;
}
.header-top-style2-right .contact-info li i{
    color: #009865;
    font-size: 16px;
    display: inline-block;
    padding-right: 8px;
}
.header-top-style2-right .social-info{
    position: relative;
    display: block;    
    padding-left: 30px;
}
.header-top-style2-right .social-info li {
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 20px;
    line-height: 22px;
}
.header-top-style2-right .social-info li:last-child{
    margin-right: 0;
}
.header-top-style2-right .social-info li a i{
    color: #ffffff;
    font-size: 14px;
    transition: all 500ms ease;
}
.header-top-style2-right .social-info li a:hover i{
    color: #e9b531;    
}

.header-bottom-style2{
    position: relative;
    display: block; 
    transition: all 300ms ease;
}
.header-bottom-style2 .inner-content{
    position: relative;
    display: block; 
    border-bottom: 1px solid rgba(239, 239, 239, 0.16);
    transition: all 300ms ease;
}
.header-bottom-style2-left{
    position: relative;
    display: block;      
}
.header-bottom-style2-left .logo-box {
    position: relative;
    display: block;
    padding: 20px 0;
    transition: all 300ms ease 100ms;
}
.header-bottom-style2-left .logo-box a{
    display: inline-block;
}
.header-bottom-style2-right{
    position: relative;
    display: block;       
}
.main-menu.style2{
    position: relative;
    display: inline-block;
    float: left;    
}
.main-menu.style2 .navigation li:before {
    display: none;
}
.main-menu.style2 .navigation li:after {
    display: none;
}
.main-menu.style2 .navigation li a {
    color: #ffffff;
    padding: 39px 22px 37px;
    transition: all 300ms ease 100ms;
}
.main-menu.style2 .navigation li a:before {
    display: none;
}
.main-menu.style2 .navigation li:hover > a, 
.main-menu.style2 .navigation li.current > a {
    color: #e9b531;
}
.main-menu.style2 .navigation > li > ul:before {
    background: #0a0a0a;
}
.main-menu.style2 .navigation li ul li a {
    color: #ffffff;
    border-bottom: 1px solid #131313;
    padding: 16px 30px 15px;
}
.main-menu.style2 .navigation li ul li a:after {
    color: #e9b531;
}

.main-menu.style2 .navigation > li > ul > li > ul:before {
    background: #0a0a0a;
}
.main-menu.style2 .navigation > li > ul > li > ul > li a {
    color: #ffffff;
    border-bottom: 1px solid #131313 !important;
    padding: 16px 30px 15px;
}

.outer-search-box.style2 .seach-toggle {
    margin: 20px 0 20px;
    transition: all 300ms ease 100ms;
}
.outer-search-box.style2 .seach-toggle:hover,
.outer-search-box.style2 .seach-toggle.active{
    color: #f5f5f5;
    background: rgba(255, 255, 255, 0.10);
}
.header-bottoms2-right-quote-button {
    position: relative;
    display: inline-block;
    float: right;
    margin: 27px 0 28px;
    transition: all 300ms ease 100ms;
}
.header-bottoms2-right-quote-button a{
    position: relative;
    display: block;
    background: #ffffff;
    color: #3a3a3a;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 5px 21px 4px;
    border-radius: 30px;
    transition: all 300ms ease 100ms;
}
.header-bottoms2-right-quote-button a:hover{
    color: #ffffff;
    background: #e9b531;
}




.header-bottom-style2.fixed-header {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 999;
    width: 100%;
    background: #0a0a0a;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    border-bottom: 0px solid rgba(255, 255, 255, 0.10);
    transition: all 100ms ease;
}
.header-bottom-style2.fixed-header .inner-content{
    border: none;
}

















/*** 
=====================================================
	Mainmenu Area style
=====================================================
***/
.mainmenu-area {
    position: relative;
    display: block;
    background: transparent;
    margin: 0px 0 -33px;
    z-index: 999;
}
.mainmenu-area .inner-content {
    position: relative;
    display: block;
    background: #f2cb42;
    padding-left: 18px;
    padding-right: 40px;
    border-radius: 5px;
}
.main-menu {
    position: relative;
    display: block;
    float: left;
    width: 100%;
}
.main-menu .navbar-collapse {
    padding: 0px
}
.main-menu .navigation {
    position: relative;
}
.main-menu .navigation li {
    position: relative;
    display: inline-block;
    float: left;
}
.main-menu .navigation li:before{
    position: absolute;
    top: 27px;
    right: -2px;
    width: 4px;
    height: 4px;
    content: "";
    background: #116db1;
    border-radius: 50%;
}
.main-menu .navigation li:last-child:before{
    display: none;
}
.main-menu .navigation li:after{
    position: absolute;
    bottom: 28px;
    right: -2px;
    width: 4px;
    height: 4px;
    content: "";
    background: #116db1;
    border-radius: 50%;
}
.main-menu .navigation li:last-child:after{
    display: none;
}

.main-menu .navigation li a {
    position: relative;
    display: block;
    color: #d16111;
    font-size: 14px;
    line-height: 14px;
    font-weight: 900;
    text-transform: uppercase;
    opacity: 1;
    padding: 26px 22px 25px;
    transition: all 300ms ease;
    font-family: 'TrebuchetMS', sans-serif;
}
.main-menu .navigation li:hover > a, 
.main-menu .navigation li.current > a {
    color: #116db1; 
}
.main-menu .navigation li a:before{
    position: absolute;
    left: -2px;
    right: -2px;
    bottom: -3px;
    height: 3px;
    content: "";
    background: #116db1;
    transform: scaleX(0);
    transition: .9s ease;
}
.main-menu .navigation li:hover a:before, 
.main-menu .navigation li.current a:before {
    transform: scaleY(1);
    transition: .5s ease;
}

.main-menu .navigation > li > ul {
    position: absolute;
    left: 0px;
    top: 130%;
    width: 250px;
    background: transparent;
    padding: 13px 0px 0;
    visibility: hidden;
    opacity: 0;
    display: block;
    border-radius: 0px;
    transition: all 500ms ease;
    z-index: 100;
}
.main-menu .navigation > li > ul:before {
    position: absolute;
    top: 13px;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #d16111;
    content: "";
    z-index: -1;
}
.main-menu .navigation > li:hover > ul {
    top: 100%;
    opacity: 1;
    visibility: visible;
}
.main-menu .navigation > li > ul > li {
    position: relative;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: all 500ms ease;
    z-index: 1;
}
.main-menu .navigation > li > ul > li:before{
    display: none;
}
.main-menu .navigation > li > ul > li:after{
    display: none;
}
.main-menu .navigation li ul li a {
    position: relative;
    display: block;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #e9b531;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
    padding: 16px 30px 15px;
    padding-right: 0;
    font-family: 'TrebuchetMS', sans-serif;
}
.main-menu .navigation li ul li:last-child a{
    border-bottom: none;
}
.main-menu .navigation li ul li a:after {
    position: absolute;
    top: 0;
    left: 30px;
    bottom: 0;
    font-family: 'icomoon';
    content: "\e909";
    color: #fff;
    font-size: 20px;
    opacity: 0;
    transform: scale(0);
    transition: all 500ms ease;
    margin: 15px 0;
}
.main-menu .navigation li ul li a:before{
    display: none;
}
.main-menu .navigation li ul li:hover a:after{
    opacity: 1;
    transform: scale(1.0);
}

.main-menu .navigation > li > ul > li:hover {
    background-color: #409e35;
}

.main-menu .navigation > li > ul > li:hover > a {
    color: #fff;
    padding-left: 60px;
}

.main-menu .navigation > li > ul > li > ul {
    position: absolute;
	left: 100%;
	top: 20px;
    width: 250px;
    padding-left: 10px;
    visibility: hidden;
    opacity: 0;
    background: transparent;
    z-index: 100;
    transition: all 200ms ease;
}
.main-menu .navigation > li > ul > li > ul:before{
    position: absolute;
    left: 10px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    content: "";
    z-index: -1;
    border-radius: 0px;    
}
.main-menu .navigation > li > ul > li:hover > ul{
	visibility: visible;
	opacity: 1;
	top: 0px;
}
.main-menu .navigation > li > ul > li > ul > li{
    position: relative;
    display: block;
    float: none;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: all 500ms ease;
    background-color: #d16111;
}
.main-menu .navigation > li > ul > li > ul > li:before{
    display: none;
}
.main-menu .navigation > li > ul > li > ul > li:after{
    display: none;
}
.main-menu .navigation > li > ul > li > ul > li a{
    position: relative;
    display: block;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #e9b531 !important;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
    padding: 16px 30px 15px;
    /*padding-right: 0;*/
    font-family: 'TrebuchetMS', sans-serif;
}
.main-menu .navigation > li > ul > li > ul > li:last-child a{
    border-bottom: 0px !important;   
}
.main-menu .navigation > li > ul > li > ul > li a:before{
    display: none;
}
.main-menu .navigation > li > ul > li > ul > li a:after {
    position: absolute;
    top: 0;
    left: 30px;
    bottom: 0;
    font-family: 'icomoon';
    content: "\e909";
    color: #fff;
    font-size: 20px;
    transform: scale(0) !important;
    transition: all 500ms ease;
    margin: 15px 0;
}
.main-menu .navigation > li > ul > li > ul > li:hover a:after{
    transform: scale(1.0) !important;
}
.main-menu .navigation > li > ul > li > ul > li:hover > a {
    color: #fff;
    padding-left: 60px;
}
.main-menu .navigation > li > ul > li > ul > li:hover {
    background-color: #409e35;
}


/*
.main-menu .navigation li ul li.dropdown{
    position: relative;
    display: block;
    width: 100%;
}
.main-menu .navigation li ul li.dropdown:after{
    position: absolute;
    font-family: 'FontAwesome';
    content: "\f105";
    font-size: 18px;
    line-height: 30px; 
    display: block;
    top: 0;
    bottom: 0;
    right: 30px;
    margin: 11px 0;
    color: #8d8d8d;
}
**/


.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn {
    position: absolute;
    right: 10px;
    top: 6px;
    width: 34px;
    height: 30px;
    border: 1px solid #ffffff;
    background: url(../../../assets/images/icon/submenu-icon.png) center center no-repeat;
    background-size: 20px;
    cursor: pointer;
    z-index: 5;
    display: none;
    border-radius: 3px;
}



.mainmenu-right {
    position: relative;
    display: inline-block;
    float: right;
}
.outer-search-box {
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 20px;
}
.outer-search-box .seach-toggle {
    position: relative;
    display: block;
    color: #ffffff;
    font-size: 16px;
    line-height: 50px;
    cursor: pointer;
    text-align: center;
    height: 50px;
    width: 50px;
    border-radius: 3px;
    margin: 7px 0 8px;
    transition: all 500ms ease;
}
.outer-search-box .seach-toggle:hover,
.outer-search-box .seach-toggle.active{
    color: #f5f5f5;
    background: #2d8607;
}
.search-box {
    position: absolute;
    top: 120%;
    right: 0%;
    background: #2d8607;
    opacity: 0;
    padding: 20px;
    transition: all 500ms ease 0s;
    visibility: hidden;
    z-index: 100;
    margin-left: 0;
}
.search-box.now-visible {
    top: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 100;
}
.search-box .form-group{
    position:relative;
    padding: 0px;
    margin: 0px;
    width: 100%;
    min-width: 250px;
}
.search-box .form-group input[type="search"] {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #e9b531;
    color: #000;
    display: block;
    font-size: 13px;
    line-height: 24px;
    position: relative;
    transition: all 500ms ease 0s;
    width: 100%;
    height: 45px;
    padding-left: 15px;
    padding-right: 50px;
}
.search-box .form-group input[type="search"]:focus{
    border-color: #f5f5f5;	
}
.search-box .form-group button, 
.search-box .form-group input[type="submit"] {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    cursor: pointer;
    background: #e9b531;
    color: #fff;
    font-size: 13px;
    height: 45px;
    line-height: 45px;
    width: 45px;
    transition: all 500ms ease 0s;
}
.search-box .form-group button:hover,
.search-box .form-group input:focus + button{
    color: #222222;
    background: #f5f5f5;
}

.cart-box {
    position: relative;
    display: inline-block;
    float: right;
    height: 50px;
    padding: 15px 0;
    margin: 7px 0 8px;
}
.cart-box a{
    display: inline-block;
    font-family: 'TrebuchetMS', sans-serif;
}
.cart-box a span:before{
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    display: inline-block;
    float: left;
    padding-right: 6px;
    transition: all 500ms ease;
}
.cart-box a:hover span:before{
    color: #2d8607;    
}
.cart-box a span.number {
    position: relative;
    display: inline-block;
    float: left;
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
}




.header-navigation {
    background-color: transparent;
    margin-bottom: 0;
    border: none;
    border-radius: 0;
    padding: 0;
    position: relative;
    background-color: transparent;
}
.header-navigation .container {
    background: transparent;
    position: relative;
    display: block;
}

.header-navigation .container .logo-box {
    float: left;
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-navigation .container .navbar-brand {
    height: auto;
    margin: 0;
}

.header-navigation .container .menu-toggler {
    display: none;
}

.header-navigation .container .right-side-box {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
}

.header-navigation .main-navigation {
    float: right;
    text-align: right;
}

@media (min-width: 1200px) {
    .header-navigation .main-navigation {
        display: block !important;
    }
}

.header-navigation ul.navigation-box {
    margin: 0;
    padding: 0;
    list-style: none;
}

.header-navigation ul.navigation-box li a .sub-nav-toggler {
    display: none;
}

.header-navigation ul.navigation-box>li {
    position: relative;
    padding: 36.5px 0;
    display: inline-block;
    vertical-align: middle;
    /* Second Level Menu */
    /* Thrid Level Menu */
}

.header-navigation ul.navigation-box>li+li {
    margin-left: 31px;
}

.header-navigation ul.navigation-box>li:first-child {
    padding-left: 0;
}

.header-navigation ul.navigation-box>li:last-child {
    padding-right: 0;
}

.header-navigation ul.navigation-box>li>a {
    font-weight: 900;
    font-size: 14px;
    color: #2e2e2e;
    text-transform: uppercase;
    padding: 0;
    transition: all .4s ease;
    position: relative;
}

.header-navigation ul.navigation-box>li.current>a,
.header-navigation ul.navigation-box>li:hover>a {
    color: #01B9E6;
}

.header-navigation ul.navigation-box>li>.sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 250px;
    padding: 0px 0px;
    padding-top: 15px;
    text-align: left;
    list-style: none;
    background-color: transparent;
    background-clip: padding-box;
    opacity: 0;
    border-radius: 0px;
    visibility: hidden;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    transition: all .4s ease, visibility .4s ease;
    box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
    .header-navigation ul.navigation-box>li>.sub-menu {
        display: block !important;
    }
}

.header-navigation ul.navigation-box>li>.sub-menu.right-align {
    left: auto;
    right: 0;
}

.header-navigation ul.navigation-box>li>.sub-menu.center-align {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.header-navigation ul.navigation-box>li>.sub-menu>li {
    display: block;
    position: relative;
    transition: all .4s ease;
    background: #d16111;
}

.header-navigation ul.navigation-box>li>.sub-menu>li+li {
    border-top: 1px solid #e9b531;
}

.header-navigation ul.navigation-box>li>.sub-menu>li>a {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    padding: 12px 30px;
    display: block;
    line-height: 26px;
    white-space: nowrap;
    position: relative;
    transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>.sub-menu>li>a:after {
    content: '\e92c';
    font-family: 'energy-icon' !important;
    font-size: 20px;
    color: #409e35;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    left: 30px;
    transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>.sub-menu>li:hover>a {
    color: #409e35;
    padding-left: 60px;
}

.header-navigation ul.navigation-box>li>.sub-menu>li:hover>a:after {
    -webkit-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}

.header-navigation ul.navigation-box>li:hover:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
}

.header-navigation ul.navigation-box>li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu {
    position: absolute;
    top: 0%;
    left: 100%;
    z-index: 1000;
    float: left;
    min-width: 250px;
    padding: 0px 0px;
    padding-top: 15px;
    text-align: left;
    list-style: none;
    background-color: transparent;
    background-clip: padding-box;
    opacity: 0;
    border-radius: 0px;
    visibility: hidden;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    transition: all .4s ease, visibility .4s ease;
    box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
    .header-navigation ul.navigation-box>li>ul>li>.sub-menu {
        display: block !important;
    }
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu.right-align {
    left: auto;
    right: 100%;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu.center-align {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li {
    display: block;
    position: relative;
    transition: all .4s ease;
    background: #d16111;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li+li {
    border-top: 1px solid #e9b531;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li>a {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    padding: 12px 30px;
    display: block;
    line-height: 26px;
    white-space: nowrap;
    position: relative;
    transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li>a:after {
    content: '\e92c';
    font-family: 'energy-icon' !important;
    font-size: 20px;
    color: #409e35;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
    left: 30px;
    transition: all .4s ease;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li:hover>a {
    color: #409e35;
    padding-left: 60px;
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu>li:hover>a:after {
    -webkit-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}

.header-navigation ul.navigation-box>li>ul>li>.sub-menu {
    padding-top: 0;
    padding-left: 15px;
}

.header-navigation ul.navigation-box>li>ul>li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.header-navigation ul.navigation-box>li>ul>li ul {
    display: none;
}

.header-navigation.stricky-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 991;
    background: #fff;
    border-bottom: 0;
    box-shadow: 0 0 21px rgba(0, 0, 0, 0.08);
}

.site-header.header-one .top-bar {
    background: #021F3D;
    padding: 11.5px 0;
}

.site-header.header-one .top-bar ul.list-items>li {
    display: inline-block;
    vertical-align: middle;
}

.site-header.header-one .top-bar ul.list-items>li+li {
    padding-left: 20px;
    margin-left: 20px;
    position: relative;
}

.site-header.header-one .top-bar ul.list-items>li+li:before {
    content: '';
    width: 1px;
    height: 18px;
    background: rgba(242, 242, 242, 0.13);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
}

.site-header.header-one .top-bar ul.list-items>li i,
.site-header.header-one .top-bar ul.list-items>li .text-line {
    display: inline-block;
    vertical-align: middle;
}

.site-header.header-one .top-bar ul.list-items>li i {
    font-size: 18px;
    color: #FFBA00;
}

.site-header.header-one .top-bar ul.list-items>li .text-line {
    color: #FFFFFF;
    font-size: 16px;
}

.site-header.header-one .top-bar ul.list-items .search-btn i {
    color: #fff;
}

.site-header.header-one .top-bar .bootstrap-select>.dropdown-toggle {
    border: none;
    outline: none !important;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 900;
    box-shadow: none;
    display: inline-block;
    vertical-align: middle;
}

.site-header.header-one .top-bar .bootstrap-select>.dropdown-toggle:after {
    border: none;
    width: auto;
    height: auto;
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.site-header.header-one .top-bar .bootstrap-select>.dropdown-toggle img {
    display: inline-block;
    vertical-align: -2px;
}

.site-header.header-one .top-bar .bootstrap-select .dropdown-menu {
    margin: 0;
    padding: 0;
    background: #021F3D;
    border-radius: 0;
}

.site-header.header-one .top-bar .bootstrap-select .dropdown-menu .dropdown-item {
    border-radius: 0 !important;
    padding: 4px 12px;
    transition: all .4s ease;
    color: #fff;
}

.site-header.header-one .top-bar .bootstrap-select .dropdown-menu .dropdown-item.active,
.site-header.header-one .top-bar .bootstrap-select .dropdown-menu .dropdown-item:hover,
.site-header.header-one .top-bar .bootstrap-select .dropdown-menu .dropdown-item:active {
    background: #01B9E6;
}

.site-header.header-one .top-bar .bootstrap-select .dropdown-menu .dropdown-item img {
    display: inline-block;
    vertical-align: -2px;
}

.site-header.header-one .header-navigation .container {
    padding-right: 235px;
}

.site-header.header-one .header-navigation .main-navigation {
    padding-left: 40px;
    padding-right: 40px;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
}

.site-header.header-one .header-navigation ul.navigation-box>li>a {
    position: relative;
}

.site-header.header-one .header-navigation ul.navigation-box>li>a:after {
    content: '\e909';
    font-family: 'energy-icon' !important;
    color: #01B9E6;
    font-size: 15px;
    position: absolute;
    bottom: -25px;
    left: 50%;
    display: block;
    -webkit-transform: translateX(-50%) translateY(-40%);
    transform: translateX(-50%) translateY(-40%);
    opacity: 0;
    transition: all .4s ease;
}

.site-header.header-one .header-navigation ul.navigation-box>li.current>a:after,
.site-header.header-one .header-navigation ul.navigation-box>li:hover>a:after {
    -webkit-transform: translateX(-50%) translateY(0%);
    transform: translateX(-50%) translateY(0%);
    opacity: 1;
}

.site-header.header-one .header-navigation .right-side-box .social a {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #F5F5F5;
    color: #8F8F8F;
    font-size: 14px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    transition: all .4s ease;
}

.site-header.header-one .header-navigation .right-side-box .social a:hover {
    background-color: #01B9E6;
    color: #fff;
}

.site-header.header-one .header-navigation .right-side-box .social a+a {
    margin-left: 15px;
}

.site-header.header-one .header-navigation.stricky-fixed {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

.site-header.header-one .header-navigation.stricky-fixed.header-navigation ul.navigation-box>li {
    padding-top: 25px;
    padding-bottom: 25px;
}

.site-header.header-three .top-bar {
    border-bottom: 1px solid #eaf0ee;
}

.site-header.header-three .top-bar .has-color-ribbon {
    position: relative;
    padding-top: 5px;
}

.site-header.header-three .top-bar .has-color-ribbon:before,
.site-header.header-three .top-bar .has-color-ribbon:after {
    content: '';
    position: absolute;
    top: 0;
    width: 50%;
    height: 5px;
}

.site-header.header-three .top-bar .has-color-ribbon:before {
    left: 0;
    background: #F9A03B;
}

.site-header.header-three .top-bar .has-color-ribbon:after {
    right: 0;
    background: #28B381;
}

.site-header.header-three .top-bar .top-menu,
.site-header.header-three .top-bar .contact-lists {
    padding: 14.5px 0;
}

.site-header.header-three .top-bar .top-menu li,
.site-header.header-three .top-bar .contact-lists li {
    display: inline-block;
    vertical-align: middle;
}

.site-header.header-three .top-bar .contact-lists li {
    color: #83888D;
    font-size: 16px;
}

.site-header.header-three .top-bar .contact-lists li+li:before {
    content: '/';
    margin-left: 15px;
    margin-right: 20px;
    color: #83888D;
}

.site-header.header-three .top-bar .contact-lists li i {
    color: #28B381;
    font-size: 19px;
    vertical-align: -2px;
    margin-right: 6px;
}

.site-header.header-three .top-bar .top-menu li a {
    display: block;
    color: #83888D;
    font-size: 16px;
}

.site-header.header-three .top-bar .top-menu li+li {
    margin-left: 30px;
}

.site-header.header-three .upper-header .container-outer {
    padding: 30.5px 0;
    border-bottom: 1px solid #eaf0ee;
}

.site-header.header-three .upper-header .single-right-block {
    display: inline-block;
    vertical-align: middle;
}

.site-header.header-three .upper-header .single-right-block+.single-right-block {
    margin-left: 35px;
}

.site-header.header-three .upper-header .contact-block .icon-block,
.site-header.header-three .upper-header .contact-block .text-block {
    display: table-cell;
    vertical-align: middle;
}

.site-header.header-three .upper-header .contact-block .icon-block i {
    color: #28B381;
    font-size: 40px;
}

.site-header.header-three .upper-header .contact-block .text-block {
    padding-left: 20px;
}

.site-header.header-three .upper-header .contact-block .text-block p {
    color: #83888D;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
}

.site-header.header-three .upper-header .contact-block .text-block p span {
    color: #262626;
    font-weight: bold;
    font-size: 20px;
}

.site-header.header-three .upper-header .social-block {
    padding: 4px 0;
}

.site-header.header-three .upper-header .social-block a {
    width: 50px;
    height: 50px;
    border: 1px dashed #E0E4E2;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #83888D;
    line-height: 48px;
    border-radius: 50%;
    transition: all .4s ease;
}

.site-header.header-three .upper-header .social-block a+a {
    margin-left: 11px;
}

.site-header.header-three .upper-header .social-block a:hover {
    color: #28B381;
    border-color: #28B381;
}

.site-header.header-three .header-navigation .main-navigation {
    float: left;
    text-align: left;
}

.site-header.header-three .header-navigation ul.navigation-box>li {
    padding: 21.5px 0;
}

.site-header.header-three .header-navigation ul.navigation-box>li+li {
    margin-left: 44px;
}

.site-header.header-three .header-navigation ul.navigation-box>li.current>a,
.site-header.header-three .header-navigation ul.navigation-box>li:hover>a {
    color: #F9A03B;
}

.site-header.header-three .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li,
.site-header.header-three .header-navigation ul.navigation-box>li>.sub-menu>li {
    background-color: #192521;
}

.site-header.header-three .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li:hover>a,
.site-header.header-three .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li>a:after,
.site-header.header-three .header-navigation ul.navigation-box>li>.sub-menu>li>a:after,
.site-header.header-three .header-navigation ul.navigation-box>li>.sub-menu>li:hover>a {
    color: #F9A03B;
}

.site-header.header-three .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li+li,
.site-header.header-three .header-navigation ul.navigation-box>li>.sub-menu>li+li {
    border-top-color: #2a3934;
}

.site-header.header-three .header-navigation .right-side-box .search-btn {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    background: #F2F7F5;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    font-size: 16px;
    color: #83888D;
    transition: all .4s ease;
}

.site-header.header-three .header-navigation .right-side-box .search-btn:hover {
    color: #fff;
    background: #F9A03B;
}

.site-header.header-three .header-navigation .right-side-box .rqa-btn {
    color: #262626;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    transition: all .4s ease;
    border-left: 1px solid #EAF0EE;
    padding: 27px 0;
    padding-left: 30px;
    margin-left: 30px;
}

.site-header.header-three .header-navigation .right-side-box .rqa-btn i {
    font-size: 21px;
    display: inline-block;
    vertical-align: -3px;
    color: #28B381;
    margin-left: 6px;
    transition: all .4s ease;
}

.site-header.header-three .header-navigation .right-side-box .rqa-btn:hover {
    color: #F9A03B;
}

.site-header.header-three .header-navigation .right-side-box .rqa-btn:hover i {
    color: #262626;
}

.site-header.header-three .header-navigation.stricky-fixed {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
}

.site-header.header-four {
    position: absolute;
    top: 10px;
    left: 50%;
    width: 75.69%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    z-index: 99;
}

@media (max-width: 1440px) {
    .site-header.header-four {
        width: 95%;
    }
}

.site-header.header-four .upper-header {
    padding: 20.5px 0;
    border-bottom: 1px solid #f2f2f2;
}

.site-header.header-four .upper-header .left-logo {
    padding: 7px 0;
}

.site-header.header-four .single-right-block {
    display: inline-block;
    vertical-align: middle;
}

.site-header.header-four .single-right-block+.single-right-block:not(:last-child) {
    border-left: 1px solid #F2F2F2;
    padding-left: 30px;
}

.site-header.header-four .single-right-block+.single-right-block {
    margin-left: 30px;
}

.site-header.header-four .single-right-block .icon-block,
.site-header.header-four .single-right-block .text-block {
    display: table-cell;
    vertical-align: middle;
}

.site-header.header-four .single-right-block .icon-block i {
    font-size: 35px;
    color: #91C942;
}

.site-header.header-four .single-right-block .text-block {
    padding-left: 20px;
}

.site-header.header-four .single-right-block .text-block p {
    color: #83888D;
    font-size: 16px;
    line-height: 1em;
    margin: 0;
}

.site-header.header-four .single-right-block .text-block p span {
    color: #3A3A3A;
    font-size: 18px;
    font-weight: bold;
    display: block;
    margin-bottom: 11px;
}

.site-header.header-four .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li,
.site-header.header-four .header-navigation ul.navigation-box>li>.sub-menu>li {
    background-color: #2e2e2e;
}

.site-header.header-four .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li:hover>a,
.site-header.header-four .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li>a:after,
.site-header.header-four .header-navigation ul.navigation-box>li>.sub-menu>li>a:after,
.site-header.header-four .header-navigation ul.navigation-box>li>.sub-menu>li:hover>a {
    color: #91C942;
}

.site-header.header-four .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li+li,
.site-header.header-four .header-navigation ul.navigation-box>li>.sub-menu>li+li {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.site-header.header-four .header-navigation .container {
    padding-left: 90px;
}

.site-header.header-four .header-navigation .logo-box .side-nav-toggler {
    font-size: 26px;
    color: #3A3A3A;
    display: block;
}

.site-header.header-four .header-navigation .main-navigation {
    float: left;
    text-align: left;
}

.site-header.header-four .header-navigation ul.navigation-box>li {
    padding: 14.5px 0;
}

.site-header.header-four .header-navigation ul.navigation-box>li>a {
    font-weight: 900;
    color: #3A3A3A;
}

.site-header.header-four .header-navigation ul.navigation-box>li.current>a,
.site-header.header-four .header-navigation ul.navigation-box>li:hover>a {
    color: #34964D;
}

.site-header.header-four .header-navigation .right-side-box .search-btn {
    color: #3A3A3A;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 25px;
    margin-right: 25px;
}

.site-header.header-four .header-navigation .right-side-box .cart-btn {
    display: inline-block;
    vertical-align: middle;
    color: #3A3A3A;
}

.site-header.header-four .header-navigation .right-side-box .cart-btn i {
    font-size: 22px;
}

.site-header.header-four .header-navigation .right-side-box .cart-btn span.number {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: 12px;
    margin-left: 4px;
}

.site-header.header-four .header-navigation .right-side-box .bootstrap-select>.dropdown-toggle {
    border: none;
    outline: none !important;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    color: #3A3A3A;
    font-size: 16px;
    font-weight: 900;
    box-shadow: none;
    display: inline-block;
    vertical-align: middle;
}

.site-header.header-four .header-navigation .right-side-box .bootstrap-select>.dropdown-toggle:after {
    border: none;
    width: auto;
    height: auto;
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.site-header.header-four .header-navigation .right-side-box .bootstrap-select>.dropdown-toggle img {
    display: inline-block;
    vertical-align: -2px;
    margin-right: 7px;
}

.site-header.header-four .header-navigation .right-side-box .bootstrap-select .dropdown-menu {
    margin: 0;
    padding: 0;
    background: #021F3D;
    border-radius: 0;
}

.site-header.header-four .header-navigation .right-side-box .bootstrap-select .dropdown-menu .dropdown-item {
    border-radius: 0 !important;
    padding: 4px 12px;
    transition: all .4s ease;
    color: #fff;
}

.site-header.header-four .header-navigation .right-side-box .bootstrap-select .dropdown-menu .dropdown-item.active,
.site-header.header-four .header-navigation .right-side-box .bootstrap-select .dropdown-menu .dropdown-item:hover,
.site-header.header-four .header-navigation .right-side-box .bootstrap-select .dropdown-menu .dropdown-item:active {
    background: #01B9E6;
}

.site-header.header-four .header-navigation .right-side-box .bootstrap-select .dropdown-menu .dropdown-item img {
    display: inline-block;
    vertical-align: -2px;
}

.header-navigation.header-style-four.stricky-menu {
    visibility: hidden;
    display: none;
}

.header-navigation.header-style-four.stricky-menu.stricky-fixed {
    visibility: visible;
    display: block;
}

.header-navigation.header-style-four.stricky-menu .container {
    padding-left: 90px;
}

.header-navigation.header-style-four.stricky-menu .logo-box .side-nav-toggler {
    font-size: 26px;
    color: #3A3A3A;
    display: block;
}

.header-navigation.header-style-four.stricky-menu .main-navigation {
    float: left;
    text-align: left;
}

.header-navigation.header-style-four.stricky-menu ul.navigation-box>li {
    padding: 20.5px 0;
}

.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>a {
    font-weight: 900;
    color: #3A3A3A;
}

.header-navigation.header-style-four.stricky-menu ul.navigation-box>li.current>a,
.header-navigation.header-style-four.stricky-menu ul.navigation-box>li:hover>a {
    color: #34964D;
}

.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>ul>li>.sub-menu>li,
.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>.sub-menu>li {
    background-color: #2e2e2e;
}

.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>ul>li>.sub-menu>li:hover>a,
.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>ul>li>.sub-menu>li>a:after,
.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>.sub-menu>li>a:after,
.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>.sub-menu>li:hover>a {
    color: #91C942;
}

.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>ul>li>.sub-menu>li+li,
.header-navigation.header-style-four.stricky-menu ul.navigation-box>li>.sub-menu>li+li {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.header-navigation.header-style-four.stricky-menu .right-side-box .search-btn {
    color: #3A3A3A;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 25px;
    margin-right: 25px;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .cart-btn {
    display: inline-block;
    vertical-align: middle;
    color: #3A3A3A;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .cart-btn i {
    font-size: 22px;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .cart-btn span.number {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: 12px;
    margin-left: 4px;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select>.dropdown-toggle {
    border: none;
    outline: none !important;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    color: #3A3A3A;
    font-size: 16px;
    font-weight: 900;
    box-shadow: none;
    display: inline-block;
    vertical-align: middle;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select>.dropdown-toggle:after {
    border: none;
    width: auto;
    height: auto;
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select>.dropdown-toggle img {
    display: inline-block;
    vertical-align: -2px;
    margin-right: 7px;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select .dropdown-menu {
    margin: 0;
    padding: 0;
    background: #021F3D;
    border-radius: 0;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select .dropdown-menu .dropdown-item {
    border-radius: 0 !important;
    padding: 4px 12px;
    transition: all .4s ease;
    color: #fff;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select .dropdown-menu .dropdown-item.active,
.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select .dropdown-menu .dropdown-item:hover,
.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select .dropdown-menu .dropdown-item:active {
    background: #01B9E6;
}

.header-navigation.header-style-four.stricky-menu .right-side-box .bootstrap-select .dropdown-menu .dropdown-item img {
    display: inline-block;
    vertical-align: -2px;
}

.header-banner-outer.header-five {
    position: relative;
}

.header-banner-outer.header-five::before,
.header-banner-outer.header-five::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 580px;
    z-index: -1;
}

.header-banner-outer.header-five:before {
    background-image: url(../../../assets/images/home-pages/header-5-bg-1-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
}

.header-banner-outer.header-five::after {
    background-color: #000000;
    opacity: 0.88;
}

.site-header.home-five {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    background: transparent;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}

.site-header.home-five .upper-header {
    padding: 25.5px 0;
    border-color: rgba(255, 255, 255, 0.13);
}

.site-header.home-five .upper-header .right-block {
    padding: 8.5px 0;
}

.site-header.home-five .upper-header .social-block a {
    width: 50px;
    height: 50px;
    border: 1px solid #313131;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #FFFFFF;
    line-height: 48px;
    border-radius: 50%;
    transition: all .4s ease;
}

.site-header.home-five .upper-header .social-block a+a {
    margin-left: 11px;
}

.site-header.home-five .upper-header .social-block a:hover {
    color: #F9C807;
    border-color: #F9C807;
}

.site-header.home-five .upper-header .left-logo {
    padding: 0;
}

.site-header.home-five .single-right-block .icon-block i {
    color: #F9C807;
}

.site-header.home-five .single-right-block+.single-right-block:not(:last-child) {
    border: none;
    padding-left: 0;
}

.site-header.home-five .single-right-block .text-block p span,
.site-header.home-five .single-right-block .text-block p {
    color: #FFFFFF;
}

.site-header.home-five .header-navigation,
.header-navigation.header-style-five.stricky-menu.stricky-fixed {
    background: #000;
}

.site-header.home-five .header-navigation .container,
.header-navigation.header-style-five.stricky-menu.stricky-fixed .container {
    padding-left: 75px;
}

.site-header.home-five .header-navigation ul.navigation-box>li.current>a,
.site-header.home-five .header-navigation ul.navigation-box>li:hover>a,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li.current>a,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li:hover>a {
    color: #F9C807;
}

.site-header.home-five .header-navigation ul.navigation-box>li,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li {
    padding: 21.5px 0;
}

.site-header.home-five .header-navigation ul.navigation-box>li+li,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li+li {
    margin-left: 20px;
}

.site-header.home-five .header-navigation ul.navigation-box>li>a,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>a {
    padding: 6.5px 15px;
    position: relative;
}

.site-header.home-five .header-navigation ul.navigation-box>li>a::before,
.site-header.home-five .header-navigation ul.navigation-box>li>a::after,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>a::before,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>a::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 21px;
    height: 30px;
    border: 1px solid #F9C807;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
    -webkit-transform: scale(0, 1) translateY(-50%);
    transform: scale(0, 1) translateY(-50%);
}

.site-header.home-five .header-navigation ul.navigation-box>li>a::before,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>a::before {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    left: 0;
    border-right: 0;
}

.site-header.home-five .header-navigation ul.navigation-box>li>a::after,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>a::after {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    right: 0;
    border-left: 0;
}

.site-header.home-five .header-navigation ul.navigation-box>li.current>a::before,
.site-header.home-five .header-navigation ul.navigation-box>li.current>a::after,
.site-header.home-five .header-navigation ul.navigation-box>li:hover>a::before,
.site-header.home-five .header-navigation ul.navigation-box>li:hover>a::after,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li.current>a::before,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li.current>a::after,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li:hover>a::before,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li:hover>a::after {
    -webkit-transform: scale(1, 1) translateY(-50%);
    transform: scale(1, 1) translateY(-50%);
}

.site-header.home-five .header-navigation .logo-box .side-nav-toggler,
.site-header.home-five .header-navigation ul.navigation-box>li>a,
.site-header.home-five .header-navigation .right-side-box .bootstrap-select>.dropdown-toggle,
.site-header.home-five .header-navigation .right-side-box .search-btn,
.site-header.home-five .header-navigation .right-side-box .cart-btn,
.header-navigation.header-style-five.stricky-menu.stricky-fixed .logo-box .side-nav-toggler,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>a,
.header-navigation.header-style-five.stricky-menu.stricky-fixed .right-side-box .bootstrap-select>.dropdown-toggle,
.header-navigation.header-style-five.stricky-menu.stricky-fixed .right-side-box .search-btn,
.header-navigation.header-style-five.stricky-menu.stricky-fixed .right-side-box .cart-btn {
    color: #FFFFFF;
}

.site-header.home-five .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li,
.site-header.home-five .header-navigation ul.navigation-box>li>.sub-menu>li,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>ul>li>.sub-menu>li,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>.sub-menu>li {
    background-color: #2e2e2e;
}

.site-header.home-five .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li:hover>a,
.site-header.home-five .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li>a:after,
.site-header.home-five .header-navigation ul.navigation-box>li>.sub-menu>li>a:after,
.site-header.home-five .header-navigation ul.navigation-box>li>.sub-menu>li:hover>a,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>ul>li>.sub-menu>li:hover>a,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>ul>li>.sub-menu>li>a:after,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>.sub-menu>li>a:after,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>.sub-menu>li:hover>a {
    color: #F9C807;
}

.site-header.home-five .header-navigation ul.navigation-box>li>ul>li>.sub-menu>li+li,
.site-header.home-five .header-navigation ul.navigation-box>li>.sub-menu>li+li,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>ul>li>.sub-menu>li+li,
.header-navigation.header-style-five.stricky-menu.stricky-fixed ul.navigation-box>li>.sub-menu>li+li {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.site-header.home-five .header-navigation {
    background-color: transparent;
}



/*** 
=====================================================
	Main Slider style
=====================================================
***/
.main-slider {
    position: relative;
    z-index: 10;
}
.main-slider .tp-caption{
	z-index:5 ;
}
.main-slider .tp-dottedoverlay{
    background: none !important;
}
.main-slider .slide-content{
    position: relative;
    display: block;
}
.main-slider .slide-content .icon-holder{
    position: relative;
    display: block;    
}
.main-slider .slide-content .icon-holder span:before{
    color: #ffffff;
    font-size: 100px;
    line-height: 100px;
    font-weight: 400;
}
.main-slider .slide-content .text{
	position: relative;
    display: block;
	color: #ffffff;
	font-size: 14px;
	line-height: 1.2em;
	font-weight: 700;
    letter-spacing: .1em;
    text-transform: initial;
    font-family: 'TrebuchetMS', sans-serif;
}
.main-slider .slide-content .big-title{
	position: relative;
	color: #ffffff;
	font-size: 72px;
	line-height: 1.1em;
	font-weight: 700;
    text-transform: none;
    font-family: 'TrebuchetMS', sans-serif;
}
.main-slider .slide-content .btn-box{}
.main-slider .slide-content .btn-box a{
    background: #e9b531; 
}
.main-slider .slide-content .btn-box a:hover{
    background: #2e8c05;
}
.main-slider .slide-content .btn-box a.btn-one b {
    color: #ffffff;
    padding: 0 25px 0;
}
.main-slider .slide-content .btn-box a.btn-one span:before {
    width: 60px;
    border-left: 1px solid #319306;
    color: #ffffff;
}



/*** 
=====================================================
	Main Slider Style2 style
=====================================================
***/
.main-slider.style2 {
    position: relative;
    display: block;
    z-index: 10;
}
.main-slider.style2 .slide-content .big-title{
	position: relative;
	color: #ffffff;
	font-size: 72px;
	line-height: 1.1em;
	font-weight: 900;
    text-transform: capitalize;
    font-family: 'TrebuchetMS', sans-serif;
}
.main-slider.style2 .slide-content .big-title span{
    font-weight: 400;
}
.main-slider.style2 .slide-content .text{
	position: relative;
    display: block;
	color: #ffffff;
	font-size: 16px;
	line-height: 1.6em;
	font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
    font-family: 'TrebuchetMS', sans-serif;
}
.main-slider.style2 .slide-content .btn-box a{
    color: #ffffff;
    background: #e9b531;
    border-radius: 30px;
    padding: 0 40px 0;
}
.main-slider.style2 .slide-content .btn-box a:hover{
    color: #e9b531;
    background: #ffffff;
}



#minimal-bootstrap-carousel {
    margin-top: 0px;
    position: relative;
}
#minimal-bootstrap-carousel .carousel-caption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    text-align: right;
    text-shadow: none;
}
#minimal-bootstrap-carousel .carousel-caption .container {
    display: table;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
#minimal-bootstrap-carousel .carousel-caption .container .box {
    display: table-cell;
    text-align: left;
}
#minimal-bootstrap-carousel .carousel-caption .container .box .content {
    display: block;
}
#minimal-bootstrap-carousel .carousel-caption .container .box.valign-top {
    vertical-align: top;
}
#minimal-bootstrap-carousel .carousel-caption .container .box.valign-bottom {
    vertical-align: bottom;
}
#minimal-bootstrap-carousel .carousel-caption .container .box.valign-middle {
    vertical-align: middle;
}
#minimal-bootstrap-carousel .carousel-indicators {
    bottom: 30px;
}
#minimal-bootstrap-carousel .carousel-indicators li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: transparent;
    opacity: 1 !important;
    border: 2px solid #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;
}
#minimal-bootstrap-carousel .carousel-indicators li.active {
    background: #fff;
}
#minimal-bootstrap-carousel [class*=carousel-control-one] {
    background: none;
    width: 63px;
    height: 63px;
    font-size: 24px;
    line-height: 63px;
    color: #fff;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    visibility: visible !important;
    opacity: 1;
    transition: all 0.5s ease;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 91;
}
#minimal-bootstrap-carousel [class*=carousel-control-one]:hover {
    background: #01B9E6;
    color: #fff;
}
@media (max-width: 575px) {
    #minimal-bootstrap-carousel [class*=carousel-control-one] {
        width: 35px;
        height: 35px;
        line-height: 35px;
    }
}
#minimal-bootstrap-carousel [class*=carousel-control-one-next] {
    right: 3%;
}
#minimal-bootstrap-carousel [class*=carousel-control-one-prev] {
    left: 3%;
}
@media (max-width: 1199px) {
    #minimal-bootstrap-carousel .carousel-caption .container {
        max-width: 960px;
    }
}
@media (max-width: 991px) {
    #minimal-bootstrap-carousel .carousel-caption .container {
        max-width: 720px;
    }
}
/* fixing slider height */
#minimal-bootstrap-carousel.slider-home-two .carousel-inner .carousel-item {
    min-height: 770px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #222;
}
#minimal-bootstrap-carousel.slider-home-three .carousel-inner .carousel-item {
    min-height: 750px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #222;
}
#minimal-bootstrap-carousel.slider-home-four .carousel-inner .carousel-item {
    min-height: 850px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #222;
}
#minimal-bootstrap-carousel.slider-home-five .carousel-inner .carousel-item {
    min-height: 800px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #222;
}
.slider-content-style .content {
    position: relative;
    z-index: 990;
}
.slider-content-style .content h3 {
    color: #FFFFFF;
    font-size: 72px;
    line-height: 80px;
    font-weight: 900;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}
.slider-content-style .content p {
    color: #FFBA00;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    margin-top: 22px;
    margin-bottom: 46px;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.slider-content-style .content .thm-btn {
    padding: 0;
    background: #01B9E6;
    color: #fff;
    line-height: 56px;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}
.slider-content-style .content .thm-btn span.text-line {
    display: inline-block;
    vertical-align: middle;
    line-height: 56px;
    padding-left: 25px;
    padding-right: 25px;
}
.slider-content-style .content .thm-btn i {
    font-size: 21px;
    color: #fff;
    border-left: 1px solid #13A4C8;
    line-height: 56px;
    display: inline-block;
    vertical-align: middle;
    width: 61px;
    transition: all .4s ease;
}
.slider-content-style .content .thm-btn:hover {
    background: #2e2e2e;
}

.slider-content-style .content .thm-btn:hover i {
    border-color: rgba(255, 255, 255, 0.2);
}
.slider-content-style-three#minimal-bootstrap-carousel [class*=carousel-control-one] {
    font-size: 75px;
    background-color: transparent !important;
}
.slider-content-style-three span.tag-line {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    font-size: 14px;
    font-weight: bold;
    display: block;
    text-transform: uppercase;
}
.slider-content-style-three h3 {
    margin: 0;
    font-weight: bold;
    font-size: 60px;
    line-height: 72px;
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.slider-content-style-three p {
    margin: 0;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 40px;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}
.slider-content-style-three .thm-btn {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.slider-content-style-three .thm-btn i {
    font-size: 21px;
    display: inline-block;
    vertical-align: -3px;
    margin-left: 5px;
}
.slider-content-style-three .thm-btn:hover {
    color: #fff;
    background: #28B381;
}
.slider-content-style-four#minimal-bootstrap-carousel [class*=carousel-control-one] {
    font-size: 75px;
    background-color: transparent !important;
}
.slider-content-style-four .carousel-item {
    position: relative;
}
.slider-content-style-four .carousel-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #185F8B 0%, #34964D 42%, #91C942 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#185F8B', endColorstr='#91C942', GradientType=1);
    /* ie6-9 */
    opacity: 0.92;
}
.slider-content-style-four .carousel-item .content {
    padding-bottom: 160px;
}
.slider-content-style-four h3,
.slider-content-style-four p {
    margin: 0;
}
.slider-content-style-four h3 {
    font-size: 80px;
    line-height: 85px;
    color: #fff;
    font-weight: 900;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}
.slider-content-style-four p {
    margin-top: 15px;
    margin-bottom: 30px;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.slider-content-style-four .thm-btn {
    padding-left: 0;
    padding-right: 0;
    width: 190px;
    background: #fff;
    color: #3A3A3A;
    overflow: hidden;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

.slider-content-style-four .thm-btn:before {
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #185F8B 0%, #34964D 42%, #91C942 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#185F8B', endColorstr='#91C942', GradientType=1);
    /* ie6-9 */
}

.slider-content-style-four .thm-btn:hover {
    color: #fff;
}

.slider-content-style-four img {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.home-five-banner-outer {
    padding-left: 50px;
    padding-right: 50px;
    background: transparent;
    width: 100%;
}

.home-five-banner-outer #minimal-bootstrap-carousel [class*=carousel-control-one]:hover {
    background-color: #F9C807;
    color: #2e2e2e;
}

.home-five-banner-outer h3,
.home-five-banner-outer p {
    margin: 0;
}

.home-five-banner-outer h3 {
    font-size: 90px;
    line-height: 100px;
    color: #262626;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    margin-bottom: 16px;
}

.home-five-banner-outer h3.light {
    color: #fff;
}

.home-five-banner-outer p {
    font-size: 18px;
    line-height: 28px;
    color: #6A6D70;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    margin-bottom: 45px;
}

.home-five-banner-outer p.light {
    color: #fff;
}

.home-five-banner-outer .thm-btn {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}




/*** 
=============================================
    About Area style      
=============================================
***/
.about-area{
    position: relative;
    display: block;
    padding-top: 100px;
}
.about-image-box {
    position: relative;
    display: block;
    max-width: 550px;
    width: 100%;
    min-height: 550px;
    margin: 145px auto 0;
}
.about-image-box img{
    width: 100%;
}
.about-image-box .image-box-one{
    position: absolute;
    top: 0;
    left: 0;
    width: 260px;
    height: 260px;
}
.about-image-box .image-box-two {
    position: absolute;
    top: -145px;
    left: 0px;
    right: 0;
    width: 260px;
    height: 260px;
    margin: 0 auto;
}
.about-image-box .image-box-three {
    position: absolute;
    top: 0;
    right: 0;
    width: 260px;
    height: 260px;
}
.about-image-box .image-box-four {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 145px;
    width: 260px;
    height: 260px;
    margin: 0 auto;
}
.about-image-box .icon-box{
    position: absolute;
    top: 77px;
    left: 0;
    right: 0;
    width: 105px;
    height: 105px;
    background: transparent;
    margin: 0 auto;
    text-align: center;
    z-index: 3;
}
.about-image-box .icon-box:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d16111;
    content: "";
    z-index: -1;
    transition: all 0.5s ease-in-out 0s;
    transform-style: preserve-3d;
    transform: perspective(0px) rotateX(0deg) rotate(45deg);
}
.about-image-box:hover .icon-box:before{
    transform: perspective(0px) rotateX(0deg) rotate(-45deg);    
}
.about-image-box .icon-box span:before {
    display: block;
    padding: 20px 0;
    color: #409e35;
    font-size: 65px;
    line-height: 65px;
}

.about-text{
    position: relative;
    display: block;
    padding-top: 20px;
}
.about-text .sec-title {
    padding-bottom: 44px;
}
.about-text .inner-content{
    position: relative;
    display: block;    
}
.about-text .inner-content h3{
    color: #409e35;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
}
.about-text .inner-content .text{
    display: block;
    padding: 26px 0 17px;
}
.solar-services{
    position: relative;
    display: block;
    overflow: hidden;
}
.single-solar-box{
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 70px;
}
.single-solar-box:last-child{
    margin-right: 0;
}
.single-solar-box .icon{
    width: 40px;
}
.single-solar-box .icon span:before{
    color: #e9b531;
    font-size: 40px;
    line-height: 40px;
}
.single-solar-box .icon,
.single-solar-box .title{
    display: table-cell;
    vertical-align: middle;
}
.single-solar-box .title{
    padding-left: 20px;
}
.single-solar-box .title h3{
    color: #d16111;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    transition: all 500ms ease;
}
.single-solar-box:hover .title h3{
    color: #e9b531;
}
.about-text .button{
    position: relative;
    display: block;
    margin-top: 38px;
}



.about-style-one .about-content p {
    margin: 0;
    color: #83888D;
    font-size: 16px;
    line-height: 26px;
}

.about-style-one .about-content p.highlite {
    color: #2E2E2E;
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 16px;
}

.about-style-one .about-content ul.list-items {
    margin-top: 20px;
}

.about-style-one .about-content ul.list-items li {
    position: relative;
    color: #83888D;
    font-size: 16px;
    padding-left: 40px;
}

.about-style-one .about-content ul.list-items li+li {
    margin-top: 10px;
}

.about-style-one .about-content ul.list-items li i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    color: #01B9E6;
    font-size: 22px;
}

.about-style-two .content-text h3,
.about-style-two .content-text p {
    margin: 0;
}

.about-style-two .content-text h3 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 400;
    color: #2E2E2E;
}

.about-style-two .content-text h3 span {
    font-weight: 700;
    border-bottom: 5px solid #F9C807;
    display: inline-block;
    line-height: 1em;
}

.about-style-two .content-text p {
    font-size: 16px;
    line-height: 28px;
    color: #83888D;
    margin-top: 30px;
    margin-bottom: 30px;
}

.about-style-two .content-text .btn-block {
    margin-top: 50px;
}

.about-style-two .content-text .btn-block a+a {
    margin-left: 16px;
}

.about-style-two .content-text .single-icon {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
}

.about-style-two .content-text .single-icon+.single-icon {
    margin-left: 20px;
}

.about-style-two .content-text .single-icon i {
    color: #C0C0C0;
    font-size: 34px;
    line-height: 60px;
}

.about-style-two .about-carousel-one .owl-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
}

.about-style-two .about-carousel-one .owl-dots .owl-dot {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: transparent;
    border: 2px solid #fff;
    margin: 0px 5px;
    padding: 0px;
    border-radius: 50%;
    transition: all .3s ease;
}

.about-style-two .about-carousel-one .owl-dots .owl-dot span {
    display: none;
}

.about-style-two .about-carousel-one .owl-dots .owl-dot:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    background: #F9C807;
    border-radius: 50%;
    opacity: 0;
    transition: all .3s ease;
}

.about-style-two .about-carousel-one .owl-dots .owl-dot.active {
    border: 0px solid #CAD0CD;
}

.about-style-two .about-carousel-one .owl-dots .owl-dot.active:before {
    opacity: 1;
}




/*** 
=============================================
    Slogan area style
=============================================
***/
.slogan-area {
    position: relative;
    display: block;
    background: #d16111;
    padding: 66px 0;
    z-index: 99;
}
.slogan-area.martop-minus{
    margin-top: -45px;
}

.slogan-area .title {
    position: relative;
    display: block;
    margin: 0 0 0;
}
.slogan-area .title h2{
    color: #ffffff;
    font-size: 36px;
    line-height: 44px;
}
.slogan-area .button {
    margin: 14px 0;
}
.slogan-area .button a{

}
.slogan-area .button a:hover{
    background: #192951;
}



/*** 
=============================================
    Services Style1 Area Style      
=============================================
***/
.services-style1-area {
    position: relative;
    display: block;
    padding: 100px 0 93px;
}
.single-service-style1 {
    position: relative;
    display: block;
    margin-bottom: 50px;
}
.single-service-style1 .img-holder{
    position: relative;
    display: block;
    overflow: hidden; 
    z-index: 1;
}
.single-service-style1 .img-holder img {
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-service-style1:hover .img-holder img {
    transform: scale(1.2);    
}
.single-service-style1:hover .overlay-style-two{
	opacity: 1;
}
.single-service-style1:hover .img-holder .overlay-style-two:before{
	opacity: 1;
    transform: perspective(400px) rotateX(0deg);
    transition: all 700ms ease 100ms;	
}
.single-service-style1:hover .img-holder .overlay-style-two:after{
	opacity: 1;
    transform: perspective(400px) rotateX(0deg);
    transition: all 700ms ease 300ms;	
}

.single-service-style1 .overlay-content-box{
    position: absolute;
    left: 30px;
    bottom: 0;
    z-index: 5;
}
.single-service-style1 .overlay-content-box .icon{
    width: 50px;
    height: 50px;
    background: #e9b531;
    text-align: center;
    border-top-left-radius: 5px;
}
.single-service-style1 .overlay-content-box .icon span:before{
    display: block;
    color: #ffffff;
    font-size: 25px;
}
.single-service-style1 .overlay-content-box .icon,
.single-service-style1 .overlay-content-box .title{
    display: table-cell;
    vertical-align: middle;
}
.single-service-style1 .overlay-content-box .title{
    background: #f3f7fa;
    padding-left: 20px;
    width: 175px;
    border-top-right-radius: 5px;
}
.single-service-style1 .overlay-content-box .title h3{
    
}


.single-service-style1 .text-holder{
    position: relative;
    display: block; 
    padding: 34px 30px 0;
    box-shadow: 0px 4px 8px 2px #F0F0F0;
    transition: all 900ms ease 500ms;
}
.single-service-style1:hover .text-holder{
    -webkit-box-shadow: 0px 3px 5px 1px rgba(246,246,246,0.9); 
    box-shadow: 0px 3px 5px 1px rgba(246,246,246,0.9);
}
.single-service-style1 .text-holder:before{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 3px;
    content: "";
    background: rgb(51,152,253);
    background: linear-gradient(90deg, rgba(51,152,253,1) 0%, rgba(81,167,253,1) 40%, rgba(209,232,255,1) 100%);
}
.single-service-style1:hover .text-holder:before{
    width: 100%;
    transition: all 900ms ease 500ms;
}
.single-service-style1 .text-holder p{
    margin: 0 0 22px;    
}
.single-service-style1 .text-holder .button{
    position: relative;
    display: block;
    border-top: 1px solid #edf2f7;
    padding-top: 17px;
    padding-bottom: 32px;
}
.single-service-style1:hover .text-holder .button a{
    color: #409e35;    
}
.emergency-call{
    position: relative;
    display: block;
    margin-top: 5px;
    text-align: center;
}
.emergency-call h6{
    color: #83888d;
    font-size: 16px;
    line-height: 24px;
}
.emergency-call h6 span{
    color: #409e35;
    font-weight: 800;
}



.single-service-style-one {
    margin-bottom: 70px;
    position: relative;
}

.single-service-style-one .image-block {
    position: relative;
}

.single-service-style-one .image-block .overlay-block {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    top: 0px;
    z-index: 2;
    opacity: 0;
    transition: all 900ms ease;
}

.single-service-style-one .image-block .overlay-block:before {
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    width: 100%;
    height: 50%;
    display: block;
    opacity: 0;
    text-align: center;
    -webkit-transform: perspective(400px) rotateX(-90deg);
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    transition: all 0.5s;
    background-color: rgba(2, 31, 61, 0.9);
}

.single-service-style-one .image-block .overlay-block:after {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 50%;
    display: block;
    opacity: 0;
    text-align: center;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    transition: all 0.5s;
    background-color: rgba(2, 31, 61, 0.9);
}

.single-service-style-one .main-content {
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    opacity: 1;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    width: calc(100% - 60px);
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);
    transition: all .4s ease;
}

.single-service-style-one .main-content .icon-block,
.single-service-style-one .main-content .text-block {
    display: table-cell;
    vertical-align: middle;
}

.single-service-style-one .main-content .icon-block .inner-box {
    width: 65px;
    height: 65px;
    background: #01B9E6;
    text-align: center;
}

.single-service-style-one .main-content .icon-block .inner-box i {
    font-size: 28px;
    color: #fff;
    line-height: 65px;
}

.single-service-style-one .main-content .text-block {
    padding-left: 35px;
}

.single-service-style-one .main-content .text-block h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.single-service-style-one .main-content .text-block h3 a {
    color: #2E2E2E;
    transition: all .4s ease .2s;
}

.single-service-style-one .hover-content {
    position: absolute;
    background: #fff;
    position: absolute;
    bottom: -32.5px;
    left: 50%;
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-30%);
    transform: translateX(-50%) translateY(-30%);
    width: calc(100% - 60px);
    text-align: center;
    z-index: 10;
    padding: 33px 25px;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.1);
    transition: all .4s ease;
}

.single-service-style-one .hover-content p {
    margin: 0;
    font-size: 16px;
    line-height: 26px;
    color: #83888D;
    margin-bottom: 15px;
}

.single-service-style-one .hover-content a.read-more {
    text-transform: uppercase;
    color: #FFBA00;
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    line-height: 21px;
    position: relative;
    z-index: 11;
    transition: all .4s ease;
}

.single-service-style-one .hover-content a.read-more i {
    font-size: 21px;
    line-height: 26px;
    vertical-align: -4px;
    margin-left: 6px;
}

.single-service-style-one .hover-content a.read-more:hover {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
    color: #2E2E2E;
}

.single-service-style-one .hover-content .box-icon {
    color: #000000;
    opacity: .04;
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 160px;
    z-index: 1;
}

.single-service-style-one:hover .main-content {
    opacity: 0;
    -webkit-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
}

.single-service-style-one:hover .hover-content {
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
}

.single-service-style-one:hover .overlay-block {
    opacity: 1;
}

.single-service-style-one:hover .image-block .overlay-block:before {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    transition: all 700ms ease 100ms;
}

.single-service-style-one:hover .image-block .overlay-block:after {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    transition: all 700ms ease 300ms;
}

.services-style-two {
    position: relative;
}

.services-style-two:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 45%;
    width: 100%;
    background: #fff;
}

.services-style-two .block-text {
    color: #83888D;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    margin-top: 60px;
    position: relative;
}

.single-service-style-two {
    border-radius: 5px;
    background: #fff;
    padding: 52px 0;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.06);
}

.single-service-style-two .bubbled {
    position: absolute;
    top: 0;
    right: 0;
    width: 65px;
    height: 80px;
    text-align: center;
    z-index: 10;
}

.single-service-style-two .bubbled:before {
    content: '';
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    right: 0;
    background: #F2F7F5;
    -webkit-transform: translate(33%, -33%);
    transform: translate(33%, -33%);
    border-radius: 50%;
    z-index: 10;
    transition: all .4s ease;
}

.single-service-style-two .bubbled .count-text {
    position: relative;
    z-index: 11;
    line-height: 77px;
    display: inline-block;
    color: #83888D;
    font-size: 20px;
    font-weight: bold;
    transition: all .4s ease;
}

.single-service-style-two .main-content {
    text-align: center;
    transition: all .4s ease;
}

.single-service-style-two .main-content .icon-box {
    position: relative;
    display: inline-block;
}

.single-service-style-two .main-content .icon-box:before {
    content: '';
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #F2F7F5;
}

.single-service-style-two .main-content .icon-box i {
    color: #28B381;
    font-size: 60px;
    position: relative;
}

.single-service-style-two .main-content h3 {
    margin: 0;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 5px;
}

.single-service-style-two .main-content h3 a {
    color: #3A3A3A;
    transition: all .4s ease;
}

.single-service-style-two .main-content a.read-more {
    display: inline-block;
    line-height: 1em;
    color: #F9A03B;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all .4s ease;
}

.single-service-style-two .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #28B381;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 5px;
    opacity: 0;
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
    transition: all .4s ease;
}

.single-service-style-two .hover-content .inner-box {
    padding: 0 37px;
    padding-top: 20px;
    width: 100%;
    text-align: center;
}

.single-service-style-two .hover-content .inner-box p {
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    margin-bottom: 20px;
}

.single-service-style-two .hover-content .inner-box a.more-link {
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    transition: all .4s ease;
}

.single-service-style-two .hover-content .inner-box a.more-link:hover {
    color: #F9A03B;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

.single-service-style-two .hover-content .inner-box a.more-link i {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
}

.single-service-style-two:hover .bubbled:before {
    background: #20A575;
}

.single-service-style-two:hover .bubbled .count-text {
    color: #fff;
}

.single-service-style-two:hover .main-content {
    opacity: 1;
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%);
}

.single-service-style-two:hover .hover-content {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.single-service-style-three {
    position: relative;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 5px;
    padding: 50px 40px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
}

.single-service-style-three:before,
.single-service-style-three:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    top: 0;
    left: 0;
    transition: all .4s ease;
    border-radius: 5px;
}

.single-service-style-three:before {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    border-top: 4px solid #91C942;
    border-bottom: 4px solid #91C942;
}

.single-service-style-three:after {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    border-left: 4px solid #91C942;
    border-right: 4px solid #91C942;
}

.single-service-style-three .top-box {
    position: relative;
    z-index: 9;
    margin-bottom: 35px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.single-service-style-three .top-box .icon-box {
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #F2F2F2;
    text-align: center;
    border-radius: 5px;
}

.single-service-style-three .top-box .icon-box i {
    color: #91C942;
    font-size: 45px;
    line-height: 78px;
    position: relative;
    transition: all .4s ease;
}

.single-service-style-three .top-box .icon-box:before {
    content: '';
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #185F8B 0%, #34964D 42%, #91C942 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#185F8B', endColorstr='#91C942', GradientType=1);
    /* ie6-9 */
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: all .4s ease;
}

.single-service-style-three .top-box .num-box {
    color: #F5F4F4;
    font-size: 72px;
    font-weight: bold;
}

.single-service-style-three .text-box {
    position: relative;
    z-index: 9;
}

.single-service-style-three .text-box h3,
.single-service-style-three .text-box p {
    margin: 0;
}

.single-service-style-three .text-box h3 {
    font-size: 20px;
    font-weight: bold;
}

.single-service-style-three .text-box h3 a {
    color: #3A3A3A;
    transition: all .4s ease;
}

.single-service-style-three .text-box h3 a:hover {
    color: #34964D;
}

.single-service-style-three .text-box span,
.single-service-style-three .text-box p {
    color: #83888D;
    font-size: 16px;
    line-height: 26px;
    display: block;
}

.single-service-style-three .text-box .main-content {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    transition: all .4s ease;
}

.single-service-style-three .text-box .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    transition: all .4s ease;
}

.single-service-style-three .more-box {
    position: relative;
    z-index: 9;
    margin-top: 19px;
}

.single-service-style-three .more-box a.more-link {
    text-transform: uppercase;
    color: #2E2E2E;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    transition: all .4s ease;
}

.single-service-style-three .more-box a.more-link i {
    font-size: 20px;
    display: inline-block;
    vertical-align: -3px;
    margin-left: 10px;
}

.single-service-style-three .more-box a.more-link:hover {
    color: #34964D;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

.single-service-style-three:hover:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.single-service-style-three:hover:after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.single-service-style-three:hover .top-box .icon-box:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.single-service-style-three:hover .top-box .icon-box i {
    color: #fff;
}

.single-service-style-three:hover .text-box .main-content {
    opacity: 0;
    -webkit-transform: translateY(-40%);
    transform: translateY(-40%);
}

.single-service-style-three:hover .text-box .hover-content {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.services-style-three {
    background-color: #FFFFFF;
    background-image: url(../../../assets/images/home-pages/service-5-bg-1-1.jpg);
    background-position: left top;
    background-repeat: no-repeat;
}

.services-style-three .single-service-style-three {
    padding-top: 47px;
    padding-bottom: 47px;
    border-radius: 0;
}

.services-style-three .single-service-style-three:after,
.services-style-three .single-service-style-three:before {
    border-color: #F9C807;
    border-radius: 0;
}

.services-style-three .single-service-style-three .top-box .icon-box {
    border: 0;
    border-radius: 0;
}

.services-style-three .single-service-style-three .top-box .icon-box:before {
    background: none;
    border-radius: 0;
}

.services-style-three .single-service-style-three .top-box .icon-box::before,
.services-style-three .single-service-style-three .top-box .icon-box::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 30px;
    height: 100%;
    border: 2px solid #F9C807;
    transition: all .4s ease;
    -webkit-transform: scale(1, 1) translateY(-50%);
    transform: scale(1, 1) translateY(-50%);
}

.services-style-three .single-service-style-three .top-box .icon-box::before {
    -webkit-transform-origin: left center;
    transform-origin: left center;
    left: 0;
    border-right: 0;
}

.services-style-three .single-service-style-three .top-box .icon-box::after {
    -webkit-transform-origin: right center;
    transform-origin: right center;
    right: 0;
    border-left: 0;
}

.services-style-three .single-service-style-three .top-box .icon-box i {
    color: #2E2E2E;
    position: relative;
    z-index: 9;
}

.services-style-three .single-service-style-three:hover .top-box .icon-box:after,
.services-style-three .single-service-style-three:hover .top-box .icon-box:before {
    width: 100%;
    background-color: #F9C807;
}

.services-style-three .single-service-style-three:hover .top-box .icon-box i {
    color: #fff;
}

.services-style-three .single-service-style-three .more-box a.more-link:hover {
    color: #F9C807;
}




/*** 
=============================================
    Why Choose area Style      
=============================================
***/
.why-choose-area{
    position: relative;
    display: block;
    padding-bottom: 60px;
    z-index: 1;
}
.why-choose-area:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: url(../../../assets/images/pattern/why-choose.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}
.choose-carousel.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    padding-bottom: 40px;
}
.single-choose-box{
    position: relative;
    display: block;
    background: #ffffff;
    padding: 44px 0 50px;
    transition: all 700ms ease 400ms;
    z-index: 5;
}
.single-choose-box:hover{
	-webkit-box-shadow: 0 15px 40px rgba(0,0,0,0.08);
	-moz-box-shadow: 0 15px 40px rgba(0,0,0,0.08);
	-ms-box-shadow: 0 15px 40px rgba(0,0,0,0.08);
	-o-box-shadow: 0 15px 40px rgba(0,0,0,0.08);
	box-shadow: 0 15px 40px rgba(0,0,0,0.08);
}
.single-choose-box h3 {
    margin: 0 0 24px;
}
.single-choose-box h3 a{
    color: #d16111;
}
.single-choose-box span:before{
    color: #e9b531;
    font-size: 80px;
    line-height: 80px;
    display: block;
}
.single-choose-box .button{
    position: relative;
    display: block;
    padding-top: 30px;
}
.single-choose-box .button a{
    position: relative;
    display: block;
    width: 55px;
    height: 55px;
    background: #f1f7f9;
    border-radius: 50%;
    margin: 0 auto;
}
.single-choose-box .button a span:before {
    display: block;
    color: #d16111;
    font-size: 20px;
    line-height: 14px;
    transform: rotate(-90deg);
    padding: 20px 0 20px;
}

.single-choose-box .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 44px 30px 0;
    background: #ffffff;
    transition: all 700ms ease 100ms;
    opacity: 0;
    transform-style: preserve-3d;
    transform: perspective(1200px) rotateX(-45deg) translateZ(-50px);
}
.single-choose-box:hover .overlay-content {
	transform: perspective(1200px) rotateX(0deg) translateZ(0px);
	opacity: 1;
}
.single-choose-box .overlay-content h3{
    margin: 0 0 18px;    
}
.single-choose-box .overlay-content h3 a{
    color: #409e35;
}
.single-choose-box .overlay-content p{
    margin: 0;
}
.single-choose-box .overlay-content .button{
    padding-top: 22px;    
}
.single-choose-box .overlay-content .button a{
    background: #e9b531;
}
.single-choose-box .overlay-content .button a span:before{
    color: #ffffff;
    font-size: 20px;
    line-height: 14px;
    transform: rotate(0deg);    
}


.choose-carousel.owl-carousel .owl-nav {
    position: absolute;
    right: 0;
    top: -110px;
    margin: 0;
    display: block;
}
.choose-carousel.owl-carousel .owl-nav [class*="owl-"] {
    position: relative;
    display: inline-block;
    background: #ffffff;
    color: #83888d;
    font-size: 26px;
    font-weight: 300;
    width: 60px;
    height: 60px;
    border: 2px solid #dbe5ec;
    line-height: 54px;
    margin: 0;
    padding: 0;
        transition: all 700ms ease 100ms;
    border-radius: 50%;
}
.choose-carousel.owl-carousel .owl-nav [class*="owl-"] p {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    color: #e9b531;
    font-size: 16px;
    font-weight: 700;
    line-height: 58px;
    margin: 0;
    text-transform: capitalize;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: all 700ms ease 200ms;
}
.choose-carousel.owl-carousel .owl-nav .owl-next {
    margin-left: 10px;
}
.choose-carousel.owl-carousel .owl-nav [class*="owl-"]:hover{
    border-color: #e9b531;
}
.choose-carousel.owl-carousel .owl-nav [class*="owl-"]:hover p{
    transform: scale(1.0); 
}



/*** 
=============================================
    Working Process area Style      
=============================================
***/
.working-process-area{
    position: relative;
    display: block;
    background: #d16111;
    padding: 100px 0 92px; 
    z-index: 2;
}
.working-process-area:before{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: rgba(32, 53, 96, 0.95) none repeat scroll 0 0;
    z-index: 1;    
}
.working-process-area .image-box {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0;
    max-width: 600px;
    width: 100%;
}
.working-process-area .image-box .image img{
    width: auto;
}

.working-process-area .sec-title.with-text {
    z-index: 2;
}

.single-working-process{
    position: relative;
    display: block;
    z-index: 5;
}
.single-working-process .top-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px 0;
    width: 80px;
    height: 80px;
    background: #ffffff;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
}
.single-working-process .top-box h6{
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
    text-transform: uppercase;
    transition: all 700ms ease 500ms;
    z-index: 5;
}
.single-working-process h3{
    color: #ffffff;
    line-height: 28px;
    margin: 34px 0 17px;
}
.single-working-process p{
    color: #b3cde4;
    margin: 0;
}
.single-working-process .button{
    position: relative;
    display: block;
    padding-top: 14px;
}
.single-working-process .button a{
    color: #ffffff;
}
.single-working-process:hover .button a{
    color: #e9b531;
}

.working-process-carousel.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    z-index: 3;
}
.working-process-carousel.owl-carousel .owl-stage-outer:before {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    max-width: 670px;
    width: 100%;
    height: 4px;
    content: "";
    margin: 0 auto;
    border-bottom: 7px dotted #e9b531;
    z-index: -1;
}
.working-process-carousel.owl-carousel {
    position: relative;
    display: block;  
    z-index: 100;
}
.working-process-carousel.owl-carousel:before {
    position: absolute;
    top: 36px;
    left: 50%;
    width: 25px;
    height: 7px;
    background: #d16111;
    content: "";
    margin-left: -65px;
    z-index: 1;
}
.working-process-carousel.owl-carousel:after {
    position: absolute;
    top: 36px;
    right: 50%;
    width: 25px;
    height: 7px;
    background: #d16111;
    content: "";
    margin-right: -65px;
    z-index: 1;
}

.working-process-area .owl-theme .owl-nav {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
}
.working-process-area .owl-theme .owl-nav .owl-prev {
    position: absolute;
    left: -60px;
    top: -45px;
    background: rgba(255, 255, 255, 0.01);
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    transition: all 700ms ease 0s;
    z-index: 10;
}
.working-process-area .owl-theme .owl-nav .owl-next {
    position: absolute;
    right: -60px;
    top: -45px;
    background: rgba(255, 255, 255, 0.15);
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    transition: all 700ms ease 0s;
    z-index: 10;
}
.working-process-area .owl-theme .owl-nav .owl-prev:hover{
    background: rgba(255, 255, 255, 0.10);
}
.working-process-area .owl-theme .owl-nav .owl-next:hover{
    background: rgba(255, 255, 255, 0.10);
}
.working-process-area .owl-theme .owl-nav div .fa:hover{
    color: #fff;    
}
.working-process-area .owl-theme .owl-nav div .fa {
    display: block;
    color: #b3cde4;
    font-size: 25px;
    line-height: 60px;
    font-weight: 100;
    text-align: center;
}



/*** 
=============================================
    Fact Projects Area style
=============================================
***/
.fact-projects-area {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 100px 0 98px;
    z-index: 2;
}
.fact-projects-area .pattern{
    position: absolute;
    top: 0;
    right: 0;
    max-width: 1174px;
    height: 580px;
}

.project-content{
    position: relative;
    display: block;
}
.project-content .sec-title{
    padding-bottom: 34px;    
}
.project-content .inner-content{
    position: relative;
    display: block;
}
.project-content .inner-content p{
    margin: 0;
}
.project-content .inner-content .button{
    position: relative;
    display: block;
    padding-top: 24px;
}


.fact-counter{
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-top: 30px;
}
.single-fact-counter {
    position: relative;
    display: inline-block;
    float: left;
    width: 50%;
    padding-top: 50px;
    text-align: center;
}
.single-fact-counter:before{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: "";
    height: 3px;
    width: 60px;
    background: #409e35;
    margin: 0 auto;
}
.single-fact-counter:after {
    position: absolute;
    top: 53px;
    right: 0;
    bottom: 7px;
    width: 1px;
    background: #edf2f7;
    content: "";
}
.single-fact-counter:last-child:after{
    display: none;
}

.single-fact-counter .count-box {
    position: relative;
    display: block;
}
.single-fact-counter h1 {
    line-height: 50px;
    margin: 0 0 22px;
}
.single-fact-counter h1 span {
    position: relative;
    display: inline-block;
    color: #409e35;
    font-size: 60px;
    font-weight: 700;
}
.single-fact-counter h1 i {
    position: relative;
    display: inline-block;
    top: -4px;
    color: #409e35;
    font-size: 30px;
    line-height: 30px;
}
.single-fact-counter .count-box .title{}
.single-fact-counter .count-box .title h3 {
    color: #d16111;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    text-transform: capitalize;
}


.latest-project{
    position: relative;
    display: block;
    margin-top: 92px;
}
.single-project-style1{
    position: relative;
    display: block;
    z-index: 6;
}
.single-project-style1:before{
    position: absolute;
    left: 30px;
    bottom: 0;
    right: 30px;
    height: 10px;
    content: "";
    box-shadow: 0px 3px 6px 1px #ececec;
    transition: all 300ms ease 100ms;
    opacity: 0;
}
.single-project-style1:hover:before{
    opacity: 1;    
}
.single-project-style1 .img-holder{
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 5;
}
/*.single-project-style1 .img-holder:before{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    height: 55px;
    background: #ffffff;
    transition: all 300ms ease 100ms;
    opacity: 0;
    transform-style: preserve-3d;
    transform: perspective(1200px) rotateX(90deg) translateZ(-30px);
    z-index: 1;
}*/
/*.single-project-style1:hover .img-holder:before{
	transform: perspective(1200px) rotateX(0deg) translateZ(0px);
	opacity: 1; 
    transition: all 700ms ease 500ms;     
}*/

.single-project-style1 .img-holder img{
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-project-style1:hover .img-holder img {
    transform:scale(1.2) rotate(2deg);	
}
.single-project-style1 .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(233, 181, 49, 0.90);
    transform: scaleX(0);
    transition: all 700ms ease 100ms;
}
.single-project-style1:hover .overlay-content{
    transform: scaleY(1);
}
.single-project-style1 .overlay-content .inner-content{
    display: table;
    width: 100%;
    height: 100%;
}
.single-project-style1 .overlay-content .inner-content .link-box{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    transform: scale(0);
    transition: all 700ms ease 500ms;
}
.single-project-style1:hover .overlay-content .inner-content .link-box{
    transform: scale(1.0);    
}
.single-project-style1 .overlay-content .inner-content .link-box a span:before{
    color: #ffffff;
    font-size: 60px;
    line-height: 60px;
    transition: all 500ms ease;
}
.single-project-style1 .overlay-content .inner-content .link-box a:hover span:before{
    color: #d16111;    
}

.single-project-style1 .img-holder .categories{
    position: absolute;
    top: 30px;
    right: 30px;
    background: #e9b531;
    display: inline-block;
    padding: 8px 20px 8px;
    text-align: center;
    border-radius: 5px;
    transform: translate3d(0px, -65px, 0px);
    transition: all 100ms ease 50ms;
}
.single-project-style1:hover .img-holder .categories{
    transition: all 700ms ease 800ms;
    transform: translate3d(0px, 0px, 0px);    
}
.single-project-style1 .img-holder .categories h5{
    color: #ffffff;
    font-size: 16px;
    text-transform: capitalize;
}
.single-project-style1 .img-holder .title-box{
    position: absolute;
    left: 30px;
    bottom: 0px;
    right: 30px;
    background: #ffffff;
    text-align: center;
    display: block;
    padding-top: 25px;
    padding-bottom: 22px;
    -webkit-box-shadow: 0px 3px 6px 1px #ececec; 
    box-shadow: 0px 3px 6px 1px #ececec;
    transform-style: preserve-3d;
    transform: perspective(1200px) rotateX(90deg) translateZ(-30px);
    opacity: 0;
    transition: all 300ms ease 100ms;
    z-index: 3;
}
.single-project-style1:hover .img-holder .title-box{
    transform: translate3d(0px, 0px, 0px); 
    opacity: 1;
    transition: all 700ms ease 600ms;    
}
.single-project-style1 .img-holder .title-box h3{
    margin: 0 0 8px;
}
.single-project-style1 .img-holder .title-box span{
    font-weight: 500;
}


.latest-project .owl-carousel .owl-stage-outer{
	padding: 0px 0px 10px;
}
.latest-project .owl-dots {
    position: relative;
    display: block !important;
    margin-top: 40px !important;
    line-height: 14px;
}
.latest-project .owl-dots .owl-dot{
    position: relative;
	display: inline-block;
	width: 14px;
	height: 14px;
    background: #ffffff;
    border: 2px solid #d4e1e7;
	margin: 0px 5px;
	padding: 0px;
	border-radius: 50%;
	transition: all 500ms ease;
}
.latest-project .owl-dots .owl-dot span{
	display:none;	
}
.latest-project .owl-dots .owl-dot:before{
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    content: "";
    background: #409e35; 
    border-radius: 50%;
    opacity: 0;
    transition: all 500ms ease;
}
.latest-project .owl-dots .owl-dot.active{
    border: 2px solid #ffffff;
}
.latest-project .owl-dots .owl-dot.active:before{
    opacity: 1;    
}



/*** 
=============================================
    Pricing Plan Area Style      
=============================================
***/
.pricing-plan-area{
    position: relative;
    display: block;
    background: #f3f7fa;
    padding: 100px 0 60px;
}
.single-price-outer{
    position: relative;
    display: block; 
    transform: translate3d(0px, 0px, 0px);
    transition: all 700ms ease 300ms;
    margin-bottom: 40px;
}
.single-price-outer:hover{
    box-shadow: 0px 6px 10px 2px #e3e7e9;
    transform: translate3d(0px, -5px, 0px);    
}
.single-price-box{
    position: relative;
    display: block;
    overflow: hidden;
    background: #ffffff;
    border-top: 2px solid #e9b531;
    padding: 45px 40px 29px;
}
.single-price-box .discount-box {
    position: absolute;
    top: -22px;
    left: -20px;
    width: 0px;
    height: 80px;
    background: #e9b531;
    text-align: left;
    border-radius: 50%;
    padding-left: 34px;
    padding-top: 31px;
    transform: scale(0);
    transition: all 700ms ease 300ms;
}
.single-price-box:hover .discount-box{
    transform: scale(1);
    width: 80px;
    transition: all 700ms ease 600ms;
}
.single-price-box .discount-box h5{
    color: #f3f7fa;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-transform: capitalize;
}

.single-price-box .table-header{
    position: relative;
    display: block;
    overflow: hidden;
}
.single-price-box .table-header .top {
    position: relative;
    display: block;
    padding-bottom: 24px;
}
.single-price-box .table-header .top h3{
    font-size: 24px;
    margin: 0 0 9px;
}
.single-price-box .table-header .top span{
    
}
.single-price-box .table-header .top span b{
    color: #409e35;    
}
.single-price-box .table-header .package {
    position: relative;
    display: block;
}
.single-price-box .table-header .package h1 {
    color: #409e35;
    font-size: 60px;
    line-height: 50px;
}
.single-price-box .table-header .package span {
    position: relative;
    top: -20px;
    display: inline-block;
    font-size: 28px;
    line-height: 28px;
    padding-right: 6px;
}
.single-price-box .table-header .package b {
    position: relative;
    display: inline-block;
    top: -1px;
    color: #83888d;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    padding-left: 7px;
}

.single-price-box .button{
    position: relative;
    display: block;
    margin-top: 26px;
    margin-bottom: 11px;
}
.single-price-box .button a{
    position: relative;
    display: block;
    background: #f3f7fa;
    text-align: center;
    color: #d16111;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'TrebuchetMS', sans-serif;
    padding: 22px 0 20px;
    border-radius: 5px;
    transition: all 700ms ease 300ms;
}
.single-price-box:hover .button a{
    color: #ffffff;
    background: #e9b531;
    transition: all 700ms ease 500ms;
}

.single-price-box .list-items{
    position: relative;
    display: block;
}
.single-price-box .list-items ul {
    display: block;
}
.single-price-box .list-items ul li{
    color: #83888d;
    line-height: 52px;
    border-bottom: 1px solid #edf2f7;
}
.single-price-box .list-items ul li:last-child{
    border: none;
}



/*** 
=============================================
    Latest blog Area style
=============================================
***/
.latest-blog-area {
    position: relative;
    display: block;
    background: #ffffff;
    padding-top: 100px;
    padding-bottom: 92px;
}
.single-blog-post {
    position: relative;
    display: block;
}
.single-blog-post .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
}
.single-blog-post:hover .overlay-style-two{
	opacity: 1;
}
.single-blog-post:hover .img-holder .overlay-style-two:before{
	opacity: 1;
    transform: perspective(400px) rotateX(0deg);
    transition: all 700ms ease 100ms;	
}
.single-blog-post:hover .img-holder .overlay-style-two:after{
	opacity: 1;
    transform: perspective(400px) rotateX(0deg);
    transition: all 700ms ease 300ms;	
}
.single-blog-post .img-holder img {
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-blog-post:hover .img-holder img {
    transform:scale(1.2) rotate(2deg);	
}
.single-blog-post .post-date {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 65px;
    height: 75px;
    text-align: center;
    background: #e9b531;
    display: block;
    padding: 8px 0 6px;
    transition: all 700ms ease 500ms;	
    z-index: 3;
}
.single-blog-post:hover .post-date{
    transform: rotateX(360deg);    
}
.single-blog-post .post-date h3 {
    position: relative;
    display: block;
    font-size: 24px;
    color: #ffffff;
    line-height: 24px;
    text-transform: uppercase;
}
.single-blog-post .post-date h3 span{
    font-size: 14px;
}

.single-blog-post .text-holder {
    position: relative;
    display: block;
    padding: 29px 20px 0px;
}
.single-blog-post .text-holder .blog-title {
    position: relative;
    display: block;
    font-size: 20px;
    line-height: 28px;
}
.single-blog-post .text-holder .blog-title a{
    color: #d16111;
    transition: all 500ms ease;
}
.single-blog-post .text-holder .blog-title a:hover{
    color: #e9b531;
}

.single-blog-post .meta-box {
    position: relative;
    display: block;
    overflow: hidden;
    padding-left: 40px;
    min-height: 30px;
    margin-top: 11px;
}
.single-blog-post .meta-box .author-icon{
    position: absolute;
    top: 0;
    left: 1px;
}
.single-blog-post .meta-box .author-icon span:before{
    color: #d3d9e0;
    font-size: 30px;
    line-height: 30px;
}
.single-blog-post .meta-box .meta-info {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 5px 0;
}
.single-blog-post .meta-box .meta-info li {
    position: relative;
    display: inline-block;
    float: left;
    line-height: 20px;
    padding-right: 11px;
    margin-right: 9px;
}
.single-blog-post .meta-box .meta-info li:before{
    position: absolute;
    top: 3px;
    right: 0px;
    width: 1px;
    height: 12px;
    background: #d3d9e0;
    content: "";
}
.single-blog-post .meta-box .meta-info li:last-child{
    padding-right: 0;
    margin-right: 0;
}
.single-blog-post .meta-box .meta-info li:last-child:before{
    display: none;
}
.single-blog-post .meta-box .meta-info li a{
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
}
.single-blog-post .meta-box .meta-info li span:before{
    position: relative;
    top: 1px;
    color: #d3d9e0;
    font-size: 30px;
    line-height: 30px;
    display: inline-block;
    padding-right: 7px;
}
.single-blog-post .meta-box .meta-info li a b{
    color: #409e35;
    font-weight: 700;
}
.single-blog-post .meta-box .meta-info li a:hover{
    color: #e9b531;
}
.single-blog-post .text-holder .text{
    display: block;
    margin-top: 24px;
}
.single-blog-post .text-holder .text p{
    margin: 0 0 24px;    
}


.blog-carousel.owl-carousel .owl-nav {
    position: absolute;
    right: 0;
    top: -110px;
    margin: 0;
    display: block;
}
.blog-carousel.owl-carousel .owl-nav [class*="owl-"] {
    position: relative;
    display: inline-block;
    background: #ffffff;
    color: #83888d;
    font-size: 26px;
    font-weight: 300;
    width: 60px;
    height: 60px;
    border: 2px solid #dbe5ec;
    line-height: 54px;
    margin: 0;
    padding: 0;
        transition: all 700ms ease 100ms;
    border-radius: 50%;
}
.blog-carousel.owl-carousel .owl-nav [class*="owl-"] p {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    color: #e9b531;
    font-size: 16px;
    font-weight: 700;
    line-height: 58px;
    margin: 0;
    text-transform: capitalize;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: all 700ms ease 200ms;
}
.blog-carousel.owl-carousel .owl-nav .owl-next {
    margin-left: 10px;
}
.blog-carousel.owl-carousel .owl-nav [class*="owl-"]:hover{
    border-color: #e9b531;
}
.blog-carousel.owl-carousel .owl-nav [class*="owl-"]:hover p{
    transform: scale(1.0); 
}



/*** 
=============================================
    Testimonial Area Style      
=============================================
***/
.testimonial-area {
    position: relative;
    display: block;
    padding: 100px 0 100px;
    background: #d16111;
}

.testimonial-area .sec-title p{
    color: #5b6c9a;    
}
.single-testimonial-item{
    position: relative;
    display: block;
    background: transparent;
    padding-top: 50px;
    z-index: 1;
}
.single-testimonial-item:before {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: -10px;
    height: 10px;
    background: rgba(255, 255, 255, 0.20);
    content: "";
    z-index: 3;
}
.single-testimonial-item .img-box{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    z-index: 2;
}
.single-testimonial-item .img-box img{
    border-radius: 50%;
}
.single-testimonial-item .inner-content{
    position: relative;
    display: block;
    padding: 73px 90px 45px;
    background: #ffffff;
}
.single-testimonial-item .inner-content:before {
    position: absolute;
    top: 43px;
    left: 40px;
    font-family: 'icomoon';
    content: "\e920";
    font-size: 56px;
    line-height: 56px;
    color: #f3f7fa;
    transform: perspective(0) rotateY(180deg);
}
.single-testimonial-item .inner-content:after {
    position: absolute;
    right: 40px;
    bottom: 43px;
    font-family: 'icomoon';
    content: "\e920";
    font-size: 56px;
    line-height: 56px;
    color: #f3f7fa;
}

.single-testimonial-item .inner-content .text-box{
    position: relative;
    display: block;
    padding-bottom: 17px;
}
.single-testimonial-item .inner-content .text-box p{
    color: #83888d;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
}
.single-testimonial-item .inner-content .client-info{
    position: relative;
    display: block;    
}
.single-testimonial-item .inner-content .client-info h3{
    margin: 0 0 6px;
}
.single-testimonial-item .inner-content .client-info p{
    color: #409e35;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
}
.single-testimonial-item .inner-content .client-info p span:before {
    position: relative;
    top: 0px;
    display: inline-block;
    padding-right: 3px;
    font-size: 13px;
    line-height: 13px;
}


.testimonial-area .owl-carousel .owl-stage-outer{
	padding: 0px 0px 10px;
}
.testimonial-area .owl-dots {
    position: relative;
    display: block !important;
    margin-top: 50px !important;
    line-height: 14px;
}
.testimonial-area .owl-dots .owl-dot{
    position: relative;
	display: inline-block;
	width: 14px;
	height: 14px;
    background: #d16111;
    border: 2px solid #d4e1e7;
	margin: 0px 5px;
	padding: 0px;
	border-radius: 50%;
	transition: all 500ms ease;
}
.testimonial-area .owl-dots .owl-dot span{
	display:none;	
}
.testimonial-area .owl-dots .owl-dot:before{
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    content: "";
    background: #409e35; 
    border-radius: 50%;
    opacity: 0;
    transition: all 500ms ease;
}
.testimonial-area .owl-dots .owl-dot.active{
    border: 2px solid #d16111;
}
.testimonial-area .owl-dots .owl-dot.active:before{
    opacity: 1;    
}

.testimonial-area .button{
    position: relative;
    display: block;
    padding-top: 48px;
}
.testimonial-area .button a {
    color: #ffffff;
    background: #d16111;
    border: 1px solid #e9b531;
    width: 270px;
    line-height: 20px;
    height: auto;
    padding: 13px 0 16px;
}
.testimonial-area .button a:hover{
    background: #e9b531;
    border-color: #e9b531;
}




.testimonial-area.home-two {
    position: relative;
    background: #021f3d url(../../../assets/images/home-pages/testi-bg-1-1.png) center right no-repeat fixed;
    background-size: cover;
}

.testimonial-area.home-two:before {
    content: '';
    background: #021f3d;
    width: 100%;
    height: 100%;
    opacity: .97;
    position: absolute;
    top: 0;
    left: 0;
}

.testimonial-area.home-two .container {
    position: relative;
}

.testimonial-area.home-two .owl-dots .owl-dot:before {
    background: #FFBA00;
}

.single-testimonial-style-one {
    border: 1px solid #EAF0EE;
    border-radius: 5px;
    padding: 50px 30px;
    position: relative;
}

.single-testimonial-style-one:before,
.single-testimonial-style-one:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    top: 0;
    left: 0;
    transition: all .4s ease;
    border-radius: 5px;
}

.single-testimonial-style-one:before {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    border-top: 4px solid #28B381;
    border-bottom: 4px solid #28B381;
}

.single-testimonial-style-one:after {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    border-left: 4px solid #28B381;
    border-right: 4px solid #28B381;
}

.single-testimonial-style-one .top-block {
    margin-bottom: 20px;
}

.single-testimonial-style-one .top-block .image-block,
.single-testimonial-style-one .top-block .text-block {
    display: table-cell;
    vertical-align: middle;
}

.single-testimonial-style-one .top-block .image-block .inner-block {
    width: 85px;
    position: relative;
}

.single-testimonial-style-one .top-block .image-block .inner-block i[class*=energy-icon-] {
    position: absolute;
    right: -20px;
    top: -10px;
    display: block;
    width: 38px;
    height: 38px;
    background: #28B381;
    color: #fff;
    line-height: 38px;
    font-size: 22px;
    text-align: center;
    border-radius: 50%;
}

.single-testimonial-style-one .top-block .image-block .inner-block>img {
    width: 100%;
    border-radius: 50%;
    position: relative;
}

.single-testimonial-style-one .top-block .text-block {
    padding-left: 38px;
}

.single-testimonial-style-one .top-block .text-block h3 {
    color: #3A3A3A;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.single-testimonial-style-one .top-block .text-block span {
    color: #F9A03B;
    font-size: 16px;
}

.single-testimonial-style-one .content-block p {
    margin: 0;
    color: #83888D;
    font-size: 18px;
    line-height: 28px;
}

.single-testimonial-style-one:hover:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.single-testimonial-style-one:hover:after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.testimonials-carousel-two .owl-dots {
    position: relative;
    display: block !important;
    margin-top: 50px !important;
    line-height: 14px;
}

.testimonials-carousel-two .owl-dots .owl-dot {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: transparent;
    border: 2px solid #CAD0CD;
    margin: 0px 5px;
    padding: 0px;
    border-radius: 50%;
    transition: all .3s ease;
}

.testimonials-carousel-two .owl-dots .owl-dot span {
    display: none;
}

.testimonials-carousel-two .owl-dots .owl-dot:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    background: #f9a03b;
    border-radius: 50%;
    opacity: 0;
    transition: all .3s ease;
}

.testimonials-carousel-two .owl-dots .owl-dot.active {
    border: 0px solid #CAD0CD;
}

.testimonials-carousel-two .owl-dots .owl-dot.active:before {
    opacity: 1;
}

.single-testimonial-style-two .top-box {
    position: relative;
}

.single-testimonial-style-two .top-box i.energy-icon-quote {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    font-size: 106px;
    color: #132A39;
}

.single-testimonial-style-two .top-box p {
    margin: 0;
    font-size: 24px;
    line-height: 38px;
    color: #FFFFFF;
    position: relative;
    z-index: 9;
}

.single-testimonial-style-two .name-box {
    margin-top: 30px;
}

.single-testimonial-style-two .name-box .image-block,
.single-testimonial-style-two .name-box .text-block {
    display: table-cell;
    vertical-align: middle;
}

.single-testimonial-style-two .name-box .image-block {
    width: 80px;
}

.single-testimonial-style-two .name-box .image-block>img {
    width: 100%;
    border-radius: 50%;
}

.single-testimonial-style-two .name-box .text-block {
    padding-left: 15px;
}

.single-testimonial-style-two .name-box .text-block h3 {
    margin: 0;
    line-height: 1em;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
}

.single-testimonial-style-two .name-box .text-block span {
    display: block;
    line-height: 1em;
    color: #34964D;
    font-size: 16px;
}

.testimonials-carousel-three {
    margin-top: 27px;
}

.testimonials-carousel-three .owl-dots {
    position: absolute;
    bottom: 0;
    right: 0;
}

.testimonials-carousel-three .owl-dots .owl-dot {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: transparent;
    border: 2px solid #1D3E53;
    margin: 0px 5px;
    padding: 0px;
    border-radius: 50%;
    transition: all .3s ease;
}

.testimonials-carousel-three .owl-dots .owl-dot span {
    display: none;
}

.testimonials-carousel-three .owl-dots .owl-dot:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    background: #34964D;
    border-radius: 50%;
    opacity: 0;
    transition: all .3s ease;
}

.testimonials-carousel-three .owl-dots .owl-dot.active {
    border: 0px solid #CAD0CD;
}

.testimonials-carousel-three .owl-dots .owl-dot.active:before {
    opacity: 1;
}

.testimonials-carousel-three .owl-nav {
    position: absolute;
    bottom: 0;
    right: 0;
}

.testimonials-carousel-three .owl-nav [class*='owl-'] {
    width: 60px;
    height: 60px;
    background-color: transparent;
    border: 2px solid #2F2F2F;
    font-size: 20px;
    color: #83888D;
    border-radius: 50%;
    line-height: 46px;
    transition: all .4s ease;
}

.testimonials-carousel-three .owl-nav [class*='owl-']:hover {
    color: #fff;
    background: #F9C807;
    border-color: #F9C807;
}

.home-five .single-testimonial-style-two .top-box i.energy-icon-quote {
    color: #232323;
}

.home-five .single-testimonial-style-two .name-box .text-block span {
    color: #F9C807;
}






/*** 
=============================================
    Consultation Area Style      
=============================================
***/
.consultation-area {
    position: relative;
    display: block;
    background: url(../../../assets/images/parallax-background/consultation-bg.jpg);
    background-position: center top;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 100px;
    padding-bottom: 100px;
    z-index: 1;
}
.consultation-area::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(243, 247, 250, 0.85);
    z-index: -1;
}
.consultation-box{
    position: relative;
    display: block;
    padding-top: 64px;
    padding-bottom: 70px;
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
    background: #ffffff;
}
.consultation-box .title-box{
    position: relative;
    display: block;  
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 42px;
}
.consultation-box .title-box h2{
    font-size: 30px;
    line-height: 38px;
    margin: 0 0 5px;
}
.consultation-box .title-box span{
    font-size: 18px;
    line-height: 28px;
}


.consultation{
    position: relative;
    display: block;
    padding: 0 100px 0;
}
.consultation-form .single-box{
    position: relative;
    display: block;
}
.consultation-form input[type="text"], 
.consultation-form input[type="email"], 
.consultation-form textarea {
    position: relative;
    display: block;
    padding: 0 20px;
    border: 1px solid #edf2f7;
    background: #ffffff;
    color: #83888d;
    font-size: 16px;
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    font-weight: 400;
    border-radius: 5px;
    transition: all 500ms ease; 
}
.consultation-form input[type="text"]:focus{
    border-color: #e9b531;    
}
.consultation-form input[type="email"]:focus{
    border-color: #e9b531;     
}
.consultation-form .single-box button{
    display: block;
    width: 100%;
    background: #e9b531;
    color: #ffffff;
}
.consultation-form input::-webkit-input-placeholder {
    color: #83888d;
}
.consultation-form input:-moz-placeholder {
    color: #83888d;
}
.consultation-form input::-moz-placeholder {
    color: #83888d;
}
.consultation-form input:-ms-input-placeholder {
    color: #83888d;
}

.consultation-form .single-box .bootstrap-select {
    position: relative;
    display: block;
}
.consultation-form .single-box .bootstrap-select>.dropdown-toggle {
    outline: none !important;
    border-radius: 5px;
    border: 1px solid #edf2f7;
    height: 60px;
    background-color: transparent;
    padding: 0;
    padding-left: 20px;
    color: #83888d;
    line-height: 60px;
    margin: 0;
    box-shadow: none !important;
}
.consultation-form .single-box .bootstrap-select>.dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 20px;
    width: auto;
    height: auto;
    font-family: 'FontAwesome';
    content: '\f107';
    color: #83888d;
    font-size: 18px;
    transform: translateY(-50%);
    margin: 0;
    border: none;
}
.consultation-form .single-box .bootstrap-select .dropdown-menu {
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: 0px solid #ddd;
    background: #f9f9f9;
    font-size: 16px;
    color: #000000;
}
.consultation-form .single-box .dropdown-item {
    display: block;
    width: 100%;
    padding: 9px 20px 9px;
    font-weight: 400;
    color: #222222;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    transition: all 500ms ease;
}
.consultation-form .single-box .dropdown-item.active,
.consultation-form .single-box .dropdown-item:active {
    background: #e9b531;
    color: #fff;
}
.consultation-form .single-box .dropdown-item:hover {
    background: #e9b531;
    color: #fff;
}


.consultation-bottom-text {
    position: relative;
    display: block;
    padding-top: 45px;
}
.consultation-bottom-text .top{
    position: relative;
    display: block;
    line-height: 20px;
    z-index: 1;
}
.consultation-bottom-text .top:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    content: "";
    background: #edf2f7;
    margin: 9px 0;
    z-index: -1;
}
.consultation-bottom-text .top span{
    position: relative;
    display: inline-block;
    padding: 0 15px;
    background: #ffffff;
    color: #83888d;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 2;
}
.consultation-bottom-text .inner {
    position: relative;
    display: block;
    padding: 28px 120px 0;
}
.consultation-bottom-text .inner .text{
    padding-bottom: 22px;
}
.consultation-bottom-text .inner .text p{
    margin: 0;
}
.consultation-bottom-text .inner a{
    display: inline-block;
    padding: 0 50px 0;
    background: #d16111;
    color: #ffffff;    
}



/*** 
=============================================
    Brand Area Style      
=============================================
***/
.brand-area {
    position: relative;
    display: block;
    padding-top: 55px;
    padding-bottom: 85px;
}
.brand-items{
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
}
.single-brand-item {
    position: relative;
    display: inline-block;
    overflow: hidden;
    float: left;
    max-width: 170px;
    width: 100%;
    margin-bottom: 30px;
    margin-right: 70px;
    transition: all 700ms ease;
}
.single-brand-item:hover{
  
}
.single-brand-item:last-child{
    margin-right: 0;
}
.single-brand-item a {
    display: block;
    text-align: center;
}
.single-brand-item a img{
    width: auto;
}

.single-brand-item .overlay-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: alpha(opacity=0);
    opacity: 0;
    transform: scale(3);
    transition: all 900ms ease;
}
.single-brand-item:hover .overlay-box{
   filter: alpha(opacity=100);
   opacity: 1;
   transform: scale(1);
}
.single-brand-item .overlay-box a{
    display: block;    
}
.single-brand-item .overlay-box a img{
    width: auto;    
}



/*** 
=============================================
    Download Material Area style
=============================================
***/
.download-material-area{
    position: relative;
    display: block; 
    margin-top: -60px;
    margin-bottom: -60px;
    z-index: 33;
}
.download-material {
    position: relative;
    display: block;
    overflow: hidden;
    background: #e9b531;
    border-radius: 5px;
}
.download-material{
    position: relative;
    display: block; 
    padding: 38px 60px 37px;
}
.download-material .title-box {
    position: relative;
    display: block;
    margin: 5px 0 6px;
}
.download-material .title-box h3{
    color: #ffffff;
    font-size: 24px;
    line-height: 34px;
    font-weight: 500;
}
.download-material .button {
    position: relative;
    display: block;
    height: 45px;
}
.download-material .button a{
    position: relative;
    display: inline-block;       
}
.download-material .button a .icon {
    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
}
.download-material .button a .icon span:before{
    color: #ffffff;
    font-size: 40px;
    line-height: 40px;
}
.download-material .button a .icon,
.download-material .button a .title{
    display: table-cell;
    vertical-align: middle;
}
.download-material .button a .title{
    padding-left: 13px;
}
.download-material .button a .title h4{
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline;
    text-transform: capitalize;
}



/*** 
=============================================
    Footer area style
=============================================
***/
.footer-area {
    position: relative;
    display: block;
    background: #d16111;
    padding-top: 80px;
    padding-bottom: 62px;
}
.footer-area.pdtop100{
    padding-top: 100px;    
}
.single-footer-widget {
    position: relative;
    display: block;
    overflow: hidden;
}
.single-footer-widget .title {
    position: relative;
    display: block;
    margin-top: -5px;
    margin-bottom: 26px;
}
.single-footer-widget .title h3 {
    color: #ffffff;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    text-transform: capitalize;
}

.single-footer-widget .contact-info-box{
    position: relative;
    display: block;
}
.single-footer-widget .contact-info-box .footer-logo {
    position: relative;
    display: block;
    padding-bottom: 34px;
}
.single-footer-widget .contact-info-box .footer-logo a{
    display: inline-block;
}
.single-footer-widget .contact-info-box .text{
    position: relative;
    display: block;    
}
.single-footer-widget .contact-info-box .text p{
    color: #ffffff;
    margin: 0;
}
.single-footer-widget .contact-info-box .text ul {
    position: relative;
    display: block;
    padding: 5px 0 19px;
}
.single-footer-widget .contact-info-box .text ul li {
    color: #ffffff;
    line-height: 37px;
}
.single-footer-widget .contact-info-box .text ul li span{
    color: #409e35;
}
.single-footer-widget .contact-info-box .text a{
    color: #ffffff;    
}
.single-footer-widget .contact-info-box .text a:hover{
    color: #e9b531;
}


.single-footer-widget .usefull-links{
    position: relative;
    display: block;
}
.single-footer-widget .usefull-links ul{
    position: relative;
    display: block;
    overflow: hidden;
}
.single-footer-widget .usefull-links ul li {
    line-height: 28px;
    margin-bottom: 12px;
}
.single-footer-widget .usefull-links ul li:last-child{
    margin-bottom: 0;
}
.single-footer-widget .usefull-links ul li a{
    position: relative;
    display: block;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    padding-left: 25px;
    transition: all 500ms ease;
}
.single-footer-widget .usefull-links ul li a:hover{
    color: #409e35;
    padding-left: 30px;
}
.single-footer-widget .usefull-links ul li a:before {
    position: absolute;
    top: -1px;
    left: 0;
    content: "\f101";
    font-family: FontAwesome;
    color: #e9b531;
    font-size: 18px;
    transition: all 0.3s ease-in-out 0.1s;
}
.single-footer-widget .usefull-links ul li a:hover:before{
    left: 5px;
    color: #409e35; 
}


.single-footer-widget .case-studies {
    position: relative;
    display: block;
    overflow: hidden;
    padding-top: 7px;
    margin-left: -10px;
    margin-right: -10px;
}
.single-footer-widget .case-studies li {
    position: relative;
    display: inline-block;
    float: left;
    margin: 0 10px 20px;
}
.single-footer-widget .case-studies li .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 110px;
    max-height: 95px;

}
.single-footer-widget .case-studies li .img-holder img{
    transition: all 0.5s ease-in-out 0.6s;
    width: 100%;
    transform: scale(1.0, 1.0); 
}
.single-footer-widget .case-studies li:hover .img-holder img{
    transform: scale(1.2, 1.2);
} 
.single-footer-widget .case-studies li:hover .img-holder .overlay-style-one{
    opacity: 1;    
}
.single-footer-widget .case-studies li .img-holder .overlay-style-one .content a span:before{
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    color: #d16111;
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    filter: alpha(opacity=0);
    opacity: 0;
    transform: scale(5);
    transition: all 0.5s ease-in-out 0.3s;
}
.single-footer-widget .case-studies li:hover .img-holder .overlay-style-one .content a span:before{
    filter: alpha(opacity=100);
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.1s;   
}



/*** 
=============================================
    Footer Bottom Area Style      
=============================================
***/
.footer-bottom-area {
    position: relative;
    display: block;
    background: #d16111;
}
.footer-bottom-area .inner-content {
    position: relative;
    display: block;
    border-top: 1px solid #e9b531;
    padding: 23px 0 23px;
}
.footer-bottom-area .copyright-text {
    margin: 6px 0;
}
.footer-bottom-area .copyright-text p {
    color: #ffffff;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
}
.footer-bottom-area .copyright-text p a {
    color: #e9b531;
    font-weight: 500;
    transition: all 500ms ease;
}
.footer-bottom-area .copyright-text p a:hover{
    color: #ffffff;
}

.call-us-now{
    position: relative;
    display: block;
    max-width: 270px;
    width: 100%;
    margin: 0 auto;
}
.call-us-now .icon{
    width: 60px;
}
.call-us-now .icon span:before{
    position: relative;
    top: 2px;
    color: #e9b531;
    font-size: 60px;
    line-height: 60px;
}
.call-us-now .icon,
.call-us-now .title{
    display: table-cell;
    vertical-align: middle;
}

.call-us-now .title{
    padding-left: 20px;
}
.call-us-now .title span{
    color: #ffffff;
}
.call-us-now .title h3{
    color: #ffffff;
    margin: 3px 0 0;
}
.footer-social-links {
    position: relative;
    display: block;
    margin: 7px 0;
}

.site-footer.footer-two {
    background: #021f3d;
    padding: 100px 0;
    position: relative;
}

.site-footer.footer-two .footer-widget .title-line {
    margin-bottom: 40px;
}

.site-footer.footer-two .footer-widget .title-line h3 {
    margin: 0;
    color: #fff;
    font-size: 24px;
}

.site-footer.footer-two .footer-widget ul.links-list li a {
    color: #fff;
    display: block;
    font-size: 16px;
    position: relative;
    padding-left: 26px;
    line-height: 1em;
    transition: all .4s ease;
}

.site-footer.footer-two .footer-widget ul.links-list li a:hover {
    color: #31557a;
}

.site-footer.footer-two .footer-widget ul.links-list li a i {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.site-footer.footer-two .footer-widget ul.links-list li+li a {
    margin-top: 20px;
}

.site-footer.footer-two .footer-widget.links-widget .row .col-sm-6:last-child ul.links-list {
    margin-left: 23px;
}

.site-footer.footer-two .footer-widget.links-widget ul.links-list.has-sep {
    position: relative;
}

.site-footer.footer-two .footer-widget.links-widget ul.links-list.has-sep:before {
    content: '';
    width: 1px;
    height: 100%;
    background: #213A54;
    position: absolute;
    top: 0;
    right: -20px;
}

.site-footer.footer-two .footer-widget .single-post-content+.single-post-content {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid #213A54;
}

.site-footer.footer-two .footer-widget .single-post-content .image-box,
.site-footer.footer-two .footer-widget .single-post-content .text-block {
    display: table-cell;
    vertical-align: middle;
}

.site-footer.footer-two .footer-widget .single-post-content .image-box {
    width: 80px;
}

.site-footer.footer-two .footer-widget .single-post-content .image-box>img {
    width: 100%;
    transition: all .4s ease;
}

.site-footer.footer-two .footer-widget .single-post-content .text-block {
    padding-left: 20px;
}

.site-footer.footer-two .footer-widget .single-post-content .text-block .date-line {
    color: #FFBA00;
    font-size: 16px;
    display: block;
    line-height: 1em;
}

.site-footer.footer-two .footer-widget .single-post-content .text-block .date-line i {
    margin-right: 4px;
}

.site-footer.footer-two .footer-widget .single-post-content .text-block h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    margin-top: 5px;
}

.site-footer.footer-two .footer-widget .single-post-content .text-block h3 a {
    color: #fff;
    transition: all .4s ease;
}

.site-footer.footer-two .footer-widget .single-post-content .text-block h3 a:hover {
    color: #31557a;
}

.site-footer.footer-two .footer-widget .single-post-content:hover .image-box>img {
    opacity: .5;
}

.site-footer.footer-two .footer-widget .newsletter-wrapper p {
    color: #FFFFFF;
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
}

.site-footer.footer-two .footer-widget .newsletter-wrapper input {
    display: block;
    width: 100%;
    height: 56px;
    border: 1px solid #213A54;
    background: #0b2847;
    border-radius: 5px;
    color: #556677;
    padding-left: 20px;
}

.site-footer.footer-two .footer-widget .newsletter-wrapper input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #556677;
}

.site-footer.footer-two .footer-widget .newsletter-wrapper input::-moz-placeholder {
    /* Firefox 19+ */
    color: #556677;
}

.site-footer.footer-two .footer-widget .newsletter-wrapper input:-ms-input-placeholder {
    /* IE 10+ */
    color: #556677;
}

.site-footer.footer-two .footer-widget .newsletter-wrapper input:-moz-placeholder {
    /* Firefox 18- */
    color: #556677;
}

.site-footer.footer-two .footer-widget .newsletter-wrapper .thm-btn {
    margin-top: 20px;
}

.site-footer.footer-two .back-to-top {
    position: absolute;
    bottom: 50px;
    right: 50px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all .4s ease;
}

.site-footer.footer-two .back-to-top i {
    font-size: 25px;
    display: inline-block;
    vertical-align: -2px;
}

.site-footer.footer-two .back-to-top:hover {
    color: #01B9E6;
}

.footer-bottom-home-two {
    background: #fff;
    padding: 22px 0;
}

.footer-bottom-home-two p {
    margin: 0;
    color: #83888D;
    font-size: 16px;
}

.footer-bottom-home-two p a {
    color: #01B9E6;
}

.footer-bottom-home-two .footer-menu {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-bottom-home-two .footer-menu li {
    display: inline-block;
    vertical-align: middle;
}

.footer-bottom-home-two .footer-menu li+li {
    margin-left: 30px;
}

.footer-bottom-home-two .footer-menu li a {
    color: #83888D;
    font-size: 16px;
    transition: all .4s ease;
}

.footer-bottom-home-two .footer-menu li a:hover {
    color: #01B9E6;
}

.site-footer.footer-three,
.footer-bottom-home-three {
    background: #192521;
}

.site-footer.footer-three {
    padding: 100px 0;
}

.site-footer.footer-three .footer-widget .title-line {
    margin-bottom: 25px;
}

.site-footer.footer-three .footer-widget .title-line h3 {
    margin: 0;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
}

.site-footer.footer-three .footer-widget .footer-logo {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-top: 30px;
}

.site-footer.footer-three .footer-widget.about-widget p {
    color: #BFC7C5;
    margin: 0;
}

.site-footer.footer-three .footer-widget.about-widget .copy-text {
    color: #FFFFFF;
    font-size: 16px;
}

.site-footer.footer-three .footer-widget.about-widget .copy-text span {
    color: #F9A03B;
}

.site-footer.footer-three .footer-widget ul.links-list li {
    display: block;
}

.site-footer.footer-three .footer-widget ul.links-list li+li {
    margin-top: 10px;
}

.site-footer.footer-three .footer-widget ul.links-list li a {
    display: inline-block;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
}

.site-footer.footer-three .footer-widget .newsletter-wrapper p {
    color: #BFC7C5;
    font-size: 16px;
    margin: 0;
    margin-bottom: 20px;
}

.site-footer.footer-three .footer-widget .newsletter-wrapper input {
    display: block;
    width: 100%;
    height: 56px;
    border: 1px solid #2A3934;
    background: #1C2925;
    border-radius: 5px;
    color: #556677;
    padding-left: 20px;
}

.site-footer.footer-three .footer-widget .newsletter-wrapper input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #556677;
}

.site-footer.footer-three .footer-widget .newsletter-wrapper input::-moz-placeholder {
    /* Firefox 19+ */
    color: #556677;
}

.site-footer.footer-three .footer-widget .newsletter-wrapper input:-ms-input-placeholder {
    /* IE 10+ */
    color: #556677;
}

.site-footer.footer-three .footer-widget .newsletter-wrapper input:-moz-placeholder {
    /* Firefox 18- */
    color: #556677;
}

.site-footer.footer-three .footer-widget .newsletter-wrapper .thm-btn {
    margin-top: 20px;
    width: 170px;
    padding-left: 0;
    padding-right: 0;
}

.site-footer.footer-three .footer-widget .contact-lists li {
    position: relative;
    padding-left: 30px;
    color: #FFFFFF;
    font-size: 16px;
}

.site-footer.footer-three .footer-widget .contact-lists li i {
    font-size: 19px;
    color: #28B381;
    position: absolute;
    top: 4px;
    left: 0;
}

.site-footer.footer-three .footer-widget .contact-lists li+li {
    margin-top: 15px;
}

.site-footer.footer-three .footer-widget .view-on-map {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
    transition: all .3s ease;
}

.site-footer.footer-three .footer-widget .view-on-map i {
    font-size: 20px;
    display: inline-block;
    vertical-align: -3px;
    margin-left: 6px;
}

.site-footer.footer-three .footer-widget .view-on-map:hover {
    padding-left: 5px;
    color: #F9A03B;
}

.site-footer.footer-three .footer-widget .footer-contact-carousel-one .owl-nav {
    margin: 0;
}

.site-footer.footer-three .footer-widget .footer-contact-carousel-one .owl-dots {
    position: absolute;
    top: -56px;
    right: 0;
}

.site-footer.footer-three .footer-widget .footer-contact-carousel-one .owl-dots .owl-dot {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: transparent;
    border: 2px solid #32423D;
    margin: 0px 5px;
    padding: 0px;
    border-radius: 50%;
    transition: all .3s ease;
}

.site-footer.footer-three .footer-widget .footer-contact-carousel-one .owl-dots .owl-dot span {
    display: none;
}

.site-footer.footer-three .footer-widget .footer-contact-carousel-one .owl-dots .owl-dot:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    background: #f9a03b;
    border-radius: 50%;
    opacity: 0;
    transition: all .3s ease;
}

.site-footer.footer-three .footer-widget .footer-contact-carousel-one .owl-dots .owl-dot.active {
    border: 0px solid #CAD0CD;
}

.site-footer.footer-three .footer-widget .footer-contact-carousel-one .owl-dots .owl-dot.active:before {
    opacity: 1;
}
.footer-contact-carousel-one.owl-carousel.owl-theme.owl-loaded.owl-drag{
    max-width: 269px;    
}




.footer-bottom-home-three {
    padding: 24.5px 0;
    border-top: 1px solid #2a3934;
}

.footer-bottom-home-three .social a {
    width: 50px;
    height: 50px;
    border: 1px dashed #3E4E49;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #FFFFFF;
    line-height: 48px;
    border-radius: 50%;
    transition: all .4s ease;
}

.footer-bottom-home-three .social a+a {
    margin-left: 11px;
}

.footer-bottom-home-three .social a:hover {
    color: #28B381;
    border-color: #28B381;
}

.footer-bottom-home-three .footer-menu {
    padding: 11.5px 0;
}

.footer-bottom-home-three .footer-menu li {
    display: inline-block;
    vertical-align: middle;
}

.footer-bottom-home-three .footer-menu li+li {
    margin-left: 30px;
}

.footer-bottom-home-three .footer-menu li a {
    color: #BFC7C5;
    font-size: 16px;
    transition: all .4s ease;
}

.footer-bottom-home-three .footer-menu li a:hover {
    color: #28B381;
}

.site-footer.footer-four,
.footer-bottom-home-two.home-four {
    background: #061F2E;
}

.site-footer.footer-four .footer-widget .single-post-content+.single-post-content {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.site-footer.footer-four .footer-widget .single-post-content .text-block .date-line {
    color: #91C942;
}

.site-footer.footer-four .footer-widget.about-widget p {
    color: #8B9397;
    font-size: 16px;
    line-height: 26px;
    margin: 23px 0 30px;
}

.site-footer.footer-four .footer-widget ul.links-list li a {
    color: #8B9397;
}

.site-footer.footer-four .footer-widget ul.links-list li a:hover {
    color: #fff;
}

.site-footer.footer-four .footer-widget.links-widget ul.links-list.has-sep:before {
    background: rgba(255, 255, 255, 0.1);
}

.site-footer.footer-four .footer-widget .contact-lists li {
    position: relative;
    padding-left: 30px;
    color: #8B9397;
    font-size: 16px;
}

.site-footer.footer-four .footer-widget .contact-lists li i {
    font-size: 19px;
    color: #91C942;
    position: absolute;
    top: 4px;
    left: 0;
}

.site-footer.footer-four .footer-widget .contact-lists li+li {
    margin-top: 13px;
}

.site-footer.footer-four .back-to-top:hover {
    color: #91C942;
}

.footer-bottom-home-two.home-four {
    padding: 0px 0;
}

.footer-bottom-home-two.home-four .container-outer {
    padding: 22px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom-home-two.home-four p {
    color: #FFFFFF;
}

.footer-bottom-home-two.home-four p a {
    color: #91C942;
}

.footer-bottom-home-two.home-four .footer-menu li a {
    color: #8B9397;
}

.footer-bottom-home-two.home-four .footer-menu li a:hover {
    color: #fff;
}

.site-footer.footer-five {
    background-color: #0A0A0A;
    padding: 100px 0;
}

.site-footer.footer-five .footer-widget .title-line {
    margin-bottom: 33px;
}

.site-footer.footer-five .footer-widget .title-line h2 {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
}

.site-footer.footer-five .footer-widget .title-line h2::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #F9C807;
    margin-top: 25px;
}

.site-footer.footer-five .footer-widget ul.links-list li a {
    color: #7A7A7A;
    font-size: 16px;
    display: inline-block;
    transition: all .4s ease;
    position: relative;
}

.site-footer.footer-five .footer-widget ul.links-list li a::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
}

.site-footer.footer-five .footer-widget ul.links-list li a:hover {
    color: #FFFFFF;
}

.site-footer.footer-five .footer-widget ul.links-list li a:hover::before {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.site-footer.footer-five .footer-widget ul.links-list li+li {
    margin-top: 15px;
}

.site-footer.footer-five .footer-widget .single-contact-info+.single-contact-info {
    margin-top: 25px;
}

.site-footer.footer-five .footer-widget .single-contact-info span,
.site-footer.footer-five .footer-widget .single-contact-info p {
    display: block;
    margin: 0;
    font-size: 16px;
}

.site-footer.footer-five .footer-widget .single-contact-info span {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1em;
}

.site-footer.footer-five .footer-widget .single-contact-info p {
    color: #fff;
    margin-top: 10px;
}

.site-footer.footer-five .footer-widget.map-widget .top-block {
    margin-bottom: 40px;
}

.site-footer.footer-five .footer-widget.map-widget .bootstrap-select {
    float: right;
}

.site-footer.footer-five .footer-widget.map-widget .bootstrap-select>.dropdown-toggle {
    border: none;
    outline: none !important;
    background-color: transparent;
    border-radius: 0;
    padding: 12px 0px;
    padding-left: 20px;
    box-shadow: none;
    background-color: transparent;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    border: 1px solid #2F2F2F;
}

.site-footer.footer-five .footer-widget.map-widget .bootstrap-select>.dropdown-toggle:after {
    border: none;
    width: auto;
    height: auto;
    content: '\f107';
    font-family: 'FontAwesome';
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.site-footer.footer-five .footer-widget.map-widget .bootstrap-select .dropdown-menu {
    margin: 0;
    padding: 0;
    background: #131313;
    border-radius: 0;
}

.site-footer.footer-five .footer-widget.map-widget .bootstrap-select .dropdown-menu .dropdown-item {
    border-radius: 0 !important;
    padding: 4px 12px;
    transition: all .4s ease;
    color: #fff;
}

.site-footer.footer-five .footer-widget.map-widget .bootstrap-select .dropdown-menu .dropdown-item.active,
.site-footer.footer-five .footer-widget.map-widget .bootstrap-select .dropdown-menu .dropdown-item:hover,
.site-footer.footer-five .footer-widget.map-widget .bootstrap-select .dropdown-menu .dropdown-item:active {
    background: #F9C807;
}

.site-footer.footer-five .footer-widget.map-widget .bootstrap-select .dropdown-menu .dropdown-item img {
    display: inline-block;
    vertical-align: -2px;
}

.footer-bottom-home-five {
    padding: 24.5px 0;
    background-color: #131313;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom-home-five .social a {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #FFFFFF;
    line-height: 48px;
    border-radius: 50%;
    transition: all .4s ease;
}

.footer-bottom-home-five .social a+a {
    margin-left: 11px;
}

.footer-bottom-home-five .social a:hover {
    color: #F9C807;
    border-color: #F9C807;
}

.footer-bottom-home-five .footer-menu {
    padding: 11.5px 0;
}

.footer-bottom-home-five .footer-menu li {
    display: inline-block;
    vertical-align: middle;
}

.footer-bottom-home-five .footer-menu li+li {
    margin-left: 30px;
}

.footer-bottom-home-five .footer-menu li a {
    color: #BFC7C5;
    font-size: 16px;
    transition: all .4s ease;
}

.footer-bottom-home-five .footer-menu li a:hover {
    color: #fff;
}




/*** 
=============================================
    Breadcrumb area style
=============================================
***/
.breadcrumb-area {
    position: relative;
    background-attachment: scroll;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 33px 0 0px;
    z-index: 1;
}
.breadcrumb-area::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
    content: "";
    z-index: -1;
}
.breadcrumb-area .inner-content {
    position: relative;
    display: block;
    padding: 109px 0;
}
.breadcrumb-area .title {
    display: block;
}
.breadcrumb-area .title h1{
    color: #d16111;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0 0 0px;
}

.breadcrumb-area .breadcrumb-menu {
    position: relative;
    display: inline-block;
    line-height: 10px;
}
.breadcrumb-area .breadcrumb-menu ul {
    position: relative;
    display: inline-block;
    line-height: 30px;
    margin: 11px 0 9px;
}
.breadcrumb-area .breadcrumb-menu ul li {
    position: relative;
    display: inline-block;
    float: left;
    color: #d16111;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 500ms ease;
    margin-right: 15px;
    padding-right: 15px;
}
.breadcrumb-area .breadcrumb-menu ul li:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -5px;
    width: 9px;
    height: 3px;
    background: url(../../../assets/images/icon/arrow.png);
    content: "";
    margin: 14px 0;
}
.breadcrumb-area .breadcrumb-menu ul li:last-child:before{
    display: none;
} 
.breadcrumb-area .breadcrumb-menu ul li:last-child{
    margin-right: 0;
    padding-right: 0;
}
.breadcrumb-area .breadcrumb-menu ul li a{
    color: #d16111;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 500ms ease;
}
.breadcrumb-area .breadcrumb-menu ul li.active{
    font-weight: 700;
}
.breadcrumb-area .breadcrumb-menu ul li a:hover{
    color: #e9b531;     
}



/*** 
=============================================
    Whowe Are Area Style      
=============================================
***/
.whowe-are-area{
    position: relative;
    display: block;
    padding: 100px 0 52px;
}
.whowe-are-area .sec-title {
    padding-bottom: 33px;
}
.company-info-box{
    position: relative;
    display: block;
    max-width: 950px;
    margin: 0 auto;
    padding-bottom: 62px;
    text-align: center;
}
.company-info-box p{
    line-height: 28px;
    margin: 0 0 25px;
}
.company-info-box h3{
    margin: 0 0 3px;
}
.company-info-box span{
    color: #409e35;
}

.whowe-are-image{
    position: relative;
    display: block;
}
.whowe-are-image img{
    width: 100%;
}

.whowe-are{
    padding-top: 70px;
}
.single-whowe-are-box {
    position: relative;
    display: block;
    padding: 0 30px;
    margin-bottom: 40px;
}
.single-whowe-are-box .icon{
    position: relative;
    display: block;    
}
.single-whowe-are-box .icon span:before{
    color: #e9b531;
    font-size: 80px;
    line-height: 80px;
}
.single-whowe-are-box .text{
    position: relative;
    display: block;    
}
.single-whowe-are-box .text h3{
    margin: 22px 0 17px;
}
.single-whowe-are-box .text p{
    margin: 0;
}
.single-whowe-are-box .count-box {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    color: #edf2f7;
    font-size: 130px;
    font-weight: 700;
    line-height: 120px;
    text-transform: uppercase;
}



/*** 
=============================================
    Choose Area Style      
=============================================
***/
.choose-area{
    position: relative;
    display: block;
    padding: 100px 0 90px;
    background: #ffffff;
}
.why-whoose-content{
    position: relative;
    display: block;
}
.why-whoose-content .sec-title {
    padding-bottom: 34px;
}
.why-whoose-content .inner-content{
    position: relative;
    display: block;    
}
.why-whoose-content .inner-content p{
    margin: 0 0 20px;
}
.why-whoose-content .inner-content ul{
    position: relative;
    display: block;        
}
.why-whoose-content .inner-content ul li{
    line-height: 38px;
}
.why-whoose-content .inner-content ul li span:before{
    position: relative;
    top: 2px;
    display: inline-block;
    padding-right: 14px;
    color: #e9b531;
    font-size: 18px;
    line-height: 18px;
}

.whychoose-right-content{
    position: relative;
    display: block;
}
.whychoose-right-content ul{
    display: block;
}
.whychoose-right-content ul li {
    position: relative;
    display: block;
    padding-left: 100px;
    min-height: 100px;
    margin-bottom: 50px;
}
.whychoose-right-content ul li:last-child{
    margin-bottom: 0;
}
.whychoose-right-content ul li .icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    box-shadow: 0px 3px 5px 1px #f4f4f4;
    z-index: 1;
    transition: all 500ms ease 100ms;
}
.whychoose-right-content ul li:hover .icon{
    box-shadow: none;    
}
.whychoose-right-content ul li .icon:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: #e9b531;
    transform: scale(0.5);
    opacity: 0;
    border-radius: 5px;
    transition: all 500ms ease 100ms;
    z-index: -1;
}
.whychoose-right-content ul li:hover .icon:before{
    transform: scale(1.0);
    opacity: 1;
    transition: all 500ms ease 100ms;
}
.whychoose-right-content ul li .icon span:before {
    display: block;
    padding: 20px 0 19px;
    color: #e9b531;
    font-size: 56px;
    line-height: 56px;
    transition: all 700ms ease 300ms;
}
.whychoose-right-content ul li:hover .icon span:before{
    color: #ffffff;
    transition: all 700ms ease 500ms;    
}
.whychoose-right-content ul li .text{
    position: relative;
    display: block;
    padding-left: 40px;
}
.whychoose-right-content ul li .text h3{
    margin: 0 0 18px;
}
.whychoose-right-content ul li .text p{
    margin: 0;
}



/*** 
=============================================
    Fact Counter Area Style      
=============================================
***/
.fact-counter-area {
    position: relative;
    display: block;
    background-attachment: fixed;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 117px 0 74px;
    z-index: 1;
}
.fact-counter-area::before {
    background: rgba(32, 53, 96, 0.95) none repeat scroll 0 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    z-index: -1;
} 
.fact-counter.style2{
    margin: 0;
}
.single-fact-counter.style2 {
    position: relative;
    display: inline-block;
    float: left;
    max-width: 390px;
    padding-top: 0;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
}
.single-fact-counter.style2:before {
    display: none;
}
.single-fact-counter.style2:after {
    position: absolute;
    top: 3px;
    right: 0px;
    bottom: 6px;
    width: 1px;
    background: #354773;
    content: "";
}
.single-fact-counter.style2 .count-box .title h3 {
    color: #ffffff;
    font-size: 20px;
    line-height: 26px;
}



/*** 
=============================================
    Team Area style
=============================================
***/
.team-area {
    position: relative;
    display: block;
    padding-top: 100px;
    padding-bottom: 98px;
}
.single-team-member {
    position: relative;
    display: block;
    overflow: hidden;
    transition: all 500ms ease;
}
.single-team-member .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
}
.single-team-member .img-holder img{
    width: 100%;
    transform: scale(1.0);
    transition: all 700ms ease;
}
.single-team-member:hover .img-holder img{
    transform:scale(1.05) rotate(0deg);	
}

.single-team-member .name {
    position: relative;
    display: block;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #f4f4f4;
    padding: 24px 0 27px;
    z-index: 1;
    transform: translateX(0%) translateY(0px) scale(1.0);
    opacity: 1;
    box-shadow: 0px 3px 5px 1px #f4f4f4;
    transition: all 700ms ease 600ms;
}
.single-team-member:hover .name{
    transform: translateX(0%) translateY(0px) scale(1.0); 
    opacity: 0;
    transition: all 700ms ease 300ms;
}
.single-team-member .name h3{
    margin: 0 0 8px;
    transition: all 500ms ease;
    transition-delay: .4s;
}
.single-team-member .name p{
    color: #409e35;
    line-height: 16px;
    margin: 0;
}

.single-team-member .name.overlay-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 25px 0px 30px;
    background: #d16111;
    opacity: 0;
    margin: 0;
    border: none;
    opacity: 0;
    box-shadow: none;
    transform: translateX(0%) translateY(100%) scale(1.0);
    transition: all 500ms ease 100ms;
}
.single-team-member:hover .name.overlay-content{
    opacity: 1; 
    transform: translateX(0%) translateY(0px) scale(1);
    transition: all 900ms ease 50ms;
}
.single-team-member .name.overlay-content h3{
    color: #ffffff;
    margin: 0 0 8px;
}
.single-team-member .name.overlay-content p{
    color: #f2cb42;
}

.single-team-member .sociallinks {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 27px;
    text-align: center;
}
.single-team-member .sociallinks li {
    display: inline-block;
    float: none;
    margin: 0 3px;
    line-height: 45px;
}
.single-team-member .sociallinks li a i{
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #ffffff;
    border: 2px solid #ffffff;
    color: #83888d;
    font-size: 14px;
    line-height: 42px;
    transition: all 500ms ease;
}
.single-team-member .sociallinks li a:hover i{
    color: #e9b531;
    border-color: #e9b531;
    transform: translateX(0%) rotate(360deg)scale(1);
}

.team-area .owl-dots {
    position: relative;
    display: block !important;
    margin-top: 50px !important;
    line-height: 14px;
}
.team-area .owl-dots .owl-dot{
    position: relative;
	display: inline-block;
	width: 14px;
	height: 14px;
    background: #ffffff;
    border: 2px solid #d4e1e7;
	margin: 0px 5px;
	padding: 0px;
	border-radius: 50%;
	transition: all 500ms ease;
}
.team-area .owl-dots .owl-dot span{
	display:none;	
}
.team-area .owl-dots .owl-dot:before{
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    content: "";
    background: #409e35; 
    border-radius: 50%;
    opacity: 0;
    transition: all 500ms ease;
}
.team-area .owl-dots .owl-dot.active{
    border: 2px solid #ffffff;
}
.team-area .owl-dots .owl-dot.active:before{
    opacity: 1;    
}



/*** 
=============================================
    Brand Area Style2 Style      
=============================================
***/
.brand-area.style2 {
    position: relative;
    display: block;
    background: #f3f7fa;
    padding: 55px 0 25px;
}



/*** 
=============================================
    Services Style1 Area Service Page Style      
=============================================
***/
.services-style1-area.service-page{
    padding-bottom: 50px;
}



/*** 
=============================================
    Single Service Area style
=============================================
***/
.single-service-area{
    position: relative;
    display: block;
    padding: 100px 0 70px;
}
.single-service-top {
    position: relative;
    display: block;
    margin-top: -7px;
}
.single-service-top h1{
    font-size: 40px;
    line-height: 48px;
    margin: 0 0 34px;
}
.single-service-top .text{
    position: relative;
    display: block;
    padding-bottom: 46px;
}
.single-service-top .text p{
 
}

.scope-of-work{
    position: relative;
    display: block;
}
.scope-of-work .img-holder{
    position: relative;
    display: block;
    z-index: 3;
}
.scope-of-work .img-holder img{
    width: 100%;
}
.scope-of-work .button{
    position: relative;
    display: block;
    margin-top: 10px;
}
.scope-of-work .button a{
    position: relative;
    display: block;
    text-align: center;
    color: #ffffff;
    background: #e9b531;
    z-index: 1;
}
.scope-of-work .button a:hover{
    background: #2f8b07;
}



.scope-of-work .text-holder{
    position: relative;
    display: block;    
}
.scope-of-work .text-holder h2{
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 28px;
}
.scope-of-work .text-holder p{
    margin: 0;
}
.scope-of-work .text-holder .inner-content {
    position: relative;
    display: block;
    margin: 32px 0 0;
    -webkit-box-shadow: 0px 2px 8px 3px #f8f8f8;
    box-shadow: 0px 2px 8px 3px #f8f8f8;
    padding: 37px 30px 25px;
}
.scope-of-work .text-holder .inner-content h5{
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 11px;
}
.scope-of-work .text-holder .inner-content ul{
    position: relative;
    display: block;
}
.scope-of-work .text-holder .inner-content ul li{
    position: relative;
    display: block;
    padding-left: 35px;
    line-height: 44px;
    border-bottom: 1px solid #edf2f7;
}
.scope-of-work .text-holder .inner-content ul li:last-child{
    border: none;
}
.scope-of-work .text-holder .inner-content ul li:before {
    content: "\f00c";
    font-family: FontAwesome;
    color: #e9b531;
    font-size: 16px;
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    font-weight: 100;
}


.services-include-box{
    position: relative;
    display: block;
    padding-top: 64px;
}
.services-include-box .title{
    position: relative;
    display: block;
    padding-bottom: 32px;
}
.services-include-box .title h2{
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 26px;
}
.services-include-box .title p{
    color: #83888d;
    margin: 0;
}
.services-include-box .inner-content{
    position: relative;
    display: block;    
}
.single-service-include {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 34px 0 40px;
    text-align: center;
    background: #f3f7fa;
    max-width: 236px;
    width: 100%;
    margin: 0 auto 30px;
}
.single-service-include .icon{
    position: relative;
    display: block;    
}
.single-service-include .icon span:before{
    color: #e9b531;
    font-size: 75px;
    line-height: 75px;
}
.single-service-include h3{
    line-height: 28px;
    margin: 14px 0 33px;
}
.single-service-include .button .readmore1{
    position: relative;
    display: block;
    width: 55px;
    height: 55px;
    background: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
}
.single-service-include .button .readmore1 span:before {
    display: block;
    color: #d16111;
    font-size: 20px;
    line-height: 14px;
    padding: 20px 0 20px;
}

.single-service-include .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f3f7fa;
    display: block;
    padding: 34px 20px 0;
    opacity: 0;
    transform: translateX(0%) translateY(100%) scale(1.0);
    transition: all 500ms ease 100ms;
}
.single-service-include:hover .overlay-content{
    opacity: 1; 
    transform: translateX(0%) translateY(0px) scale(1);
    transition: all 900ms ease 50ms;
}
.single-service-include .overlay-content h3 {
    margin: 0;
}
.single-service-include .overlay-content p{
    margin: 17px 0 32px;
}
.single-service-include .overlay-content .readmore2{
    position: relative;
    display: block;
    width: 175px;
    height: 50px;
    margin: 0 auto;
    border-radius: 5px;
    background: #e9b531;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 52px;
    text-align: center;
    transition: all 500ms ease;
}
.single-service-include .overlay-content .readmore2:hover{
    background: #2f8b07;
}
.single-service-include .overlay-content .readmore2 span:before {
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    position: relative;
    display: inline-block;
    padding-left: 10px;
    top: 4px;
}



.flexible-pricing-box {
    position: relative;
    display: block;
    padding-top: 35px;
}
.flexible-pricing-box .title{
    display: block;
    padding-bottom: 32px;
}
.flexible-pricing-box .title h2{
    font-size: 30px;
}

.single-flexible-box{
    position: relative;
    display: block; 
    max-width: 370px;
    width: 100%;
    margin: 0 auto 30px;
}

.single-flexible-box .static-content{
    position: relative;
    display: block;
    overflow: hidden;
}
.single-flexible-box .static-content .discount-box {
    position: absolute;
    top: -22px;
    left: -20px;
    width: 0px;
    height: 80px;
    background: #e9b531;
    text-align: left;
    border-radius: 50%;
    padding-left: 34px;
    padding-top: 31px;
    transform: scale(0);
    z-index: 3;
    transition: all 700ms ease 500ms;
}
.single-flexible-box:hover .static-content .discount-box{
    transform: scale(1);
    width: 80px;
    transition: all 700ms ease 600ms;
}
.single-flexible-box .static-content .discount-box h5{
    color: #f3f7fa;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-transform: capitalize;
}


.single-flexible-box .static-content .table-header {
    position: relative;
    display: block;
    border: 1px solid #f2f2f2;
    border-top: 3px solid #e9b531;
    padding: 44px 0 44px;
}
.single-flexible-box .static-content .table-header .top {
    position: relative;
    display: block;
    padding-bottom: 24px;
}
.single-flexible-box .static-content .table-header .top h3{
    font-size: 24px;
    margin: 0 0 9px;
}
.single-flexible-box .static-content .table-header .top span{
    
}
.single-flexible-box .static-content .table-header .top span b{
    color: #409e35;    
}
.single-flexible-box .static-content .table-header .package {
    position: relative;
    display: block;
}
.single-flexible-box .static-content .table-header .package h1 {
    color: #409e35;
    font-size: 60px;
    line-height: 50px;
}
.single-flexible-box .static-content .table-header .package span {
    position: relative;
    top: -20px;
    display: inline-block;
    font-size: 28px;
    line-height: 28px;
    padding-right: 6px;
}
.single-flexible-box .static-content .table-header .package b {
    position: relative;
    display: inline-block;
    top: -1px;
    color: #83888d;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    padding-left: 7px;
}
.single-flexible-box .static-content .table-header .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: block;
    padding: 24px 40px 0px;
    transform: translateX(0%) translateY(100%) scale(0.5);
    transition: all 700ms ease 100ms;
}
.single-flexible-box:hover .static-content .table-header .overlay-content{
    transform: translateX(0%) translateY(0%) scale(1.0); 
    transition: all 900ms ease 100ms;
}
.single-flexible-box .static-content .table-header .overlay-content .list-items {
    position: relative;
    display: block;
}
.single-flexible-box .static-content .table-header .overlay-content .list-items ul{}
.single-flexible-box .static-content .table-header .overlay-content .list-items ul li{
    line-height: 50px;
    border-bottom: 1px solid #edf2f7;
}
.single-flexible-box .static-content .table-header .overlay-content .list-items ul li:last-child{
    border-bottom: none;
}

.single-flexible-box .static-content .button{
    position: relative;
    display: block;
    margin-top: 20px;
}
.single-flexible-box .static-content .button a{
    position: relative;
    display: block;
    background: #f3f7fa;
    text-align: center;
    color: #d16111;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'TrebuchetMS', sans-serif;
    padding: 24px 0 22px;
    border-radius: 5px;
    transition: all 700ms ease 300ms;
}
.single-flexible-box:hover .static-content .button a{
    color: #ffffff;
    background: #e9b531;
    transition: all 700ms ease 500ms;
}



.single-service-sidebar {
    position: relative;
    display: block;
    margin-left: 35px;
    max-width: 335px;
    width: 100%;
}
.single-service-sidebar .single-sidebar{
    position: relative;
    display: block;
}
.single-service-sidebar .service-pages{
    display: block;
    margin-bottom: 60px;
}
.single-service-sidebar .service-pages li{
    position: relative;
    display: block;
    border: 1px solid #edf2f7;
    padding: 0 30px;
    margin-bottom: 10px;
    z-index: 5;
}
.single-service-sidebar .service-pages li:last-child{
    margin-bottom: 0;
}
.single-service-sidebar .service-pages li:before {
    position: absolute;
    top: -1px;
    left: -11px;
    bottom: -1px;
    width: 10px;
    background: #e9b531;
    content: "";
    z-index: 3;
    opacity: 0;
    transition: all 700ms ease 300ms;
    transform: translateX(0%) translateY(0%) scaleY(0.1);
}
.single-service-sidebar .service-pages li.active:before,
.single-service-sidebar .service-pages li:hover:before{
    opacity: 1;
    transform: translateX(0%) translateY(0%) scaleY(1.0);
    transition: all 700ms ease 100ms;
}
.single-service-sidebar .service-pages li:after {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: #d16111;
    content: "";
    z-index: -1;
    opacity: 0;
    transition: all 700ms ease 300ms;
    transform: translateX(0%) translateY(0%) scaleY(0.1);
}
.single-service-sidebar .service-pages li.active:after,
.single-service-sidebar .service-pages li:hover:after{
    opacity: 1;
    transform: translateX(0%) translateY(0%) scaleY(1.0); 
    transition: all 700ms ease 100ms;
}

.single-service-sidebar .service-pages li a {
    position: relative;
    display: block;
    color: #d16111;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    transition: all 500ms ease;
    transition-delay: .2s;
    padding: 17px 0 16px;
}
.single-service-sidebar .service-pages li a .icon{
    width: 30px;
}
.single-service-sidebar .service-pages li a .icon span:before {
    position: relative;
    top: 3px;
    color: #cbd2d9;
    font-size: 20px;
    line-height: 20px;
    transition: all 700ms ease 300ms;
}
.single-service-sidebar .service-pages li a .icon,
.single-service-sidebar .service-pages li a .title{
    display: table-cell;
    vertical-align: middle;
}
.single-service-sidebar .service-pages li a .title{
    padding-left: 15px;
}
.single-service-sidebar .service-pages li a .title h3{
    font-size: 18px;
    line-height: 20px;
    transition: all 700ms ease 300ms;
}
.single-service-sidebar .service-pages li.active a .title h3,
.single-service-sidebar .service-pages li:hover a .title h3{
    color: #409e35;
    transition: all 700ms ease 600ms;    
}
.single-service-sidebar .service-pages li.active a .icon span:before,
.single-service-sidebar .service-pages li:hover a .icon span:before{
    color: #409e35;
    transition: all 700ms ease 500ms;       
}



.sidebar-contact-box{
    position: relative;
    display: block;
    padding-bottom: 60px;
}
.sidebar-contact-box .img-holder{
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
}
.sidebar-contact-box .img-holder:before{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    height: 70%;
    background: rgb(32,50,96);
    background: linear-gradient(0deg, rgba(32,50,96,1) 0%, rgba(32,50,96,1) 0%, rgba(32,50,96,0) 100%, rgba(32,50,96,0.6530987394957983) 100%, rgba(80,166,253,1) 100%, rgba(93,173,253,1) 100%, rgba(100,177,253,1) 100%);
}
.sidebar-contact-box .inner-content{
    position: relative;
    display: block;
    padding: 20px 30px 0;
    background: #d16111;
    z-index: 1;
}
.sidebar-contact-box .inner-content:before{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 30px;
    content: "";
    background: #ffffff;
    z-index: -1;
}
.sidebar-contact-box .inner-content .icon-holder{
    position: relative;
    display: block;
    padding-bottom: 15px;
}
.sidebar-contact-box .inner-content .icon-holder span:before{
    color: #409e35;
    font-size: 65px;
    line-height: 65px;
}
.sidebar-contact-box .inner-content h3{
    color: #ffffff;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 22px;
}
.sidebar-contact-box .inner-content .bottom-box{
    position: relative;
    display: block;
    border-top: 1px solid #e9b531;
    padding-top: 25px;
}
.sidebar-contact-box .inner-content .bottom-box h2{
    color: #ffffff;
    font-size: 24px;
    font-weight: 400;
    margin: 0 0 3px;
}
.sidebar-contact-box .inner-content .bottom-box span{
    color: #ffffff;
    font-size: 16px;
}
.sidebar-contact-box .inner-content .button {
    position: relative;
    display: block;
    padding-top: 32px;
}
.sidebar-contact-box .inner-content .button a{ 
    -webkit-box-shadow: 0px 3px 7px 2px #ededed; 
    box-shadow: 0px 3px 7px 2px #ededed;
}





.single-service-sidebar .service-pack-download {
    position: relative;
    display: block;
}
.single-service-sidebar .service-pack-download li {
    position: relative;
    display: block;
    background: #f3f7fa;
    padding: 25px 30px 25px;
    padding-right: 0;
    transition: all 500ms ease;
}
.single-service-sidebar .service-pack-download li .icon-holder {
    width: 65px;
}
.single-service-sidebar .service-pack-download li .icon-holder span:before{
    color: #e9b531;
    font-size: 45px;
    line-height: 45px;
}
.single-service-sidebar .service-pack-download li .icon-holder,
.single-service-sidebar .service-pack-download li .title-holder{
    display: table-cell;
    vertical-align: middle;
}
.single-service-sidebar .service-pack-download li .title-holder{
    border-left: 1px solid #dbe5ec;
    padding-left: 20px;
}

.single-service-sidebar .service-pack-download li .title-holder h3 {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 4px;
}
.single-service-sidebar .service-pack-download li .title-holder a{
    color: #83888d;
    font-weight: 500;
}
.single-service-sidebar .service-pack-download li .title-holder a:hover{
    color: #e9b531;    
}



/*** 
=============================================
    Faq Area style
=============================================
***/
.faq-area{
    position: relative;
    display: block;
    padding: 96px 0 100px;
}
.faq-area .sec-title {
    padding-bottom: 29px;
    margin: -4px auto 0;
}
.faq-area .sec-title .icon{
    display: block;
    padding-bottom: 12px;
}
.faq-area .sec-title .icon span{
    margin: 0;
}
.faq-area .sec-title .icon span:before{
    color: #e9b531;
    font-size: 80px;
    line-height: 80px;
}
.faq-area .sec-title .title{
    margin: 0;
}

.faq-search-box form.search-form {
    position: relative;
    display: block;
    max-width: 770px;
    width: 100%;
    margin: 0px auto 0px;
}
.faq-search-box .search-form input[type="search"] {
    position: relative;
    display: block;
    background: #fff;
    border: 1px solid #edf2f7;
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    height: 60px;
    letter-spacing: 1px;
    padding-left: 20px;
    padding-right: 80px;
    width: 100%;
    border-radius: 0;
    transition: all 500ms ease 0s;
}
.faq-search-box .search-form button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    display: block;
    background: transparent;
    font-size: 14px;
    color: #e9b531;
    height: 60px;
    width: 70px;
    border: 0px solid;
    line-height: 60px;
    text-align: center;
    border-radius: 0%;
    transition: all 500ms ease 0s;
    margin: 0;
}
.faq-search-box .search-form button i {
    font-size: 14px
}
.faq-search-box .search-form input[type="search"]:focus {
    border: 1px solid #e9b531;
    background: #fff;
    color: #000000;
}
.faq-search-box .search-form input[type="search"]:focus + button, 
.faq-search-box .search-form button:hover {
    background: #e9b531;
    color: #fff;
}
.faq-search-box p{
    display: block;
    text-align: center;
    margin: 24px 0 52px;
}
.faq-search-box p a{
    color: #409e35;
}
.faq-content-box {
    position: relative;
    display: block;
}



/*** 
=============================================
    Error Page Area Style      
=============================================
***/
.error-page-area{
    position: relative;
    display: block;
    background: #f3f7fa;
    padding: 175px 0 150px;
}
.error-content{
    position: relative;
    display: block;
    z-index: 3;
}
.error-content:before {
    position: absolute;
    top: -175px;
    left: 0;
    right: 0px;
    margin: 0 auto;
    content: '';
    background: url(../../../assets/images/icon/cloud.png);
    width: 1200px;
    height: 576px;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    animation-name: float-bob;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: float-bob;
    -webkit-animation-duration: 30s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 30s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 30s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 30s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}
.error-content span{
    color: #409e35;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
.error-content .title{
    position: relative;
    display: block;
    color: #d16111;
    font-size: 180px;
    line-height: 150px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 13px 0 14px;
}
.error-content p{
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 32px;
}
.error-content .button{}
.error-content .button a {
    width: 270px;
    background: #e9b531;
    color: #ffffff;
}
.error-content .button a span:before{
    color: #ffffff;    
}
.error-content .button a:hover{
    background: #2e8906;
}



/*** 
=============================================
    Blog Default Area style
=============================================
***/
#blog-area{
    padding: 100px 0 100px;
}
#blog-area.blog-default-area {
    padding-bottom: 40px;
}
.blog-default-area .single-blog-post {
    margin-bottom: 52px;
}


/*** 
=============================================
    Blog Style2 Area style
=============================================
***/
.blog-style2-area{
    position: relative;
    display: block;
}
.single-blog-post.style2{
    margin-bottom: 53px;    
}
.single-blog-post.style2 .text-holder {
    position: relative;
    display: block;
    padding: 28px 0px 0px;
}
.single-blog-post.style2 .post-date{
    position: absolute;
    left: auto;
    bottom: auto;
    top: 0px;
    right: 0px;
    width: 65px;
    height: 75px;
    text-align: center;
    background: #e9b531;
    display: block;
    padding: 8px 0 6px;
    transition: all 700ms ease 500ms;
    z-index: 3;    
}
.single-blog-post.style2 .sttg-comax{
    position: absolute;
    left: auto;
    bottom: 0px;
    top: auto;
    right: 0px;
    width: 40px;
    height: 40px;
    text-align: center;
    background: #e9b531;
    display: block;
    padding: 8px 0 6px;
    transition: all 700ms ease 500ms;
    z-index: 3;
    border-top-left-radius: 50%;
    cursor: pointer;
}
.single-blog-post.style2 .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: all 700ms ease 500ms;
    transform: scale(0);
    opacity: 1;
    z-index: 2;
}
.single-blog-post.style2:hover .overlay-content{
    transform: scale(1.0);
    transition: all 700ms ease 1000ms;
}
.single-blog-post.style2 .overlay-content .box {
    display: table;
    height: 100%;
    width: 100%;
}
.single-blog-post.style2 .overlay-content .box .read-more {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.single-blog-post.style2 .overlay-content .box .read-more a{
    color: #ffffff;
}
.single-blog-post.style2 .overlay-content .box .read-more a:hover{
    color: #e9b531;
}
.single-blog-post.style2 .meta-box {
    position: relative;
    display: block;
    overflow: hidden;
    padding-left: 40px;
    min-height: 30px;
    margin-top: 0;
    margin-bottom: 14px;
}



/*** 
=============================================
    Blog large Area style
=============================================
***/
.blog-large-area{
    position: relative;
    display: block;
    background: #f3f7fa;
}
.blog-large-area .blog-post {
    position: relative;
    display: block;
    z-index: 1;
}
.single-blog-post.style3 {
    position: relative;
    display: block;
    margin-bottom: 60px;
}
.single-blog-post.style3 .blog-title {
    display: block;
    border-bottom: 1px solid #dbe5ec;
    font-size: 30px;
    line-height: 40px;
    padding: 0px 0 15px;
    margin-top: -7px;
    margin-bottom: 23px;
}
.single-blog-post.style3 .blog-title a{
    color: #393939;
    transition: all 500ms ease;
} 
.single-blog-post.style3 .blog-title a:hover{
    color: #e9b531;
}
.single-blog-post.style3 .img-holder{
    position: relative;
    z-index: 1;
}
.single-blog-post.style3 .img-holder:before{
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    height: 40px;
    content: "";
    background: #f3f7fa;
    z-index: 5;
}
.single-blog-post.style3 .post-date {
    position: absolute;
    left: auto;
    bottom: auto;
    top: 0;
    right: 20px;
    width: 65px;
    height: 75px;
    text-align: center;
    background: #e9b531;
    display: block;
    padding: 8px 0 6px;
    transition: all 700ms ease 500ms;
    z-index: 3;
}
.single-blog-post.style3 .text-holder {
    position: relative;
    display: block;
    background: #f3f7fa;
    padding: 0 60px 0;
    z-index: 3;
}
.single-blog-post.style3 .meta-box {
    margin-top: 0;
}
.single-blog-post.style3 .text-holder .text {
    display: block;
    margin-top: 24px;
}
.single-blog-post.style3 .text-holder .text p{
    margin: 0;
}
.single-blog-post.style3 .button{
    position: relative;
    display: block;
    margin-top: 32px;
    padding: 0 20px;
}
.single-blog-post.style3 .button a{
    position: relative;
    display: block;
    background: #f3f7fa;
    border: 1px solid #dbe5ec;
    text-align: center;
}


#blog-area .sidebar-wrapper {
    position: relative;
    display: block;
    max-width: 370px;
    width: 100%;
}
.sec-title.sidebar-title{
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -2px;
    padding-bottom: 40px;    
}
.sec-title.sidebar-title .title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 16px;
}
.sidebar-wrapper .single-sidebar {
    position: relative;
    display: block;
}

.sidebar-search-box .search-form {
    position: relative;
    display: block;
    max-width: 370px;
    margin-bottom: 60px;
}
.sidebar-search-box .search-form input[type="text"] {
    position: relative;
    display: block;
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    height: 60px;
    width: 100%;
    padding-left: 70px;
    padding-right: 10px;
    border-radius: 0px;
    transition: all 500ms ease 0s;
}
.sidebar-wrapper .search-form button {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    background: transparent;
    color: #83888d;
    font-size: 14px;
    height: 60px;
    width: 60px;
    border: 0px solid #fec144;
    line-height: 60px;
    text-align: center;
    border-radius: 0px;
    transition: all 500ms ease 0s;
    margin: 0;
}
.sidebar-wrapper .search-form button:before {
    position: absolute;
    top: 20px;
    right: 0px;
    bottom: 20px;
    width: 1px;
    background: #d1dce3;
    content: "";
}
.sidebar-wrapper .search-form button i {
    font-size: 14px
}
.sidebar-wrapper .search-form input[type="text"]:focus {
    border: 1px solid #e9b531;
    background: #fff;
    color: #000000;
}
.sidebar-wrapper .search-form input[type="text"]:focus + button, 
.sidebar-wrapper .search-form button:hover {
    border-color: #e9b531;
    color: #e9b531;
}


.single-sidebar .categories {
    position: relative;
    margin-left: -5px;
    margin-right: -5px;
}
.single-sidebar .categories li {
    position: relative;
    display: inline-block;
    float: left;
    width: 180px;
    margin: 0 5px 10px;
    text-align: center;
    z-index: 1;
}
.single-sidebar .categories li a {
    position: relative;
    display: block;
    background: #ffffff;
    color: #d16111;
    font-size: 16px;
    line-height: 55px;
    font-weight: 700;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 700ms ease 100ms;
    z-index: 1;
}
.single-sidebar .categories li:hover a{
    color: #ffffff;
    transition: all 700ms ease 500ms;
}
.single-sidebar .categories li a:before{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0;
    width: 0px;
    background: #e9b531;
    content: "";
    z-index: -1;
    opacity: 0;
    transition: all 700ms ease 100ms;
    transform: translateX(0%) translateY(0%) scaleY(0.1);    
}
.single-sidebar .categories li:hover a:before{
    opacity: 1;
    transform: translateX(0%) translateY(0%) scaleY(1.0); 
    width: 100%;
    transition: all 700ms ease 100ms;
} 












.single-sidebar.popular-post-box{
    padding-top: 50px;    
}
.single-sidebar .popular-post {
    position: relative;
    display: block;
    overflow: hidden;
}
.single-sidebar .popular-post li {
    position: relative;
    display: block;
    border-bottom: 1px solid #dbe5ec;
    min-height: 80px;
    padding-left: 80px;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.single-sidebar .popular-post li:last-child{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}
.single-sidebar .popular-post li .img-holder {
    position: absolute;
    top: 0px;
    left: 0;
    width: 80px;
    height: 80px;
    display: block;
    overflow: hidden;
}
.single-sidebar .popular-post li .img-holder img {
    transition: all 0.4s ease-in-out 0.5s;    
    width: 100%;
    transform: scale(1.1, 1.1);      
}
.single-sidebar .popular-post li:hover .img-holder img {
    transition-delay: 0s;
    transform: scale(1.2, 1.2);    
}
.single-sidebar .popular-post li .img-holder .overlay-style-one{
    background: rgba(233, 181, 49, 0.90);
}
.single-sidebar .popular-post li .img-holder .overlay-style-one .box .content a{
    display: inline-block;
    width: 20px;
    height: 20px;
    filter: alpha(opacity=0);
    transform: scale(3);
    transition: all 0.3s ease-in-out 0.3s;
    opacity: 0;
    z-index: 5;    
}
.single-sidebar .popular-post li:hover .img-holder .overlay-style-one .box .content a{
   filter: alpha(opacity=100);
   transform: scale(1);
   transition-delay: 0.1s; 
   opacity: 1;
}
.single-sidebar .popular-post li .img-holder .overlay-style-one .box .content a span:before{
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    transition: all 500ms ease;
}
.single-sidebar .popular-post li .img-holder .overlay-style-one .box .content a:hover span:before{
    color: #e9b531;
}
.single-sidebar .popular-post li:hover .img-holder .overlay-style-one{
    opacity: 1;
}

.single-sidebar .popular-post li .title-holder {
    padding-left: 20px;
}
.single-sidebar .popular-post li .title-holder span {
    display: block;
    line-height: 20px;
}
.single-sidebar .popular-post li .title-holder .post-title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin: 8px 0 0;
    transition: all 500ms ease;
}
.single-sidebar .popular-post li .title-holder .post-title a{
    color: #d16111;
    transition: all 500ms ease;
}
.single-sidebar .popular-post li .title-holder .post-title a:hover{
    color: #e9b531;
}

.single-sidebar.instagram-box{
    position: relative;
    display: block;
    padding-top: 60px;
    padding-bottom: 53px;
}
.single-sidebar .instagram {
    position: relative;
    display: block;
    overflow: hidden;
    margin-left: -10px;
    margin-right: -10px;
}
.single-sidebar .instagram li {
    position: relative;
    display: inline-block;
    float: left;
    margin: 0 10px 20px;
    transition: all 0.5s ease 0s;
}
.single-sidebar .instagram li .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
    transition: all 0.5s ease 0s;
    z-index: 5;
}
.single-sidebar .instagram li .img-holder img {
    transform: scale(1.1, 1.1);
    transition: all 0.5s ease 0s;
    width: 100%;
}
.single-sidebar .instagram li:hover .img-holder img {
    transform: scale(1, 1);
}
.single-sidebar .instagram li:hover .img-holder .overlay-style-one {
    filter: alpha(opacity=100);
    opacity: 1;
    transition-delay: 0s;
}
.single-sidebar .instagram li .img-holder .overlay-style-one .box .content a i{
    color: #fff;
    font-size: 18px;
    filter: alpha(opacity=0);
    opacity: 0;
    transform: scale(3);
    transition: all 0.3s ease-in-out 0.3s;
    z-index: 5;
}
.single-sidebar .instagram li:hover .img-holder .overlay-style-one .box .content a i{
   filter: alpha(opacity=100);
   opacity: 1;
   transform: scale(1);
   transition-delay: 0.1s;    
}
.single-sidebar .instagram li .img-holder .overlay-style-one .box .content a:hover i{
    color: #e9b531;    
}
.follow-us-button {
    position: relative;
    display: block;
    color: #409e35;
    font-size: 16px;
    font-weight: 500;
    padding: 4px 0 0;
    transition: all 500ms ease;
}
.follow-us-button span:before {
    position: relative;
    display: inline-block;
    padding-right: 8px;
    font-size: 14px;
}
.follow-us-button:hover{
    color: #e9b531;
}


.single-sidebar .popular-tag {
    position: relative;
    display: block;
    margin-left: -5px;
    margin-right: -5px;
}
.single-sidebar .popular-tag li {
    display: inline-block;
    float: left;
    margin: 0 5px 10px;
}
.single-sidebar .popular-tag li a {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 8px 20px 6px;
    color: #7e8597;
    font-size: 16px;
    font-weight: 400;
    transition: all 500ms ease 0s;
    -webkit-box-shadow: 0px 2px 5px 2px #e2e6e8; 
    box-shadow: 0px 2px 5px 2px #e2e6e8;
    z-index: 1;
}
.single-sidebar .popular-tag li a:hover{
    color: #ffffff;
}
.single-sidebar .popular-tag li a:before{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0;
    width: 0px;
    background: #e9b531;
    content: "";
    z-index: -1;
    opacity: 0;
    transition: all 700ms ease 100ms;
    transform: translateX(0%) translateY(0%) scaleY(0.1);    
}
.single-sidebar .popular-tag li:hover a:before{
    opacity: 1;
    transform: translateX(0%) translateY(0%) scaleY(1.0); 
    width: 100%;
    transition: all 700ms ease 100ms;
} 


.single-sidebar.subscribe-form-box{
    position: relative;
    display: block;  
    margin-top: 50px;
}
.sidebar-subscribe-form{
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 370px;
    width: 100%;
    background: #d16111;
    padding: 50px 30px 50px;
    z-index: 1;
}
.sidebar-subscribe-form .icon{
    position: relative;
    display: block;
    padding-bottom: 22px;
}
.sidebar-subscribe-form .icon span:before{
    color: #409e35;
    font-size: 80px;
    line-height: 80px;
}
.sidebar-subscribe-form h3{
    color: #f3f7fa;
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 24px;
}
.sidebar-subscribe-form form {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
}
.sidebar-subscribe-form form .input-field{
    position: relative;
    display: block;
}
.sidebar-subscribe-form form .input-field input[type=email] {
    position: relative;
    display: block;
    background: #e9b531;
    border: 1px solid #e9b531;
    color: #f3f7fa;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    height: 60px;
    padding-left: 20px;
    padding-right: 60px;
    border-radius: 30px;
    transition: all 500ms ease 0s;
}
.sidebar-subscribe-form form .input-field input::-webkit-input-placeholder {
    color: #ffffff;
}
.sidebar-subscribe-form form .input-field input:-moz-placeholder {
    color: #ffffff;
}
.sidebar-subscribe-form form .input-field input::-moz-placeholder {
    color: #ffffff;
}
.sidebar-subscribe-form form .input-field input:-ms-input-placeholder {
    color: #ffffff;
}
.sidebar-subscribe-form form button {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #e9b531;
    color: #ffffff;
    font-size: 14px;
    line-height: 52px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    border: 0px solid #f2f2f2;
    transition: all 500ms ease 0s;
    font-family: 'TrebuchetMS', sans-serif;
}

.sidebar-subscribe-form form .input-field input[type=email]:focus {
    border: 1px solid #f3f7fa;
}
.sidebar-subscribe-form form .input-field input[type=email]:focus + button, 
.sidebar-subscribe-form form button:hover {
    background: #ffffff;
    color: #e9b531;
    transform: rotate(360deg);
}



/*** 
=============================================
    Blog Single Area style
=============================================
***/
#blog-area.blog-single-area {
    padding-top: 0;
    padding-bottom: 100px;
    background: #f3f7fa;
}
.breadcrumb-area.style2::before {
    background: rgba(32, 53, 96, 0.80) none repeat scroll 0 0;
}

.single-post-info {
    position: relative;
    display: block;
    max-width: 970px;
    width: 100%;
    margin: 0 auto;
    padding: 174px 0 375px;
}
.single-post-info p{
    color: #409e35;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0 0 14px;
}
.single-post-info .title{
    position: relative;
    display: block;
    border-bottom: 1px solid #dbe5ec;
    padding-bottom: 19px;
    margin-bottom: 20px;
}
.single-post-info .title .blog-title{
    color: #ffffff;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
}
.single-post-info .meta-box {
    position: relative;
    display: block;
    overflow: hidden;
    min-height: 30px;
    margin-top: 0px;
    line-height: 15px;
}
.single-post-info .meta-box .author-icon {
    position: relative;
    display: inline-block;
    padding-right: 8px;
}
.single-post-info .meta-box .author-icon span:before{
    color: #d3d9e0;
    font-size: 30px;
    line-height: 30px;
}
.single-post-info .meta-box .meta-info {
    position: relative;
    display: inline-block;
    margin: 1px 0;
}
.single-post-info .meta-box .meta-info li {
    position: relative;
    display: inline-block;
    float: left;
    line-height: 20px;
    padding-right: 15px;
    margin-right: 15px;
}
.single-post-info .meta-box .meta-info li:before{
    position: absolute;
    top: 3px;
    right: 0px;
    width: 1px;
    height: 12px;
    background: #d3d9e0;
    content: "";
}
.single-post-info .meta-box .meta-info li:last-child{
    padding-right: 0;
    margin-right: 0;
}
.single-post-info .meta-box .meta-info li:last-child:before{
    display: none;
}
.single-post-info .meta-box .meta-info li a{
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
}
.single-post-info .meta-box .meta-info li a b{
    color: #409e35;
    font-weight: 700;
}
.single-post-info .meta-box .meta-info li a:hover{
    color: #e9b531;
}


.blog-single-area .blog-post {
    position: relative;
    display: block;
}
.blog-single-area .single-blog-post {
    position: relative;
    display: block;
    margin-top: -200px;
    padding: 94px 100px 100px;
    border-radius: 0px;
    background: #ffffff;
    z-index: 99;
}
.single-blog-post .top-text-box {
    position: relative;
    display: block;
    overflow: hidden;
}
.author-quote-box {
    position: relative;
    display: block;
    border-top: 4px solid #edf2f7;
    margin-top: 36px;
    padding-top: 40px;
    margin-bottom: 54px;
}
.author-quote-box .icon{
    display: block;
    padding-bottom: 23px;
}
.author-quote-box .text {
    position: relative;
    display: block;
}
.author-quote-box .text p{
    color: #868298;
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 15px;
}
.author-quote-box .text .name{
    position: relative;
    display: block;
}
.author-quote-box .text .name h3{
    font-size: 20px;
}

.single-blog-post .main-image-holder {
    position: relative;
    display: block;
}

.quality-with-integrity-box {
    position: relative;
    display: block;
    padding-top: 54px;
}
.quality-with-integrity-box h2 {
    font-size: 30px;
    line-height: 38px;
    margin: 0 0 26px;
}
.quality-with-integrity-box .inner-content{
    position: relative;
    display: block;    
}
.quality-with-integrity-box .inner-content h3{
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    margin: 0 0 17px;
}
.quality-with-integrity-box .inner-content p{
    margin: 0;
}
.quality-with-integrity-box .inner-content ul{
    position: relative;
    display: block;
    padding-top: 23px
}
.quality-with-integrity-box .inner-content ul li{
    position: relative;
    display: block;
    padding-left: 40px;
    line-height: 42px;
}
.quality-with-integrity-box .inner-content ul li:before{
    font-family: 'icomoon';
    position: absolute;
    top: 8px;
    left: 0;
    content: "\e935";
    color: #e9b531;
    font-size: 22px;
    line-height: 22px;
}
.quality-with-integrity-box .inner-content ol{
    position: relative;
    display: block;
    padding-left: 40px;
    margin-top: 12px;
}
.quality-with-integrity-box .inner-content ol li{
    position: relative;
    display: block;
    line-height: 38px;
    padding-left: 25px;
}
.quality-with-integrity-box .inner-content ol li:before{
    position: absolute;
    top: 15px;
    left: 0;
    width: 7px;
    height: 7px;
    background: #e9b531;
    border-radius: 50%;
    content: "";
}


.tag-with-social-links-box {
    position: relative;
    display: block;
    overflow: hidden;
}
.tag-with-social-links-box .inner-content{
    position: relative;
    display: block;
    padding-top: 40px;
}
.tag-with-social-links-box .tag-box{
    position: relative;
    display: block;
}
.tag-with-social-links-box .tag-box p {
    position: relative;
    display: inline-block;
    float: left;
    color: #d16111;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    margin: 0;
}
.tag-with-social-links-box .tag-box p i{
    color: #e9b531;
    display: inline-block;
    padding-right: 10px;
}
.tag-with-social-links-box .tag-box ul{
    position: relative;
    display: inline-block;
    padding-left: 20px;
    float: left;
}
.tag-with-social-links-box .tag-box ul li{
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 10px;
}
.tag-with-social-links-box .tag-box ul li:last-child{
    margin-right: 0;
}
.tag-with-social-links-box .tag-box ul li a{
    position: relative;
    display: block;
    padding: 7px 18px 5px;
    border: 1px solid #edf2f7;
    color: #7e8597;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 300ms ease 100ms;
}
.tag-with-social-links-box .tag-box ul li a:hover{
    color: #ffffff;
    border-color: #e9b531;
    background: #e9b531;
}
.tag-with-social-links-box .social-links-box{
    position: relative;
    display: block;
}
.tag-with-social-links-box .social-links-box .sociallinks-style-two{
    display: block;
}
.tag-with-social-links-box .social-links-box .sociallinks-style-two li{
    margin-right: 10px;
}
.tag-with-social-links-box .social-links-box .sociallinks-style-two li:last-child{
    margin-right: 0;
}
.tag-with-social-links-box .social-links-box .sociallinks-style-two li a i {
    position: relative;
    display: block;
    background: #ffffff;
    width: 40px;
    height: 40px;
    border: 1px solid #edf2f7;
    color: #83888d;
    line-height: 38px;
}
.tag-with-social-links-box .social-links-box .sociallinks-style-two li a:hover i{
    color: #ffffff;
    background: #e9b531;
    border-color: #e9b531;
}


.blog-prev-next-option {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-top: 90px;
}
.blog-prev-next-option .single {
    position: relative;
    display: block;
    max-width: 470px;
    width: 100%;
    background: #f3f7fa;
    padding: 34px 0 31px;
} 
.blog-prev-next-option .single.prev {
    position: relative;
    display: block;
    overflow: hidden;
    float: left;
    padding-right: 40px;
    z-index: 1;
}
.blog-prev-next-option .single.prev:before{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 35px;
    content: "";
    background: #ffffff;
    z-index: -1;
}
.blog-prev-next-option .single.prev .image-thumb {
    width: 70px;
    float: left;
    margin-top: 10px;
}
.blog-prev-next-option .single .image-thumb img{
    width: 100%;
    border-radius: 50%;
}
.blog-prev-next-option .single.prev .image-thumb,
.blog-prev-next-option .single.prev .title{
    display: table-cell;
    vertical-align: middle;
}
.blog-prev-next-option .single.prev .title {
    padding-left: 30px;
}
.blog-prev-next-option .single .title h3 {
    color: #d16111;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0 0 10px;
}
.blog-prev-next-option .single .title a{
    color: #83888d;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 500ms ease;
}
.blog-prev-next-option .single .title a:hover{
    color: #409e35;
}
.blog-prev-next-option .single .title a span:before {
    position: relative;
    display: inline-block;
    padding-left: 9px;
    font-size: 20px;
    line-height: 14px;
    top: 4px;
}

.blog-prev-next-option .single.next{
    position: relative;
    display: block;
    overflow: hidden;
    float: right;
    padding-left: 40px;
    z-index: 1;
}
.blog-prev-next-option .single.next:before{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 35px;
    content: "";
    background: #ffffff;
    z-index: -1;    
}
.blog-prev-next-option .single.next .image-thumb{
    width: 70px;
    float: right;
    margin-top: 10px;
}
.blog-prev-next-option .single.next .image-thumb img{
    width: 100%;
    border-radius: 50%;    
}
.blog-prev-next-option .single.next .image-thumb,
.blog-prev-next-option .single.next .title{
    display: table-cell;
    vertical-align: middle;
}
.blog-prev-next-option .single.next .title {
    padding-right: 30px;
    text-align: right;
}


.author-box-holder {
    position: relative;
    display: block;
    overflow: hidden;
    background: #ffffff;
    padding: 45px 50px 50px;
    margin: 60px 0 80px;
    -webkit-box-shadow: 0px 4px 10px 3px #e0e4e6; 
    box-shadow: 0px 4px 10px 3px #e0e4e6;
}
.author-box-holder .inner-box{
    position: relative;
    display: block;
}
.author-box-holder .inner-box .img-box{
    width: 100px;
}
.author-box-holder .inner-box .img-box img {
    border-radius: 50%;
    border: 1px solid #f3f7fa;
}
.author-box-holder .inner-box .img-box,
.author-box-holder .inner-box .text{
    display: table-cell;
    vertical-align: middle;
}
.author-box-holder .inner-box .text{
    padding-left: 40px;    
}
.author-box-holder .inner-box .text h3{
    color: #d16111;
    margin: 0 0 3px;
}
.author-box-holder .inner-box .text span{
    color: #409e35;
}
.author-box-holder .inner-box .text p{
    color: #868298;
    line-height: 26px;
    margin: 16px 0 22px;
}
.author-box-holder .inner-box .text .author-social-links{
    display: block;
    overflow: hidden;
}
.author-box-holder .inner-box .text .author-social-links ul{
    overflow: hidden;
}
.author-box-holder .inner-box .text .author-social-links ul li a i{
    color: #83888d;
    border-color: #edf2f7;
    background: #ffffff;
}
.author-box-holder .inner-box .text .author-social-links ul li a:hover i{
    color: #409e35;
    background: #ffffff;
    border-color: #409e35;    
}

.single-blog-title-box{
    position: relative;
    display: block;
    padding-bottom: 32px;
    margin-top: -5px;
}
.single-blog-title-box h2{
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
}
.blog-single-area .inner-comment-box {
    position: relative;
    display: block;
    overflow: hidden;
}
.blog-single-area .inner-comment-box .single-comment-outer-box{
    position: relative;
    display: block;
    background: #ffffff;
    padding: 35px 50px 32px;
    padding-right: 100px;
    margin-bottom: 50px;
}
.blog-single-area .inner-comment-box .single-comment-outer-box.mar-left{
    margin-left: 200px;
}
.blog-single-area .inner-comment-box .single-comment-box {
    position: relative;
    display: block;
    padding-left: 80px;
}
.blog-single-area .inner-comment-box .single-comment-box .img-box {
    position: absolute;
    top: 5px;
    left: 0;
    width: 80px;
    height: 80px;
}
.blog-single-area .inner-comment-box .single-comment-box .img-box img{
    border-radius: 50%;
    border: 1px solid #f3f7fa;
}

.blog-single-area .inner-comment-box .single-comment-box .text-box {
    position: relative;
    display: block;
    margin-left: 30px;
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .top {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 16px;
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .top .name{
    position: relative;
    display: block;
    float: left;
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .top .name h3{
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 3px;
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .top .name span {
    color: #409e35;
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .top .reply-button{
    position: relative;
    top: -3px;
    display: block;
    float: right;    
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .top .reply-button a{
    color: #d3d9e0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .top .reply-button a:hover{
    color: #409e35;
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .top .reply-button a span:before{
    position: relative;
    top: 1px;
    display: inline-block;
    padding-right: 10px;
    font-size: 14px;
    line-height: 14px;
}
.blog-single-area .inner-comment-box .single-comment-box .text-box .text{}
.blog-single-area .inner-comment-box .single-comment-box .text-box .text p{
    margin: 0;
}


.add-comment-box {
    position: relative;
    display: block;
    overflow: hidden;
    padding-top: 30px;
}
.add-comment-box #add-comment-form {}
.add-comment-box #add-comment-form input[type="text"],
.add-comment-box #add-comment-form input[type="email"],
.add-comment-box #add-comment-form textarea{
    position: relative;
    display: block;
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ffffff;
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    height: 60px;
    padding: 0 30px;
    width: 100%;  
    border-radius: 5px;
    margin-bottom: 30px;
    transition: all 500ms ease;
}
.add-comment-box #add-comment-form textarea {
    height: 170px;
    padding: 13px 30px;
    margin-bottom: 30px;
}
.add-comment-box #add-comment-form input[type="text"]:focus{
    border-color: #e9b531;    
}
.add-comment-box #add-comment-form input[type="email"]:focus{
    border-color: #e9b531;    
}
.add-comment-box #add-comment-form textarea:focus{
    border-color: #e9b531;       
}
.add-comment-box #add-comment-form button {
    color: #ffffff;
    background: #e9b531;
}
.add-comment-box #add-comment-form button:hover{
    background: #2d8109;
}




.single-blog-style-one-content .line {
    width: 70px;
    height: 3px;
    background: #01B9E6;
    display: block;
}

.single-blog-style-one-content .meta-info {
    margin-top: 35px;
}

.single-blog-style-one-content .meta-info a {
    color: #83888D;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    transition: all .4s ease;
}

.single-blog-style-one-content .meta-info a:hover {
    color: #01B9E6;
}

.single-blog-style-one-content .meta-info a+a {
    padding-left: 15px;
    margin-left: 8px;
}

.single-blog-style-one-content .meta-info a+a:before {
    content: '';
    width: 1px;
    height: 12px;
    background: #d3d9e0;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.single-blog-style-one-content .meta-info a i {
    color: #FFBA00;
    margin-right: 4px;
}

.single-blog-style-one-content h3 {
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    margin-top: 14px;
    margin-bottom: 14px;
}

.single-blog-style-one-content h3 a {
    color: #2e2e2e;
    transition: all .4s ease;
}

.single-blog-style-one-content h3 a:hover {
    color: #01B9E6;
}

.single-blog-style-one-content p {
    color: #83888D;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    margin-bottom: 30px;
}

.single-blog-style-one-content .author-box .image-box,
.single-blog-style-one-content .author-box .text-block {
    display: table-cell;
    vertical-align: middle;
}

.single-blog-style-one-content .author-box .image-box {
    width: 60px;
    border-radius: 50%;
    overflow: hidden;
}

.single-blog-style-one-content .author-box .image-box>img {
    width: 100%;
}

.single-blog-style-one-content .author-box .text-block {
    padding-left: 20px;
}

.single-blog-style-one-content .author-box .text-block a {
    color: #2E2E2E;
    font-weight: 700;
    font-size: 16px;
    transition: all .4s ease;
}

.single-blog-style-one-content .author-box .text-block a:hover {
    color: #FFBA00;
}

.single-blog-style-one-image-box {
    position: relative;
    overflow: hidden;
    -webkit-perspective: 400px;
    perspective: 400px;
}

.single-blog-style-one-image-box>img {
    width: 100%;
    transition: all .4s ease;
}

.single-blog-style-one-image-box .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgba(2, 31, 61, 0.9);
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    transition: all .4s ease;
}

.single-blog-style-one-image-box .box a {
    color: #FFBA00;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 21px;
    display: inline-block;
    vertical-align: middle;
}

.single-blog-style-one-image-box .box a i {
    font-size: 21px;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 6px;
}

.single-blog-style-one-image-box:hover .box {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.single-blog-style-one-image-box:hover>img {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
}

.blog-carousel-one .owl-nav {
    margin: 0;
    position: absolute;
    bottom: calc(100% + 50px);
    right: 0;
}

.blog-carousel-one .owl-nav [class*='owl-'] {
    border-radius: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: #83888D;
    font-size: 23px;
    width: 60px;
    height: 60px;
    display: inline-block;
    text-align: center;
    border: 2px solid #F2F2F2;
    border-radius: 50%;
    line-height: 56px;
    position: relative;
    transition: all .4s ease;
}

.blog-carousel-one .owl-nav [class*='owl-']+[class*='owl-'] {
    margin-left: 10px;
}

.blog-carousel-one .owl-nav [class*='owl-'] i {
    transition: all .4s ease;
}

.blog-carousel-one .owl-nav [class*='owl-'] span:after {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    content: attr(data-text);
    color: #2e2e2e;
    font-size: 16px;
    font-weight: bold;
    opacity: 0;
    transition: all .4s ease;
}

.blog-carousel-one .owl-nav [class*='owl-']:hover {
    background-color: transparent;
    color: #83888D;
    border-color: #01B9E6;
}

.blog-carousel-one .owl-nav [class*='owl-']:hover i {
    opacity: 0;
}

.blog-carousel-one .owl-nav [class*='owl-']:hover span:after {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}

.home-three .single-blog-post {
    margin-bottom: 30px;
}

.home-three .single-blog-post .overlay-style-two:before,
.home-three .single-blog-post .overlay-style-two::after {
    background-color: rgba(25, 37, 33, 0.95);
}

.home-three .single-blog-post .post-date {
    top: 0;
    right: 0;
    left: auto;
    background: #F9A03B;
}

.home-three .single-blog-post .meta-box {
    margin: 0;
    margin-bottom: 10px;
}

.home-three .single-blog-post .meta-box .meta-info li a b {
    color: #28B381;
}

.home-three .single-blog-post .meta-box .meta-info li a {
    color: #83888D;
}

.home-three .single-blog-post .meta-box .meta-info li a:hover {
    color: #F9A03B;
}

.home-three .single-blog-post .text-holder {
    margin-top: 25px;
    padding: 0;
}

.home-three .single-blog-post .text-holder .blog-title a {
    color: #3A3A3A;
}

.home-three .single-blog-post .text-holder .blog-title a:hover {
    color: #F9A03B;
}

.home-four .single-blog-post {
    margin-bottom: 30px;
}

.home-four .single-blog-post .image-holder-outer {
    border-radius: 5px;
    overflow: hidden;
}

.home-four .single-blog-post .img-holder .box-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 20;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all .3s ease .2s;
}

.home-four .single-blog-post .img-holder .box-link a {
    color: #fff;
    font-size: 23px;
}

.home-four .single-blog-post .img-holder .overlay-style-two:before,
.home-four .single-blog-post .img-holder .overlay-style-two:after {
    background: rgba(6, 31, 46, 0.9);
}

.home-four .single-blog-post .text-holder .blog-title a {
    color: #3A3A3A;
}

.home-four .single-blog-post .text-holder .blog-title a:hover {
    color: #91C942;
}

.home-four .single-blog-post .meta-box .meta-info li a b {
    color: #34964D;
}

.home-four .single-blog-post .btn-two {
    color: #3A3A3A;
}

.home-four .single-blog-post .btn-two:hover {
    color: #91C942;
}

.home-four .single-blog-post:hover .img-holder .box-link {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.home-five .single-blog-style-one-image-box .box {
    background-color: rgba(0, 0, 0, 0.8);
}

.home-five .single-blog-style-one-content .meta-info a i,
.home-five .single-blog-style-one-content .line {
    background-color: #F9C807;
}

.home-five .blog-carousel-one .owl-nav [class*='owl-']:hover {
    background-color: transparent;
    color: #83888D;
    border-color: #F9C807;
}

.home-five .single-blog-style-one-content .meta-info a:hover {
    color: #2e2e2e;
}

.home-five .single-blog-style-one-content h3 a:hover {
    color: #F9C807;
}



/*** 
=============================================
    Main Project Area style
=============================================
***/
.main-project-area{
    position: relative;
    display: block;
    background: #ffffff;
    padding: 100px 0 100px;
}
.project-filter.style1 {
    position: relative;
    display: block;
    text-align: center;
    border-top: 1px solid #edf2f7;
    border-bottom: 1px solid #edf2f7;
    margin-bottom: 65px;
}
.project-filter.style1 li {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.project-filter.style1 li .filter-text {
    color: #83888d;
    font-size: 16px;
    font-weight: 700;
    display: block;
    padding: 27px 30px 25px;
    padding-right: 26px;
    cursor: pointer;
    transition: all .4s ease;
}
.project-filter.style1 li:hover .filter-text, 
.project-filter.style1 li.active .filter-text {
    color: #409e35;
}
.project-filter.style1 li .count {
    color: #ffffff;
    font-size: 12px;
    line-height: 26px;
    font-weight: 500;
    background: #409e35;
    border-radius: 3px;
    display: inline-block;
    padding: 0px 14px;
    position: absolute;
    bottom: 0;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
.project-filter.style1 li .count:before {
    position: absolute;
    left: 50%;
    bottom: 100%;
    content: '';
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #409e35;
}
.project-filter.style1 li:hover .count, 
.project-filter.style1 li.active .count {
    opacity: 1;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}
.project-filter.style1 li + li {
    border-left: 1px solid #edf2f7;
}


.single-project-style2 {
    position: relative;
    display: block;
    min-height: 400px;
    margin-bottom: 50px;
}
.single-project-style2 .img-holder{
    position: relative;
    display: block; 
    z-index: 3;
}
.single-project-style2 .img-holder .inner{
    position: relative;
    display: block;   
    overflow: hidden;
}
.single-project-style2 .img-holder .inner img{
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-project-style2:hover .img-holder .inner img {
    transform:scale(1.2) rotate(2deg);	
}

.single-project-style2 .img-holder .overlay-content{
    position: absolute;
    left: 0;
    bottom: -170px;
    width: 100%;
    background: #ffffff;
    padding: 24px 40px 22px;
    transition: all 700ms ease 100ms;
}
.single-project-style2:hover .img-holder .overlay-content{
    width: 100%; 
    -webkit-box-shadow: 0px 3px 7px 2px #eeeeee; 
    box-shadow: 0px 3px 7px 2px #eeeeee;
}
.single-project-style2 .img-holder .overlay-content h3{
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 6px;
}
.single-project-style2 .img-holder .overlay-content h3 a{
    color: #d16111;
}
.single-project-style2 .img-holder .overlay-content span{
    color: #83888d;
    font-weight: 500;
}

.single-project-style2 .img-holder .overlay-content .button{
    position: absolute;
    display: block;
    top: -40px;
    right: 40px;
}
.single-project-style2 .img-holder .overlay-content .button a {
    position: relative;
    display: block;
    width: 55px;
    height: 55px;
    background: #f3f7fa;
    border-radius: 50%;
    margin: 4px 0;
    transform: perspective(1200px) rotateX(90deg) translateX(95px);
    transition: all 700ms ease 10ms;
    opacity: 0;
}
.single-project-style2:hover .img-holder .overlay-content .button a{
    transform: perspective(1200px) rotateX(0deg) translateX(0px); 
    transition: all 300ms ease 200ms;
    opacity: 1;
}
.single-project-style2 .img-holder .overlay-content .button a span:before {
    display: block;
    color: #d16111;
    font-size: 20px;
    line-height: 14px;
    text-align: center;
    padding: 20px 0 20px;
}



/*** 
=============================================
    Main Project Area V2 style
=============================================
***/
.main-project-area.v2{}
.single-project-style1 .img-holder .categories.bg2 {
    background: #409e35;
}
.single-project-style1.marbtm40{
    margin-bottom: 40px;
}



/*** 
=============================================
    Main Project Area V3 style
=============================================
***/
.main-project-area.v3{}

.single-project-style3{
    position: relative;
    display: block;
}
.single-project-style3.marbtm30{
    margin-bottom: 30px;
}
.single-project-style3 .img-holder{
    position: relative;
    display: block;
    overflow: hidden;
}
.single-project-style3 .img-holder img{
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-project-style3:hover .img-holder img {
    transform:scale(1.2) rotate(2deg);	
}
.single-project-style3 .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(233, 181, 49, 0.90);
    transform: scaleX(0);
    transition: all 700ms ease 100ms;
}
.single-project-style3:hover .overlay-content{
    transform: scaleY(1);
}
.single-project-style3 .overlay-content .inner-content{
    display: table;
    width: 100%;
    height: 100%;
}
.single-project-style3 .overlay-content .inner-content .title-box{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    transform: scale(0);
    transition: all 700ms ease 500ms;
}
.single-project-style3:hover .overlay-content .inner-content .title-box{
    transform: scale(1.0);    
}
.single-project-style3 .overlay-content .inner-content .title-box h3{
    margin: 0 0 8px;
}
.single-project-style3 .overlay-content .inner-content .title-box h3 a{
    color: #ffffff;
    transition: all 500ms ease;
}
.single-project-style3 .overlay-content .inner-content .title-box h3 a:hover{
    color: #e9b531;
}
.single-project-style3 .overlay-content .inner-content .title-box span{
    color: #409e35;
    font-weight: 500;
}
.main-project-area.v3 .post-pagination{
    margin-top: 20px;
}



/*** 
=============================================
    Main Project Area V4 style
=============================================
***/
.main-project-area.v4 {
    position: relative;
    display: block;
    width: 100%;
    padding: 100px 15px 100px;
}
.single-project-style4{}
.single-project-style4{
    position: relative;
    display: block;
}
.single-project-style4.marbtm30{
    margin-bottom: 30px;
}

.single-project-style4 .img-holder{
    position: relative;
    display: block;
    overflow: hidden;
}
.single-project-style4 .img-holder img{
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-project-style4:hover .img-holder img {
    transform:scale(1.2) rotate(2deg);	
}
.single-project-style4 .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(233, 181, 49, 0.90);
    transform: scaleX(0);
    transition: all 700ms ease 100ms;
}
.single-project-style4:hover .overlay-content{
    transform: scaleY(1);
}
.single-project-style4 .overlay-content .inner-content{
    display: table;
    width: 100%;
    height: 100%;
}
.single-project-style4 .overlay-content .inner-content .links-icons{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    transform: scale(0);
    transition: all 700ms ease 500ms;
}
.single-project-style4:hover .overlay-content .inner-content .links-icons{
    transform: scale(1.0);    
}
.single-project-style4 .overlay-content .inner-content .links-icons li{
    display: inline-block;
    margin: 0 5.5px;
}
.single-project-style4 .overlay-content .inner-content .links-icons li a span:before{
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    background: #409e35;
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    line-height: 60px;
    font-weight: 100;
    transition: all 500ms ease;
}
.single-project-style4 .overlay-content .inner-content .links-icons li a:hover span:before{
    background: #e9b531;    
}
.main-project-area.v4 .post-pagination{
    margin-top: 20px;
}



/*** 
=============================================
    Main Project Area V5 style
=============================================
***/
.main-project-area.v5 {
    position: relative;
    display: block;
}
.main-project-area.v5 .post-pagination{
    margin-top: 20px;
}



/*** 
=============================================
    Project single Area style
=============================================
***/
.project-single-area {
    position: relative;
    display: block;
    padding: 100px 0 56px;
}

.single-project-image-box{
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 40px;
}
.single-project-image-box img{
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-project-image-box:hover img {
    transform:scale(1.2) rotate(2deg);	
}

.single-project-content-box{
    position: relative;
    display: block; 
    margin-top: 30px;
}
.single-project-content-box h2{
    font-size: 30px;
    line-height: 38px;
    margin: -7px 0 35px;
}
.single-project-content-box .text{
    position: relative;
    display: block;        
}


.project-info {
    position: relative;
    display: block;
    margin-top: 30px;
    background: #f3f7fa;
    padding: 20px 40px 19px;
}
.project-info ul{
    position: relative;
    display: block;
    overflow: hidden;
}
.project-info ul li{
    line-height: 53px;
    border-bottom: 1px solid #dbe5ec;
}
.project-info ul li:last-child{
    border: none;
}
.project-info ul li span {
    position: relative;
    display: inline-block;
    float: left;
    color: #409e35;
    font-weight: 700;
}
.project-info ul li b {
    position: relative;
    display: inline-block;
    float: left;
    color: #d16111;
    font-weight: 500;
    width: 25px;
    text-align: center;
}



.project-filter {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 65px;
}

.project-filter li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.project-filter li .filter-text {
    font-size: 16px;
    font-weight: bold;
    color: #83888D;
    display: block;
    padding: 27px 30px;
    cursor: pointer;
    transition: all .4s ease;
}

.project-filter li .count {
    font-size: 12px;
    font-weight: 500;
    background: #01B9E6;
    color: #fff;
    line-height: 26px;
    border-radius: 3px;
    display: inline-block;
    padding: 0px 14px;
    position: absolute;
    bottom: 0;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    transition: all .4s ease;
}

.project-filter li .count:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #01B9E6;
}

.project-filter li:hover .filter-text,
.project-filter li.active .filter-text {
    color: #01B9E6;
}

.project-filter li:hover .count,
.project-filter li.active .count {
    opacity: 1;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.project-filter li+li {
    border-left: 1px solid #f2f2f2;
}

.project-style-one {
    margin-bottom: 30px;
    position: relative;
    display: block;
    z-index: 6;
}

.project-style-one:before {
    position: absolute;
    left: 30px;
    bottom: 0;
    right: 30px;
    height: 10px;
    content: "";
    box-shadow: 0px 3px 6px 1px #ececec;
    transition: all 300ms ease 100ms;
    opacity: 0;
}

.project-style-one .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 5;
}

.project-style-one .img-holder img {
    width: 100%;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    transition: all 700ms ease;
}

.project-style-one .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(2, 31, 61, 0.9);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: all 700ms ease 100ms;
}

.project-style-one .overlay-content .inner-content {
    display: table;
    width: 100%;
    height: 100%;
}

.project-style-one .overlay-content .inner-content .text-box {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 700ms ease 500ms;
}

.project-style-one .overlay-content .inner-content .text-box h3,
.project-style-one .overlay-content .inner-content .text-box p {
    margin: 0;
}

.project-style-one .overlay-content .inner-content .text-box h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.project-style-one .overlay-content .inner-content .text-box h3 a {
    color: #fff;
    transition: all .4s ease;
}

.project-style-one .overlay-content .inner-content .text-box p {
    color: #FFBA00;
    font-weight: 500;
    font-size: 16px;
}

.project-style-one .overlay-content .inner-content .text-box .link-btn {
    display: inline-block;
    vertical-align: middle;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #F9A03B;
    line-height: 55px;
    color: #fff;
    font-size: 20px;
    transition: all .4s ease;
}

.project-style-one .overlay-content .inner-content .text-box .link-btn:hover {
    background: #fff;
    color: #F9A03B;
}

.project-style-one:hover:before {
    opacity: 1;
}

.project-style-one:hover .img-holder img {
    -webkit-transform: scale(1.2) rotate(2deg);
    transform: scale(1.2) rotate(2deg);
}

.project-style-one:hover .overlay-content {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.project-style-one:hover .overlay-content .inner-content .text-box {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.home-three .project-style-one {
    border-radius: 5px;
    overflow: hidden;
}

.home-three .project-style-one .overlay-content {
    background: rgba(25, 37, 33, 0.95);
}

.home-three .more-btn {
    margin-top: 10px;
}

.home-four .project-filter li .count {
    background: #34964d;
}

.home-four .project-filter li .count:before {
    border-bottom-color: #34964d;
}

.home-four .project-filter li:hover .filter-text,
.home-four .project-filter li.active .filter-text {
    color: #34964d;
}

.home-four .project-style-one {
    border-radius: 5px;
    overflow: hidden;
}

.home-four .project-style-one .overlay-content {
    background: rgba(6, 31, 46, 0.95);
}

.home-four .project-style-one .overlay-content .inner-content .text-box .link-btn {
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #185F8B 0%, #34964D 42%, #91C942 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#185F8B', endColorstr='#91C942', GradientType=1);
    /* ie6-9 */
    color: #fff;
}

.home-four .more-btn {
    margin-top: 10px;
}

.single-project-style-two {
    position: relative;
    overflow: hidden;
}

.single-project-style-two .image-block {
    position: relative;
}

.single-project-style-two .image-block:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(6deg, black 0%, rgba(0, 0, 0, 0) 27%, rgba(0, 0, 0, 0) 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
    /* ie6-9 */
}

.single-project-style-two .image-block>img {
    width: 100%;
}

.single-project-style-two .text-block {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.single-project-style-two .text-block h3 {
    font-size: 30px;
    font-weight: 400;
}

.single-project-style-two .text-block h3 a {
    color: #FFFFFF;
    transition: all .4s ease;
}

.single-project-style-two .text-block p {
    color: #878787;
    font-size: 18px;
    margin: 0;
}

.single-project-style-two .text-block a.more-link {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    transition: all .4s ease;
}

.single-project-style-two .text-block a.more-link i {
    font-size: 20px;
    display: inline-block;
    vertical-align: -3px;
    margin-left: 7px;
}

.single-project-style-two .text-block a.more-link:hover {
    color: #F9C807;
}

.single-project-style-two .main-content {
    -webkit-transform: translateY(240%);
    transform: translateY(240%);
    transition: all .4s ease;
}

.single-project-style-two .hover-content {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background: #111012;
    padding: 37px 50px;
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: all .4s ease;
}

.single-project-style-two:hover .text-block .hover-content {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

.single-project-style-two:hover .text-block .main-content {
    opacity: 0;
    -webkit-transform: translateY(480%);
    transform: translateY(480%);
}

.project-carousel-two .owl-nav {
    margin: 0;
}

.project-carousel-two .owl-nav [class*="owl-"] {
    margin: 0;
    padding: 0;
    color: #2e2e2e;
    font-size: 30px;
    line-height: 55px;
    border-radius: 0;
    width: 70px;
    height: 55px;
    background: #fff;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .4s ease;
}

.project-carousel-two .owl-nav [class*="owl-"]:hover {
    background: #F9C807;
}

.project-carousel-two .owl-nav [class*="owl-prev"] {
    left: 0;
    border-top-right-radius: 27.5px;
    border-bottom-right-radius: 27.5px;
}

.project-carousel-two .owl-nav [class*="owl-next"] {
    right: 0;
    border-top-left-radius: 27.5px;
    border-bottom-left-radius: 27.5px;
}




/*** 
=============================================
    Challenge Solution Area  style
=============================================
***/
.challenge-solution-area{
    position: relative;
    display: block;
    padding-bottom: 60px;
}
.challenge-solution-box{
    position: relative;
    display: block;  
    padding: 33px 40px 27px;
    border: 1px solid #edf2f7;
    margin-bottom: 40px;
}
.challenge-solution-box h3{
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 16px;
}
.challenge-solution-box h4{
    font-size: 18px;
    line-height: 26px;
}
.challenge-solution-box ul{
    position: relative;
    display: block;
    padding-top: 12px;
}
.challenge-solution-box ul li{
    position: relative;
    display: block;
    padding-left: 30px;
    line-height: 36px;
}
.challenge-solution-box ul li:before{
    position: absolute;
    top: 10px;
    left: 0;
    font-family: FontAwesome;
    content: "\f00c";
    color: #e9b531;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
}



/*** 
=============================================
   Shop area  style
=============================================
***/
#shop-area {
    padding-top: 100px;
    padding-bottom: 100px;
}
.main-shop-area{
    position: relative;
    display: block;
    background: #ffffff;
}
#shop-area .shop-content {
    position: relative;
    display: block;
}
.showing-result-shorting {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #edf2f7;
    margin-bottom: 50px;
}
.showing-result-shorting .showing{}
.showing-result-shorting .shorting{ }
.showing-result-shorting .showing p {
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.single-product-item {
    position: relative;
    display: block;
    border: 1px solid #edf2f7;
    transition: all 500ms ease;
    margin-bottom: 30px;
}
.single-product-item:hover{
    -webkit-box-shadow: 0px 3px 8px 1px #f2f2f2; 
    box-shadow: 0px 3px 8px 1px #f2f2f2;
} 
.single-product-item .img-holder{
    position: relative;
    display: block;
    overflow: hidden;
}
.single-product-item .img-holder::before {
    background: rgba(255, 255, 255, 0.30);    
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    content: "";
    opacity: 0;
    position: absolute;
    z-index: 1;
    transition: all 0.4s ease-in-out 0s;
}
.single-product-item:hover .img-holder::before{
    opacity: 1;
}
.single-product-item .img-holder img{
    transform: scale(1);
    transition: all 0.7s ease 0s;
    width: 100%;   
}
.single-product-item:hover .img-holder img{
    transform: scale(1.1);   
}


.single-product-item .title-holder {
    position: relative;
    display: block;
    overflow: hidden;
    border-top: 1px solid #edf2f7;
    padding: 0 39px;
}
.single-product-item .title-holder .static-content{
    position: relative;
    display: block;    
}
.single-product-item .title-holder .title {
    position: relative;
    display: block;
    border-bottom: 1px solid #edf2f7;
    padding: 19px 0 14px;
}
.single-product-item .title-holder .title a{
    color: #d16111;
    transition: all 500ms ease;
}
.single-product-item .title-holder .rate-review-box {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 17px 0 21px;
}
.single-product-item .title-holder .rate-box{
    position: relative;
    display: block;
}
.single-product-item .title-holder .rate-box p{
    color: #409e35;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
}
.single-product-item .title-holder .rate-box p del{
    color: #b8babc;    
}
.single-product-item .title-holder .review-box{
    position: relative;
    display: block;
}
.single-product-item .title-holder .review-box ul{
    line-height: 20px;
}
.single-product-item .title-holder .review-box ul li i{
    color: #e9b531;
}

.single-product-item .title-holder .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    text-align: center;
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transition: all 700ms ease 300ms;
}
.single-product-item:hover .title-holder .overlay-content {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transition: all 700ms ease 100ms;
}
.single-product-item .title-holder .overlay-content ul {
    position: relative;
    display: block;
    padding: 33px 0 34px;
}
.single-product-item .title-holder .overlay-content ul li{
    display: inline-block;
    margin: 0 3px;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 700ms ease 100ms;
}
.single-product-item:hover .title-holder .overlay-content ul li {
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: all 700ms ease 300ms;
}
.single-product-item .title-holder .overlay-content ul li a span:before{
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    background: #f3f7fa;
    border-radius: 50%;
    color: #83888d;
    font-size: 18px;
    line-height: 50px;
    transition: all 500ms ease;
}
.single-product-item .title-holder .overlay-content ul li a:hover span:before{
    color: #ffffff;
    background: #e9b531;
}
.post-pagination.mainshop {
    margin-top: 20px;
}



.shop-sidebar-wrapper {
    position: relative;
    display: block;
    border: 1px solid #edf2f7;
    border-bottom: none;
    padding-top: 39px;
}
.shop-sidebar-wrapper .single-sidebar-box {
    position: relative;
    display: block;
    border-bottom: 1px solid #edf2f7;
    padding: 0 49px;
}
.single-sidebar-box.pdbtm{
    padding-bottom: 23px;
}
.shop-sidebar-wrapper .search-form {
    position: relative;
    display: block;
    max-width: 270px;
    width: 100%;
    margin-bottom: 39px;
}
.shop-sidebar-wrapper .search-form input[type="text"] {
    position: relative;
    display: block;
    background: #fff;
    border: 1px solid #edf2f7;
    color: #7e8597;
    font-size: 18px;
    font-weight: 400;
    height: 50px;
    max-width: 270px;
    width: 100%;
    padding-left: 20px;
    padding-right: 70px;
    border-radius: 0px;
    transition: all 500ms ease 0s;
}
.shop-sidebar-wrapper .search-form button {
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 0;
    display: block;
    background: #ffffff;
    color: #7c7b7b;
    font-size: 14px;
    height: 50px;
    width: 60px;
    border: 1px solid #edf2f7;
    border-left: none;
    line-height: 48px;
    text-align: center;
    border-radius: 0px;
    transition: all 500ms ease 0s;
    margin: 0;
}
.shop-sidebar-wrapper .search-form button i {
    font-size: 14px
}
.shop-sidebar-wrapper .search-form input[type="text"]:focus {
    border: 1px solid #e9b531;
    background: #fff;
    color: #000000;
}
.shop-sidebar-wrapper .search-form input[type="text"]:focus + button, 
.shop-sidebar-wrapper .search-form button:hover {
    background: #e9b531;
    border-color: #e9b531;
    color: #fff;
}


.shop-sidebar-title {
    position: relative;
    display: block;
    padding-top: 34px;
}
.shop-sidebar-title h3{
    display: block;
    text-transform: capitalize;
}
.single-sidebar-box .categories {
    position: relative;
    display: block;
    padding: 10px 0 0px;
}
.single-sidebar-box .categories li {
    position: relative;
    display: block;
    padding-left: 20px;
    line-height: 44px;
    border-bottom: 1px solid #edf2f7;
    transition: all 500ms ease;
}
.single-sidebar-box .categories li:last-child{
    border-bottom: none;
}
.single-sidebar-box .categories li:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    height: 7px;
    background: #d3d9e0;
    border-radius: 50%;
    content: "";
    margin: 18px 0;
    transition: all 500ms ease;
    transition-delay: .3s;
    transform: perspective(0px) translateX(0px) rotate(0deg);
}
.single-sidebar-box .categories li:hover:before{
    background: #409e35;
    transform: perspective(0px) translateX(10px) rotate(0deg);    
}
.single-sidebar-box .categories li a {
    position: relative;
    display: block;
    color: #83888d;
    font-size: 18px;
    font-weight: 400;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
    transition-delay: .2s;
    transform: perspective(0px) translateX(0px) rotate(0deg);
}
.single-sidebar-box .categories li:hover a{
    color: #409e35;
    transform: perspective(0px) translateX(10px) rotate(0deg);
}


.shop-sidebar-wrapper .price-ranger {
    margin-top: 28px;
    margin-bottom: 40px;
}
.shop-sidebar-wrapper .price-ranger .ui-widget-content {
    background: #edf2f7;
    border: none;
    height: 2px;
}
.shop-sidebar-wrapper .price-ranger .ui-slider-handle {
    background: #e9b531;
    border: 2px solid #e9b531;
    border-radius: 50%;
    height: 10px;
    margin-left: -4px;
    outline: medium none;
    width: 10px !important;
    cursor: pointer;
    z-index: 2;
}
.shop-sidebar-wrapper .price-ranger .ui-slider-horizontal .ui-slider-handle {
    top: -4px;
}
.shop-sidebar-wrapper .price-ranger .ui-slider .ui-slider-range {
    background: #e9b531;
}
.shop-sidebar-wrapper .price-ranger #slider-range {
    margin-left: 4px;
    margin-right: 6px;
    margin-top: 0;
}
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block {
    position: relative;
    display: block;
    margin-top: 34px;
}
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block input {
    display: inline-block;
}
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block input[type="submit"] {
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 10px;
    padding: 8px 0 6px;
    text-align: center;
    width: 90px;
    border: none;
    color: #d16111;
    margin-top: 0;
    border-radius: 0;
    background: #f3f7fa;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block input[type="submit"]:hover{}
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block input[type="text"] {
    position: relative;
    display: inline-block;
    color: #83888d;
    font-size: 18px;
    font-weight: 400;
    text-align: right;
    width: 40px;
    line-height: 40px;
    border: none;
    padding: 0;
}
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block input[type='text'].max {}
.shop-sidebar-wrapper .price-ranger .ranger-min-max-block span {
    position: relative;
    display: inline-block;
    color: #83888d;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
}


/*** 
=============================================
   Single Shop Area style
=============================================
***/
#shop-area.single-shop-area {
    padding-top: 100px;
    padding-bottom: 70px;
}
.single-product-image-holder {
    margin-right: 35px;
}
.single-product-image-holder img{
    width: auto;
}

.single-shop-content .content-box {
    position: relative;
    display: block;
}
.single-shop-content .content-box span.price {
    color: #409e35;
    font-size: 24px;
    font-weight: 500;
}
.single-shop-content .content-box h2 {
    color: #d16111;
    font-size: 30px;
    font-weight: 700;
    margin: 6px 0 12px;
    text-transform: capitalize;
}
.single-shop-content .content-box .review-box {}
.single-shop-content .content-box .review-box ul {}
.single-shop-content .content-box .review-box ul li {}
.single-shop-content .content-box .review-box ul li i {
    color: #e9b531;
    font-size: 18px;
}
.single-shop-content .content-box .text {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 32px 0 26px;
}
.single-shop-content .content-box .text p{
    margin: 0;
}
.single-shop-content .content-box .location-box {
    margin: 0 0 32px;
}
.single-shop-content .content-box .location-box p {
    color: #83888d;
    font-size: 16px;
    margin: 0 0 12px;
}
.single-shop-content .content-box .location-box form {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 14px;
}
.single-shop-content .content-box .location-box form input {
    position: relative;
    display: inline-block;
    float: left;
    border: 1px solid #f3f7fa;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    width: 180px;
    transition: all 500ms ease;
    margin: 0;
}
.single-shop-content .content-box .location-box form input:focus{
    border-color: #e9b531;
}
.single-shop-content .content-box .location-box form button {
    position: relative;
    display: inline-block;
    float: left;
    background: #f3f7fa;
    border: none;
    color: #d16111;
    font-size: 14px;
    font-weight: 700;
    line-height: 52px;
    height: 50px;
    width: 100px;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    transition: all 500ms ease 0s;
}
.single-shop-content .content-box .location-box form button:hover{
    color: #fff;
    background: #e9b531;
}
.single-shop-content .content-box .location-box span {
    color: #409e35;
}
.single-shop-content .content-box .addto-cart-box {}
.single-shop-content .content-box .addto-cart-box .input-group.bootstrap-touchspin {
    float: left;
    width: 46px;
}
.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .btn {
    position: relative;
    display: block;
    float: none;
    margin-left: 0px;
    width: 100%;
    background: #edf2f7;
    border: 1px solid #edf2f7;
    color: #848484;
    padding: 12px 11px;
    cursor: pointer;
}
.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0
}
.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    border-radius: 0;
    margin-top: -2px;
}
.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .btn.bootstrap-touchspin-up:before {
    content: "\f0d8";
    font-family: FontAwesome;
    color: #848484;
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 5px 5px 5px;
}
.single-shop-content .content-box .addto-cart-box .bootstrap-touchspin .input-group-btn-vertical .btn.bootstrap-touchspin-down:before {
    content: "\f0d7";
    font-family: FontAwesome;
    color: #848484;
    font-size: 14px;
    position: absolute;
    top: -1px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0px 5px 5px;
}
.single-shop-content .content-box .addto-cart-box button.addtocart {
    background: #e9b531;
    height: 50px;
    width: 170px;
    margin-left: 43px;
    color: #fff;
    padding: 0px 0 0px;
    cursor: pointer;
    border-radius: 0;
    line-height: 52px;
}
.single-shop-content .content-box .addto-cart-box button.addtocart:hover{
    background: #2e8508;
}

.share-products-socials {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 38px;
}
.share-products-socials h5{
    position: relative;
    display: inline-block;
    float: left;
    color: #d16111;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
}
.share-products-socials ul {
    position: relative;
    display: inline-block;
    float: left;
    margin-left: 20px;
}
.share-products-socials ul li{
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 10px;
}
.share-products-socials ul li:last-child{
    margin-right: 0;
}
.share-products-socials ul li a i{
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 42px;
    text-align: center;
    transition: all 500ms ease;
}
.share-products-socials ul li a i.fb{
    background: #3b5998;
    border-color: #3b5998;
    color: #ffffff;
}
.share-products-socials ul li a:hover i.fb{
    background: #1d376f;    
}
.share-products-socials ul li a i.tw{
    background: #55acee;
    border-color: #55acee;
    color: #ffffff;    
}
.share-products-socials ul li a:hover i.tw{
    background: #1e7dc5;    
}
.share-products-socials ul li a i.wp{
    background: #15750a;
    border-color: #15750a;
    color: #ffffff;    
}
.share-products-socials ul li a:hover i.wp{
    background: #0c4a04;    
}
.share-products-socials ul li a i.pin{
    background: #cb2027;
    border-color: #cb2027;
    color: #ffffff;       
}
.share-products-socials ul li a:hover i.pin{
    background: #a81218;    
}
.share-products-socials ul li a i.lin{
    background: #007ab9;
    border-color: #007ab9;
    color: #ffffff;      
}
.share-products-socials ul li a:hover i.lin{
    background: #01537d;    
}


.product-tab-box {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 85px;
}
.product-tab-box .tab-btns {
    position: relative;
    display: block;
    text-align: center;
    border-bottom: 1px solid #edf2f7;
    margin-bottom: 45px;
    z-index: 1;
}
.product-tab-box .tab-btns .tab-btn {
    display: inline-block;
    float: none;
    text-align: center;
    margin: 0 2px;
}
.product-tab-box .tab-btns .tab-btn span {
    position: relative;
    display: block;
    background: transparent;
    border-radius: 0;
    cursor: pointer;
    margin: 0;
    padding: 15px 20px 13px;
    color: #d16111;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 500ms ease;
    font-family: 'TrebuchetMS', sans-serif;
}
.product-tab-box .tab-btns .tab-btn.active-btn span, 
.product-tab-box .tab-btns .tab-btn:hover span{
    color: #409e35;
}
.product-tab-box .tab-btns .tab-btn span:before{
    position: absolute;
    left: 0;
    bottom: -1px;
    right: 0;
    height: 1px;
    background: #409e35;
    content: "";
    opacity: 0;
    transition: all 500ms ease;
    transition-delay: .3s;
}
.product-tab-box .tab-btns .tab-btn.active-btn span:before,
.product-tab-box .tab-btns .tab-btn:hover span:before{
    opacity: 1;
}


.product-tab-box .tabs-content {
    position: relative;
    display: block;
    padding: 0;
}
.product-tab-box .tabs-content .tab{
	position: relative;
	padding: 0px;
	display: none;
}
.product-tab-box .tabs-content .tab.active-tab{
	display: block;	
}
.review-box-holder {
    position: relative;
    display: block;
}
.review-box-holder .single-review-box {
    position: relative;
    display: block;
    padding-left: 65px;
    margin-bottom: 40px;
    border-bottom: 1px solid #edf2f7;
    padding-bottom: 32px;
}
.review-box-holder .single-review-box .icon-holder {
    left: 0;
    position: absolute;
    top: 0;
}
.review-box-holder .single-review-box .icon-holder span:before{
    color: #d3d9e0;
    font-size: 65px;
    line-height: 65px;
}

.review-box-holder .single-review-box .text-holder {
    position: relative;
    display: block;
    padding-left: 30px;
    padding-right: 40px;
}
.review-box-holder .single-review-box .text-holder .top {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0 0 23px;
}

.review-box-holder .single-review-box .text-holder .top .name h3 {
    margin: 0 0 7px;
    text-transform: capitalize;
}
.review-box-holder .single-review-box .text-holder .top .name h3 span{
    color: #409e35;
    font-size: 16px;
    font-weight: 400;
}
.review-box-holder .single-review-box .text-holder .top .review-box ul {
    position: relative;
    display: block;
    overflow: hidden;
}
.review-box-holder .single-review-box .text-holder .top .review-box ul li {
    display: inline-block;
    margin-right: 3px;
}
.review-box-holder .single-review-box .text-holder .top .review-box ul li:last-child{
    margin-right: 0;
}
.review-box-holder .single-review-box .text-holder .top .review-box ul li i {
    color: #e9b531;
    font-size: 14px;
}
.review-box-holder .single-review-box .text-holder .text p {
    margin: 0;
}


.product-tab-box .review-form {
    padding-top: 39px;
}
.product-tab-box .review-form .title {
    padding-bottom: 29px;
}
.product-tab-box .review-form .title h3 {
    color: #d16111;
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 11px;
    text-transform: capitalize;
}
.product-tab-box .review-form .title span{
    color: #7e8597;
}
.product-tab-box .review-form .add-rating-box {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
}
.product-tab-box .review-form .add-rating-box .add-rating-title{
    position: relative;
    display: inline-block;
    float: left;    
}
.product-tab-box .review-form .add-rating-box .add-rating-title h4 {
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    text-transform: capitalize;
}
.product-tab-box .review-form .add-rating-box .review-box{
    position: relative;
    display: inline-block;
    float: left; 
    padding-left: 20px;
}
.product-tab-box .review-form .add-rating-box .review-box ul li {
    position: relative;
    display: inline-block;
    float: left;
    line-height: 18px;
    margin-right: 5px;
}
.product-tab-box .review-form .add-rating-box .review-box ul li:last-child{
    margin-right: 0px;
}
.product-tab-box .review-form .add-rating-box .review-box ul li i {
    color: #b3b9c8;
    font-size: 16px;
    line-height: 20px;
    opacity: 1;
    transition: all 500ms ease 0s;
}


.product-tab-box .review-form form input[type="text"], 
.product-tab-box .review-form form input[type="email"], 
.product-tab-box .review-form form textarea {
    position: relative;
    display: block;
    background: #ffffff;
    width: 100%;
    height: 60px;
    border: 1px solid #edf2f7;
    color: #252525;
    font-size: 16px;
    padding: 0 20px;
    margin-bottom: 30px;
    border-radius: 5px;
    transition: all 500ms ease;
}
.product-tab-box .review-form form textarea {
    height: 120px;
    padding: 10px 20px;
    margin-bottom: 40px;
}
.product-tab-box .review-form form button {
    color: #ffffff;
    background: #e9b531; 
    padding: 0 50px 0;
}
.product-tab-box .review-form form button:hover{
    background: #2e8707;
}
.product-tab-box .review-form form input[type="text"]:focus{
    border-color: #e9b531;
}
.product-tab-box .review-form form input[type="email"]:focus{
    border-color: #e9b531;
}
.product-tab-box .review-form form textarea:focus{
    border-color: #e9b531;  
}
.related-product {
  padding-top: 100px;
}
.related-product .title {
    padding-bottom: 37px;
}
.related-product .title h3 {
    color: #d16111;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
}
.related-product .single-product-item {}



/*** 
=============================================
   Cart area  style
=============================================
***/
.cart-area {
    padding-bottom: 100px;
    padding-top: 100px;
}
.cart-area .table-outer {
    overflow-x: auto;
    position: relative;
    width: 100%;
}
.cart-area .cart-table {
    min-width: 1024px;
    width: 100%;
}
.cart-area .cart-table .cart-header {
    position: relative;
    background: #f3f7fa;
    color: #d16111;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    width: 100%;
}
.cart-area .cart-table thead tr th {
    font-weight: 700;
    line-height: 24px;
    min-width: 110px;
    padding: 20px 25px;
}
.cart-area .cart-table thead tr th.prod-column {
    padding-left: 30px;
    text-align: left;
}
.cart-area .cart-table thead tr th.availability {
    padding: 0 40px
}
.cart-area .cart-table tbody tr {
    border-bottom: 1px solid #edf2f7;
}
.cart-area .cart-table tbody tr td {
    color: #d16111;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    min-width: 100px;
    padding: 27.5px 25px;
    font-family: 'TrebuchetMS', sans-serif;
}
.cart-area .cart-table tbody tr .prod-column .column-box {
    min-height: 70px;
    position: relative;
}
.cart-area .cart-table tbody tr .prod-column .column-box .prod-thumb {
    width: 70px;
    border: 1px solid #edf2f7;
}
.cart-area .cart-table tbody tr .prod-column .column-box .prod-thumb a {
    display: block;
    border-radius: 5px;
}
.cart-area .cart-table tbody tr .prod-column .column-box .prod-thumb img {
    display: block;
    max-width: 100%;
    border-radius: 5px;
}
.cart-area .cart-table tbody tr .prod-column .column-box .prod-thumb, 
.cart-area .cart-table tbody tr .prod-column .column-box .title{
    display: table-cell;
    vertical-align: middle;
}
.cart-area .cart-table tbody tr .prod-column .column-box .title{
    padding-left: 20px;
}
.cart-area .cart-table tbody tr .prod-column .column-box .title h3 {
    color: #d16111;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
}

.cart-area .cart-table tbody tr .qty {
    padding-right: 25px;
    width: 120px;
}
.cart-area .cart-table tbody tr .qty .input-group.bootstrap-touchspin {
    float: left;
    width: 46px;
}
.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn {
    position: relative;
    display: block;
    float: none;
    margin-left: 0px;
    width: 100%;
    background: #f3f7fa;
    border: 1px solid #f3f7fa;
    color: #848484;
    padding: 12px 11px;
    cursor: pointer;
}
.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0
}
.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    border-radius: 0;
    margin-top: -2px;
}
.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-up:before {
    content: "\f0d8";
    font-family: FontAwesome;
    color: #848484;
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 5px 5px 5px;
}
.cart-area .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-down:before {
    content: "\f0d7";
    font-family: FontAwesome;
    color: #848484;
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0px 5px 10px;
}
.cart-area .cart-table tbody tr td.unit-price {
    padding-left: 40px;
    padding-right: 20px;
}
.cart-area .cart-table tbody .available-info {
    position: relative;
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 50px;
    font-family: 'TrebuchetMS', sans-serif;
}
.cart-area .cart-table tbody .available-info .icon {
    position: absolute;
    top: 0px;
    left: 0;
    width: 40px;
    height: 40px;
    background: #e9b531;
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
}
.cart-area .cart-table tbody tr td.price {
    color: #83888d;
    font-size: 20px;
    font-weight: 400;
}
.cart-area .cart-table tbody tr .sub-total {
    color: #409e35;
    font-size: 20px;
    font-weight: 400;
}
.cart-area .cart-table tbody tr td .remove{ }
.cart-area .cart-table tbody tr td .remove .checkbox label {
    color: #83888d;
    font-size: 18px;
    font-weight: 400;
}
.cart-area .cart-table tbody tr td .remove .checkbox label input[type="checkbox"] {
    display: inline-block;
    position: relative;
    top: 1px;
}

.cart-middle {
    padding-top: 30px
}
.cart-middle .apply-coupon {
    padding-left: 245px;
    position: relative;
}
.cart-middle .apply-coupon input[type="text"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;
    height: 60px;
    border: 1px solid #edf2f7;
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    padding: 0 15px;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
    border-radius: 5px;
}
.cart-middle .apply-coupon input[type="text"]:focus {
    border: 1px solid #e9b531
}
.cart-middle .apply-coupon .apply-coupon-button button {
    color: #d16111;
    background: #f3f7fa;
}
.cart-middle .apply-coupon .apply-coupon-button button:hover{
    color: #ffffff;
    background: #e9b531
}
.cart-middle .update-cart button {
    color: #ffffff;
    background: #e9b531;
}
.cart-middle .update-cart button:hover{
    background: #2c8306;
} 

.cart-bottom {
    overflow: hidden;
    padding-top: 70px;
}
.shop-title-box {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -5px;
    padding-bottom: 23px;
}
.shop-title-box h3{
    color: #d16111;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
}
.cart-bottom .calculate-shipping {}
.cart-bottom .calculate-shipping .ui-state-default .ui-icon {
    background: none
}
.cart-bottom .calculate-shipping .ui-state-default {
    position: relative;
    display: block;
    background: #ffffff;
    border: 1px solid #edf2f7;
    border-radius: 0;
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    margin-bottom: 30px;
    outline: medium none;
    width: 100%;
}
.cart-bottom .calculate-shipping .ui-selectmenu-button span.ui-selectmenu-text {
    display: block;
    line-height: 18px;
    overflow: hidden;
    padding: 15px 19px;
    text-align: left;
}
.cart-bottom .calculate-shipping .ui-selectmenu-button span.ui-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-left: 1px solid #edf2f7;
    display: block;
    width: 60px;
    height: 48px;
    text-indent: 0;
    margin: 0;
}
.cart-bottom .calculate-shipping .ui-selectmenu-button span.ui-icon::before {
    color: #9b9b9b;
    content: "\f107";
    font-family: FontAwesome;
    font-size: 20px;
    overflow: visible;
    position: absolute;
    right: 21px;
    top: 10px;
}
.cart-bottom .calculate-shipping input[type="text"] {
    position: relative;
    display: block;
    border: 1px solid #edf2f7;
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    height: 50px;
    padding: 0 20px;
    width: 100%;
    margin-bottom: 30px;
    transition: all 500ms ease;
}
.cart-bottom .calculate-shipping input[type="text"]:focus {
    border: 1px solid #1d1d1d;
}
.cart-bottom .calculate-shipping button {
    color: #d16111;
    background: #f3f7fa;
}
.cart-bottom .calculate-shipping button:hover{
    color: #ffffff;
    background: #e9b531;
}
.cart-bottom button.checkout-btn{
    color: #d16111;
    background: #f3f7fa;
    margin-top: 30px;
}
.cart-bottom button.checkout-btn:hover{
    color: #ffffff;
    background: #e9b531;    
}



/*** 
=============================================
    Checkout Area style
=============================================
***/
.checkout-area {
    position: relative;
    display: block;
    padding-top: 100px;
}
.checkout-area .exisitng-customer {
    position: relative;
    display: block;
    background: #f3f7fa;
    padding: 20px 28px 21px;
    margin-bottom: 20px;
    border-radius: 5px;
}
.checkout-area .exisitng-customer:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: #409e35;
    content: "";
}
.checkout-area .exisitng-customer h5{
    color: #222222;
    font-size: 16px;
    font-weight: 500;
}
.checkout-area .exisitng-customer h5 a{
    color: #409e35;
    font-weight: 700;
    display: inline-block;
    padding-left: 15px;
}

.checkout-area .coupon {
    position: relative;
    display: block;
    background: #f3f7fa;
    padding: 20px 28px 21px;
    margin-bottom: 60px;
    border-radius: 5px;
}
.checkout-area .coupon:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: #409e35;
    content: "";  
}
.checkout-area .coupon h5 {
    color: #222222;
    font-size: 16px;
    font-weight: 500;
}
.checkout-area .coupon h5 a {
    color: #409e35;
    font-weight: 700;
    display: inline-block;
    padding-left: 15px;  
}
.checkout-area .form form .field-label {
    color: #83888d;
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 3px;
    text-transform: capitalize;
}
.checkout-area .form form .field-input input[type="text"] {
    position: relative;
    display: block;   
    border: 1px solid #edf2f7;
    color: #848484;
    font-size: 16px;
    height: 48px;
    margin-bottom: 25px;
    padding: 0 15px;
    width: 100%;
    border-radius: 5px;
    transition: all 500ms ease;
}
.checkout-area .form form .field-input input[type="text"]:focus{
    border: 1px solid #e9b531;
}
.checkout-area .create-acc .checkbox {
    margin: 7px 0 0;
}
.checkout-area .create-acc .checkbox label {
    color: #d16111;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    cursor: pointer;
}
.checkout-area .create-acc .checkbox input {
    position: relative;
    top: 1px;
}
.checkout-area .shipping-info input[type="checkbox"] {
    cursor: pointer;
    display: inline-block;
    margin: 0 0 0 20px;
    position: relative;
    top: 0px;
    vertical-align: middle;
}
.checkout-area .form form .field-input textarea {
    position: relative;
    display: block;
    border: 1px solid #edf2f7;
    color: #848484;
    font-size: 16px;
    height: 121px;
    margin-bottom: 28px;
    padding: 10px 15px;
    width: 100%;
    border-radius: 5px;
    transition: all 500ms ease;
}
.checkout-area .form form .field-input textarea:focus{
    border-color: #e9b531;
}


.checkout-area .bottom {
    position: relative;
    display: block;
    padding-top: 92px;
    padding-bottom: 100px;
}
.checkout-area .table {
    overflow-x: auto;
    position: relative;
    width: 100%;
    margin-bottom: 0;
}
.checkout-area .table .cart-table {
    min-width: auto;
    width: 100%;
}
.checkout-area .table .cart-table .cart-header {
    position: relative;
    background: #f3f7fa;
    color: #d16111;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    width: 100%;
}
.checkout-area .table .cart-table thead tr th {
    font-weight: 700;
    line-height: 24px;
    min-width: 110px;
    padding: 19px 30px 17px;
    border-top: none;
    border-bottom: none;
}
.checkout-area .table .cart-table thead tr th.product-column {
    padding-left: 30px;
    text-align: left;
}
.checkout-area .table .cart-table tbody tr {
    border-bottom: 1px solid #edf2f7;
}
.checkout-area .table .cart-table tbody tr td {
    vertical-align: middle
}
.checkout-area .table .cart-table tbody tr td {
    min-width: 110px;
    padding: 25px 25px;
    border-top: none;
}

.checkout-area .table .cart-table tbody tr .product-column .column-box {
    min-height: 70px;
}
.checkout-area .table .cart-table tbody tr .product-column .column-box .prod-thumb {
    width: 70px;
}
.checkout-area .table .cart-table tbody tr .product-column .column-box .prod-thumb a {
    display: block;
    border: 1px solid #edf2f7;
}
.checkout-area .table .cart-table tbody tr .product-column .column-box .prod-thumb img {
    display: block;
    max-width: 100%;
}
.checkout-area .table .cart-table tbody tr .product-column .column-box .prod-thumb, 
.checkout-area .table .cart-table tbody tr .product-column .column-box .product-title{
    display: table-cell;
    vertical-align: middle;
}
.checkout-area .table .cart-table tbody tr .product-column .column-box .product-title{
    padding-left: 20px;
}
.checkout-area .table .cart-table tbody tr .product-column .column-box .product-title h3 {
    color: #d16111;
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
}

.checkout-area .table .cart-table tbody tr .qty {
    padding-right: 30px;
    width: 130px;
}
.checkout-area .table .cart-table tbody tr .qty .input-group.bootstrap-touchspin {
    float: left;
    width: 46px;
}
.checkout-area .table .cart-table tbody tr .qty .form-control {
    display: block;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #edf2f7;
    border-radius: 0 !important;
    box-shadow: none;
    color: #d16111;
    font-size: 18px;
    font-weight: 500;
    height: 50px;
    padding: 6px 0;
    text-align: center;
    font-family: 'TrebuchetMS', sans-serif;
}

.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn {
    position: relative;
    display: block;
    float: none;
    margin-left: 0px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #edf2f7;
    color: #848484;
    padding: 12px 11px;
    cursor: pointer;
}
.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0
}
.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    border-radius: 0;
    margin-top: -2px;
}
.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-up:before {
    content: "\f0d8";
    font-family: FontAwesome;
    color: #848484;
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 1px 5px 5px;
}
.checkout-area .table .cart-table tbody tr .qty .bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-down:before {
    content: "\f0d7";
    font-family: FontAwesome;
    color: #848484;
    font-size: 14px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 2px 5px 8px;
}
.checkout-area .table .cart-table tbody tr td.price {
    color: #409e35;
    font-size: 18px;
    font-weight: 500;
}


.cart-total-table {
    border: 1px solid #edf2f7
}
.cart-total-table li {
    border-bottom: 1px solid #edf2f7
}
.cart-total-table li:last-child {
    border-bottom: none
}
.cart-total-table li span.col.col-title {
    position: relative;
    display: block;
    float: left;
    border-right: 1px solid #edf2f7;
    color: #d16111;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    padding: 10px 18px;
    width: 50%;
    font-family: 'TrebuchetMS', sans-serif;
}
.cart-total-table li span.col {
    position: relative;
    display: block;
    float: left;
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    padding: 10px 18px;
    width: 50%;
    font-family: 'TrebuchetMS', sans-serif;
}


.cart-total .payment-options {
    position: relative;
    display: block;
    overflow: hidden;
    background: #ffffff;
    margin-top: 30px;
    border: 1px solid #edf2f7;
    padding: 33px 30px 40px;
}
.cart-total .payment-options .option-block {
    margin-bottom: 14px
}
.cart-total .payment-options .option-block .checkbox {
    margin: 0 0 5px;
}
.cart-total .payment-options .option-block .checkbox label {
    display: block;
    font-weight: 500;
    min-height: 20px;
    padding-left: 0px;
    margin: 0;
}
.cart-total .payment-options .option-block .checkbox label input {
    position: relative;
    top: 1px;
}
.cart-total .payment-options .option-block .checkbox label span {
    color: #d16111;
    font-size: 16px;
    font-weight: 500;
    padding-left: 10px;
    font-family: 'TrebuchetMS', sans-serif;
}
.cart-total .payment-options .option-block .checkbox label span b {
    color: #409e35;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    padding-left: 25px;
    text-decoration: underline;
}
.cart-total .payment-options .option-block .text{
    padding-left: 30px;
}
.cart-total .payment-options .option-block .text p {
    margin: 0;
    line-height: 28px;
}
.placeorder-button button {
    color: #d16111;
    background: #f3f7fa;
    margin-top: 17px;
}
.placeorder-button button:hover{ 
    color: #ffffff;
    background: #e9b531;
}



/*** 
=============================================
    Login Register Area style
=============================================
***/
.login-register-area {
    position: relative;
    display: block;
    padding-top: 100px;
    padding-bottom: 88px;
}
.login-register-area .form form {
    width: 100%;
}
.login-register-area .form .input-field {
    position: relative;
}
.login-register-area .form .input-field input[type="text"] {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    border: 1px solid #edf2f7;
    color: #83888d;
    font-size: 16px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 50px;
    transition: all 700ms ease 0s;
}
.login-register-area .form .input-field .icon-holder {
    position: absolute;
    right: 20px;
    top: 12px;
}
.login-register-area .form .input-field .icon-holder i {
    color: #999999;
    font-size: 14px;
    font-weight: 400;
}
.login-register-area .form button {
    color: #d16111;
    background: #f3f7fa;
}
.login-register-area .form button:hover{ 
    color: #ffffff;
    background: #e9b531;
}

.login-register-area .form .remember-text {
    margin-top: 19px;
}
.login-register-area .form .remember-text .checkbox label {
    color: #d16111;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    cursor: pointer;
}
.login-register-area .form .remember-text .checkbox input {
    position: relative;
    top: 1px;
}
.login-register-area .form .remember-text .checkbox span {
    color: #d16111;
    font-size: 16px;
    font-weight: 400;
}
.login-register-area .form .social-icon {
    position: relative;
    float: right;
    margin-right: -8px;
    margin-top: 15px;
}
.login-register-area .form .social-icon .login-with {
    display: inline-block;
    left: -110px;
    position: absolute;
    top: 8px;
}
.login-register-area .form .social-icon li {
    display: inline-block;
    margin: 0 8px;
}
.login-register-area .form .social-icon li a i {
    background: #47588f none repeat scroll 0 0;
    border-radius: 50%;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 100;
    height: 40px;
    line-height: 24px;
    padding: 8px 0;
    text-align: center;
    transition: all 700ms ease 0s;
    width: 40px;
}
.login-register-area .form .social-icon li a i.twitter {
    background: #33ccfe none repeat scroll 0 0;
}
.login-register-area .form .social-icon li a i.gplus {
    background: #dd4b39 none repeat scroll 0 0;
}
.login-register-area .form a.forgot-password {
    color: #f3441e;
    float: right;
    font-weight: 500;
    margin: 20px 0 0;
}
.login-register-area .form .right {
    display: block;
    overflow: hidden;
    margin-left: -20px;
}
.login-register-area .form .right h6 {
    float: left;
    color: #868298;
    font-size: 14px;
    font-weight: 400;
    margin-top: 28px;
}
.login-register-area .form .right h6 span{
    color: #f36727;
}
.login-register-area .form .input-field input[type="text"]:focus{
    border: 1px solid #1d1d1d;    
}
.login-register-area .form .social-icon li a:hover i{
    background: #18387b;   
}
.login-register-area .form .social-icon li a:hover i.twitter{
    background: #12addf;    
}
.login-register-area .form .social-icon li a:hover i.gplus{
    background: #c62d1a;
}



/*** 
=============================================
    Contact Details Area style
=============================================
***/
.contact-details-area {
    position: relative;
    display: block;
    padding: 100px 0 93px;
}
.single-contact-details-box {
    position: relative;
    display: block;
    -webkit-box-shadow: 0px 3px 13px 2px #F0F0F0; 
    box-shadow: 0px 3px 13px 2px #F0F0F0;
    margin-bottom: 40px;
}
.single-contact-details-box .image-box{
    position: relative;
    display: block;
}
.single-contact-details-box .image-box img{
    width: 100%;
}
.single-contact-details-box .text{
    position: relative;
    display: block;
    padding: 34px 30px 32px;
}
.single-contact-details-box .text p{
    margin: 0 0 14px;
}
#contact-google-map {
    height: 230px;
    width: 100%;
}
.contact-details-area .emergency-call{
    margin-top: 15px;
}



/*** 
=============================================
    Contact Info Area style
=============================================
***/
.contact-info-area{
    position: relative;
    display: block;
    overflow: hidden;
    background: #f3f7fa;
    padding: 0px 0 0px;
}
.contact-form {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
}
.contact-form .inner{
    display: table-cell;
    vertical-align: middle;    
}
.contact-form form{
    position: relative;
    display: block;
}
.contact-form form .input-box{
    position: relative;
    display: block;
}
.contact-form form input[type="text"],
.contact-form form input[type="email"],
.contact-form form textarea{
    position: relative;
    display: block;   
    background: #ffffff;
    border: 1px solid #e2e8f0;
    width: 100%;
    height: 60px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    margin-bottom: 30px;
    transition: all 500ms ease;
}
.contact-form form textarea {
    height: 100px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 15px;
}
.contact-form form input[type="text"]:focus{
    border-color: #1d1d1d; 
    color: #222222;
}
.contact-form form input[type="email"]:focus{
    border-color: #1d1d1d;
    color: #222222;
}
.contact-form form textarea:focus{
    border-color: #1d1d1d;
    color: #222222;
}
.contact-form form input[type="text"]::-webkit-input-placeholder {
    color: #83888d;
}
.contact-form form input[type="text"]:-moz-placeholder {
    color: #83888d;
}
.contact-form form input[type="text"]::-moz-placeholder {
    color: #83888d;
}
.contact-form form input[type="text"]:-ms-input-placeholder {
    color: #83888d;
}
.contact-form form input[type="email"]::-webkit-input-placeholder {
    color: #83888d;
}
.contact-form form input[type="email"]:-moz-placeholder {
    color: #83888d;
}
.contact-form form input[type="email"]::-moz-placeholder {
    color: #83888d;
}
.contact-form form input[type="email"]:-ms-input-placeholder {
    color: #83888d;
}
.contact-form form button {
    color: #ffffff;
    background: #e9b531;
    width: 100%;
}
.contact-form form button:hover{
    color: #dddddd;
    background: #2e8806;
}

.contact-form-text-box {
    position: relative;
    display: block;
    margin-right: 35px;
    padding: 150px 0 144px;
    z-index: 1;
}
.contact-form-text-box:before {
    position: absolute;
    top: -1000px;
    left: -50000px;
    bottom: -1000px;
    right: 0;
    background: #409e35;
    content: "";
    z-index: -1;
}
.contact-form-text-box .inner{
    position: relative;
    display: block;
    padding-right: 50px;
}
.contact-form-text-box .inner .icon-holder {
    position: absolute;
    top: 0;
    left: -380px;
    bottom: 0;
    z-index: -1;
}
.contact-form-text-box .inner .icon-holder span:before {
    color: #47ad3b;
    font-size: 415px;
    line-height: 275px;
}

.contact-form-text-box .sec-title {
    padding-bottom: 30px;
}
.contact-form-text-box .sec-title p{
    color: #f2cb42;
}
.contact-form-text-box .sec-title .title{
    color: #ffffff;
}
.contact-form-text-box .inner-content{
    position: relative;
    display: block;    
}
.contact-form-text-box .inner-content h2{
    color: rgba(0, 0, 0, 0.5);
    font-size: 26px;
    line-height: 48px;
    font-weight: 600;
}



/*** 
=============================================
    Branches Section style
=============================================
***/
.branches-section{
    position: relative;
    display: block;
    padding: 100px 0 93px;
}
.single-branches-box {
    position: relative;
    display: block;
    border: 1px solid #edf2f7;
    padding: 54px 30px 51px;
    transition: all 900ms ease;
    transition-delay: .5s;
}
.single-branches-box .title-box{
    position: relative;
    display: block; 
    padding-bottom: 18px;
}
.single-branches-box .title-box h3{}
.single-branches-box ul {
    position: relative;
    display: block;
    padding-bottom: 11px;
}
.single-branches-box ul li{
    display: block;
    margin-bottom: 11px;
}
.single-branches-box ul li:last-child{
    margin-bottom: 0;
}
.single-branches-box .text{}
.single-branches-box .text p{
    margin: 0;    
}

.branches-carousel.owl-carousel .owl-nav {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
}
.branches-carousel.owl-carousel .owl-nav [class*="owl-"] {
    position: relative;
    display: inline-block;
    background: #ffffff;
    color: #83888d;
    font-size: 26px;
    font-weight: 300;
    width: 60px;
    height: 60px;
    border: 2px solid #dbe5ec;
    line-height: 54px;
    margin: 0;
    padding: 0;
        transition: all 700ms ease 100ms;
    border-radius: 50%;
}
.branches-carousel.owl-carousel .owl-nav [class*="owl-"] p {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    color: #e9b531;
    font-size: 16px;
    font-weight: 700;
    line-height: 58px;
    margin: 0;
    text-transform: capitalize;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: all 700ms ease 200ms;
}
.branches-carousel.owl-carousel .owl-nav .owl-prev{
    position: absolute;
    left: -80px;
    top: -40px;    
}
.branches-carousel.owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: -80px;
    top: -40px;   
}
.branches-carousel.owl-carousel .owl-nav [class*="owl-"]:hover{
    border-color: #e9b531;
}
.branches-carousel.owl-carousel .owl-nav [class*="owl-"]:hover p{
    transform: scale(1.0); 
}

.our-all-branches{
    position: relative;
    display: block;
    padding-top: 45px;
    text-align: center;
}
.our-all-branches h6{
    color: #83888d;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.our-all-branches h6 span{
    color: #409e35;
}



/*** 
=============================================
    Mission Vision Area style
=============================================
***/
.mission-vision-area{
    position: relative;
    display: block;
}
.mission-vision-area .inner-content{
    padding: 0;
}
.mission-vision-area .mar0{
    margin: 0;
}
.mission-vision-area .pd0{
    padding: 0;    
}

.video-holder-box {
    position: relative;
    display: block;
    width: 100%;
}
.video-holder-box .img-holder{
    position: relative;
    display: block;
    overflow: hidden;
}
.video-holder-box .img-holder img {
    width: 100%;
}
.video-holder-box .img-holder .icon-holder{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    content: "";
    background: rgba(255, 255, 255, 0.02);
    transition: all 900ms ease;
    z-index: 3;
    opacity: 1;    
}
.video-holder-box .img-holder .icon-holder .icon{
    display: table;
    width: 100%;
    height: 100%;
}
.video-holder-box .img-holder .icon-holder .icon .inner{
    display: table-cell;
    vertical-align: middle;
}
.video-holder-box .img-holder .icon-holder .icon .inner a {
    position: relative;
    display: inline-block;
    border: 2px solid rgba(255, 255, 255, .99);
    color: #e9b531;
    height: 125px;
    width: 125px;
    border-radius: 50%;
    text-align: center;
    transition: all 500ms ease;
    padding: 10px;
}
.video-holder-box .img-holder .icon-holder .icon .inner a span:before {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: #ffffff;
    font-size: 30px;
    line-height: 100px;
    border-radius: 50%;
    -webkit-animation: pulse 2s infinite;
    -o-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    transition: all 1s ease;
}
@keyframes pulse {
    50% {
        box-shadow: 0 0 0 5px rgba(255,255,255,.1),
        0 0 0 20px rgba(238, 238,238, 0.3000);
    }
}


.mission-vision-content{
    position: relative;
    display: block;
}
.mission-vision-content .single-box{
    position: relative;
    display: block;
    overflow: hidden;
}
.mission-vision-content .single-box img {
    width: 100%;
}
.mission-vision-content .single-box .title{
    position: absolute;
    left: 30px;
    bottom: 30px;
    right: 30px;
    background: #ffffff;
    text-align: center;
    border-radius: 5px;
    display: block;
    padding: 13px 0 12px;
    transition: all 500ms ease 50ms;
}
.mission-vision-content .single-box:hover .title{
    transform: translateX(0%) translateY(100%) scale(1.0);    
}
.mission-vision-content .single-box .title h3{
    font-size: 24px;
    line-height: 32px;
}

.mission-vision-content .overlay-content {
    position: absolute;
    top: 0;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: #d16111;
    display: block;
    padding: 80px 60px 0;
    padding-top: 17%;
    transform: translateX(0%) translateY(-100%) scale(1.0);
    transition: all 500ms ease 100ms;
}
.mission-vision-content .single-box:hover .overlay-content{
    transform: translateX(0%) translateY(0%) scale(1.0);    
}
.mission-vision-content .overlay-content .top{
    position: relative;
    display: block;
    overflow: hidden;
}
.mission-vision-content .overlay-content .top .icon{
    position: relative;
    display: block;
    float: left;
}
.mission-vision-content .overlay-content .top .icon span:before{
    color: #409e35;
    font-size: 120px;
    line-height: 122px;
}
.mission-vision-content .overlay-content .top .big-title {
    position: relative;
    top: -3px;
    display: block;
    float: right;
    color: #293c6e;
    font-size: 200px;
    font-weight: 700;
    line-height: 150px;
    text-transform: uppercase;
}
.mission-vision-content .overlay-content .text {
    position: relative;
    display: block;
    padding-top: 18px;
}
.mission-vision-content .overlay-content .text h3{
    color: #ffffff;
    font-size: 24px;
    margin: 0 0 19px;
}
.mission-vision-content .overlay-content .text p{
    color: #b3cde4;
    margin: 0;
}





/*********************************** 
    qoute styles
************************************/
.qoute-form-one {
    position: relative;
}
.qoute-form-one:before {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #f5f5f5;
}
.qoute-form-one .container {
    position: relative;
}
.qoute-form-one .image-box {
    position: relative;
}
.qoute-form-one .image-box .phone-box {
    background: #fff;
    width: 100%;
    max-width: 230px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    padding: 20px 0;
    padding-left: 30px;
}
.qoute-form-one .image-box .phone-box i[class*=-icon-] {
    color: #FFBA00;
    font-size: 30px;
    display: inline-block;
    line-height: 1em;
    vertical-align: middle;
}
.qoute-form-one .image-box .phone-box .phone-number {
    line-height: 1em;
    font-size: 20px;
    color: #2E2E2E;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
}
.qoute-form-one .form-block {
    background: #fff;
    padding: 70px 50px;
    padding-top: 53px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.09);
}
.qoute-form-one .form-block h3,
.qoute-form-one .form-block p {
    margin: 0;
}
.qoute-form-one .form-block h3 {
    font-size: 30px;
    line-height: 34px;
    color: #2E2E2E;
    font-weight: bold;
}
.qoute-form-one .form-block p {
    font-size: 18px;
    color: #83888D;
    line-height: 28px;
    margin-top: 5px;
    margin-bottom: 43px;
}
.qoute-form-one .form-block input {
    display: block;
    border: none;
    outline: none;
    background-color: transparent;
    border: 1px solid #EAF0EE;
    border-radius: 5px;
    width: 100%;
    color: #83888D;
    height: 60px;
    padding-left: 20px;
    margin-bottom: 20px;
}
.qoute-form-one .form-block input::-webkit-input-placeholder {
    color: #83888D;
}
.qoute-form-one .form-block input::-moz-placeholder {
    color: #83888D;
}
.qoute-form-one .form-block input:-ms-input-placeholder {
    color: #83888D;
}
.qoute-form-one .form-block input:-moz-placeholder {
    color: #83888D;
}
.qoute-form-one .form-block .bootstrap-select>.dropdown-toggle {
    outline: none !important;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 5px;
    border: 1px solid #EAF0EE;
    height: 60px;
    background-color: transparent;
    padding: 0;
    padding-left: 20px;
    color: #83888D;
    line-height: 60px;
    margin-bottom: 2px;
}
.qoute-form-one .form-block .bootstrap-select>.dropdown-toggle::after {
    content: '\f107';
    color: #83888D;
    font-size: 18px;
    border: none !important;
    font-family: 'FontAwesome';
    position: absolute;
    top: 50%;
    right: 20px;
    width: auto;
    height: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.qoute-form-one .form-block .bootstrap-select .dropdown-menu {
    margin: 0;
    padding: 0;
}
.qoute-form-one .form-block .dropdown-item.active,
.qoute-form-one .form-block .dropdown-item:active {
    background: #FFBA00;
    color: #2E2E2E;
}
.qoute-form-one .form-block button[type=submit] {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    background-color: #01B9E6;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    border-radius: 5px;
    padding: 19.5px 0;
    transition: all .4s ease;
}
.qoute-form-one .form-block button[type=submit]:hover {
    background: #2E2E2E;
    color: #fff;
}
.qoute-form-one .form-block button[type=submit] i {
    font-size: 21px;
    display: inline-block;
    vertical-align: bottom;
    line-height: 1em;
    margin-left: 7px;
}
.qoute-form-one.home-three::before {
    background: #F2F7F5;
}
.qoute-form-one.home-three .image-box .phone-box i[class*=-icon-] {
    color: #28B381;
}
.qoute-form-one.home-three .form-block button[type=submit] {
    background: #F9A03B;
}
.qoute-form-one.home-three .form-block button[type=submit]:hover {
    background: #28B381;
}



/******************************** 
* cta styles
************************************/
.cta-style-one .thm-btn+.thm-btn {
    margin-left: 16px;
}

.cta-style-one.home-five {
    background: #F5F5F5;
}

.cta-style-one.home-five .sec-title-two span.tag-line {
    color: #F9C807;
}

.cta-style-two {
    padding: 42.5px 0;
    background-color: #F9C807;
}

.cta-style-two h2 {
    margin: 0;
    color: #2E2E2E;
    font-size: 30px;
    font-weight: bold;
    line-height: 57px;
}



/******************************** 
* features styles
************************************/
.single-feautre-style-one {
    margin-bottom: 30px;
    background: #fff;
    padding: 40px;
    border-radius: 4px;
    transition: all .4s ease;
}

.single-feautre-style-one .icon-block,
.single-feautre-style-one .text-block {
    display: table-cell;
    vertical-align: middle;
}

.single-feautre-style-one .icon-block .inner-box {
    border-radius: 5px;
    border: 1px solid #F2F2F2;
    width: 100px;
    height: 100px;
    text-align: center;
    position: relative;
}

.single-feautre-style-one .icon-block .inner-box:before,
.single-feautre-style-one .icon-block .inner-box:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    top: 0;
    left: 0;
    transition: all .4s ease;
    border-radius: 5px;
}

.single-feautre-style-one .icon-block .inner-box:before {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    border-top: 2px solid #01B9E6;
    border-bottom: 2px solid #01B9E6;
}

.single-feautre-style-one .icon-block .inner-box:after {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    border-left: 2px solid #01B9E6;
    border-right: 2px solid #01B9E6;
}

.single-feautre-style-one .icon-block i {
    font-size: 50px;
    color: #01B9E6;
    line-height: 98px;
}

.single-feautre-style-one .text-block {
    padding-left: 40px;
}

.single-feautre-style-one .text-block h3,
.single-feautre-style-one .text-block p {
    margin: 0;
}

.single-feautre-style-one .text-block h3 {
    position: relative;
    font-size: 20px;
    margin-bottom: 16px;
    transition: all .4s ease;
}

.single-feautre-style-one .text-block h3:before {
    content: '';
    width: 0%;
    height: 2px;
    background: #01B9E6;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .4s ease;
}

.single-feautre-style-one .text-block h3 a {
    color: #2E2E2E;
    transition: all .4s ease;
}

.single-feautre-style-one .text-block h3 a:hover {
    color: #01B9E6;
}

.single-feautre-style-one .text-block p {
    color: #83888D;
    line-height: 26px;
}

.single-feautre-style-one:hover {
    box-shadow: 0px 21px 21px rgba(0, 0, 0, 0.07);
}

.single-feautre-style-one:hover .icon-block .inner-box:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.single-feautre-style-one:hover .icon-block .inner-box:after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.single-feautre-style-one:hover .text-block h3 {
    padding-left: calc(20% + 20px);
}

.single-feautre-style-one:hover .text-block h3:before {
    width: 20%;
}

.single-feautre-style-one:hover .text-block h3 a {
    color: #01B9E6;
}

.featured-style-two .feature-title-box .sec-title-three {
    margin: 0;
}

.featured-style-two .feature-title-box p {
    color: #83888D;
    font-size: 16px;
    line-height: 26px;
    margin: 39px 0 0;
}

.featured-style-two .feature-title-box ul.list-items {
    margin-top: 20px;
}

.featured-style-two .feature-title-box ul.list-items li {
    position: relative;
    color: #83888D;
    font-size: 16px;
    padding-left: 40px;
}

.featured-style-two .feature-title-box ul.list-items li+li {
    margin-top: 10px;
}

.featured-style-two .feature-title-box ul.list-items li i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    color: #1b996c;
    font-size: 22px;
}

.featured-style-two .single-featured-box-one .upper-block {
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.featured-style-two .single-featured-box-one .image-box {
    position: relative;
    overflow: hidden;
    -webkit-perspective: 400px;
    perspective: 400px;
}

.featured-style-two .single-featured-box-one .image-box>img {
    width: 100%;
    transition: all .4s ease;
}

.featured-style-two .single-featured-box-one .image-box .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgba(25, 37, 33, 0.7);
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    transition: all .4s ease;
}

.featured-style-two .single-featured-box-one .image-box .box a {
    color: #FFBA00;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 21px;
    display: inline-block;
    vertical-align: middle;
}

.featured-style-two .single-featured-box-one .image-box .box a i {
    font-size: 21px;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 6px;
}

.featured-style-two .single-featured-box-one .text-block {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 35px 30px;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.06);
}

.featured-style-two .single-featured-box-one .text-block h3,
.featured-style-two .single-featured-box-one .text-block p {
    margin: 0;
}

.featured-style-two .single-featured-box-one .text-block h3 {
    display: inline-block;
    font-size: 20px;
    margin-bottom: 10px;
    position: relative;
}

.featured-style-two .single-featured-box-one .text-block h3:before {
    content: '';
    background: #F9A03B;
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 2px;
    left: 0;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
}

.featured-style-two .single-featured-box-one .text-block h3 a {
    position: relative;
    color: #3A3A3A;
    transition: all .4s ease;
}

.featured-style-two .single-featured-box-one .text-block p {
    color: #83888D;
    font-size: 16px;
    line-height: 26px;
}

.featured-style-two .single-featured-box-one:hover .image-box .box {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.featured-style-two .single-featured-box-one:hover .image-box>img {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
}

.featured-style-two .single-featured-box-one:hover .text-block h3:before {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.single-feautre-style-three {
    margin-bottom: 30px;
}

.single-feautre-style-three .image-block {
    margin-bottom: 20px;
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 5;
}

.single-feautre-style-three .image-block img {
    width: 100%;
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    transition: all 700ms ease;
}

.single-feautre-style-three .overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(2, 31, 61, 0.9);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: all 700ms ease 100ms;
}

.single-feautre-style-three .overlay-content .inner-content {
    display: table;
    width: 100%;
    height: 100%;
}

.single-feautre-style-three .overlay-content .inner-content .text-box {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 700ms ease 500ms;
}

.single-feautre-style-three .overlay-content .inner-content .text-box .link-btn {
    font-size: 70px;
    color: #91C942;
}

.single-feautre-style-three:hover .image-block img {
    -webkit-transform: scale(1.2) rotate(2deg);
    transform: scale(1.2) rotate(2deg);
}

.single-feautre-style-three:hover .overlay-content {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.single-feautre-style-three:hover .overlay-content .inner-content .text-box {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.single-feautre-style-three .text-block {
    position: relative;
    padding: 33px 30px;
    box-shadow: 0 0 21px rgba(0, 0, 0, 0.06);
}

.single-feautre-style-three .text-block h3,
.single-feautre-style-three .text-block p {
    margin: 0;
}

.single-feautre-style-three .text-block h3 {
    font-size: 20px;
    font-weight: bold;
}

.single-feautre-style-three .text-block h3 a {
    color: #3A3A3A;
    transition: all .4s ease;
}

.single-feautre-style-three .text-block h3 a:hover {
    color: #34964D;
}

.single-feautre-style-three .text-block p {
    color: #83888D;
    font-size: 16px;
    line-height: 26px;
    margin-top: 10px;
    margin-bottom: 17px;
}

.single-feautre-style-three .text-block a.more-link {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: #3A3A3A;
    display: inline-block;
    transition: all .4s ease;
}

.single-feautre-style-three .text-block a.more-link i {
    display: inline-block;
    vertical-align: -3px;
    font-size: 20px;
    margin-left: 10px;
}

.single-feautre-style-three .text-block a.more-link:hover {
    color: #34964D;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

.single-feautre-style-three .text-block .box-icon {
    font-size: 70px;
    position: absolute;
    color: #DCE4E1;
    bottom: 20px;
    right: 20px;
    transition: all .4s ease;
}

.single-feautre-style-three:hover .text-block .box-icon {
    opacity: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.single-featured-style-four {
    margin-bottom: 30px;
}

.single-featured-style-four .icon-block i {
    color: #F9C807;
    font-size: 50px;
}

.single-featured-style-four h3,
.single-featured-style-four p {
    margin: 0;
}

.single-featured-style-four h3 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 26px;
}

.single-featured-style-four h3 a {
    color: #2E2E2E;
    transition: all .4s ease;
}

.single-featured-style-four h3 a:hover {
    color: #F9C807;
}

.single-featured-style-four p {
    color: #83888D;
    font-size: 16px;
    line-height: 28px;
    margin-top: 6px;
}

.featured-style-five {
    padding-bottom: 100px;
}

.featured-style-five .container {
    position: relative;
}

.featured-style-five .container .container-outer {
    position: absolute;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 99;
    padding: 0 15px;
}

.single-featured-style-five {
    box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.14);
    background: #fff;
    transition: all .4s ease;
}

.single-featured-style-five h3,
.single-featured-style-five p {
    margin: 0;
}

.single-featured-style-five h3 {
    color: #262626;
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
}

.single-featured-style-five p {
    color: #716D6D;
    font-size: 16px;
    line-height: 26px;
}

.single-featured-style-five .box-icon {
    color: #2E2E2E;
    font-size: 40px;
}

.single-featured-style-five .top-box {
    margin-bottom: 16px;
}

.single-featured-style-five .top-box .icon-block,
.single-featured-style-five .top-box .text-block {
    display: table-cell;
    vertical-align: middle;
}

.single-featured-style-five .top-box .text-block {
    padding-left: 30px;
}

.single-featured-style-five .inner-box {
    overflow: hidden;
    padding: 45px 40px;
    position: relative;
}

.single-featured-style-five .bubbled {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 65px;
    height: 70px;
    text-align: center;
    z-index: 10;
    -webkit-transform: translate(100%, 50%);
    transform: translate(100%, 50%);
    transition: all .4s ease;
}

.single-featured-style-five .bubbled:before {
    content: '';
    width: 90px;
    height: 90px;
    position: absolute;
    top: 0;
    right: 0;
    background: #DDB206;
    -webkit-transform: translate(21%, 7%);
    transform: translate(21%, 7%);
    border-radius: 50%;
    z-index: 10;
    transition: all .4s ease;
}

.single-featured-style-five .bubbled .count-text {
    position: relative;
    z-index: 11;
    line-height: 77px;
    display: inline-block;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    transition: all .4s ease;
}

.single-featured-style-five:hover {
    background-color: #F9C807;
}

.single-featured-style-five:hover .bubbled {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}





.fun-fact-style-one .row [class*=col-]+[class*=col-] {
    border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.fun-fact-style-one.home-three {
    padding-top: 115px;
}

.fun-fact-style-one.home-three .row [class*=col-] {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.fun-fact-style-one.home-three .single-fun-fact-style-one h3 {
    color: #F9A03B;
}

.single-fun-fact-style-one {
    position: relative;
    padding: 65px 0;
    padding-left: 60px;
}

.single-fun-fact-style-one h3,
.single-fun-fact-style-one p {
    margin: 0;
}

.single-fun-fact-style-one h3 {
    color: #FFBA00;
    font-size: 60px;
    line-height: 1em;
}

.single-fun-fact-style-one h3 span.counter-text {
    font-size: 24px;
}

.single-fun-fact-style-one p {
    font-weight: bold;
    font-size: 20px;
    color: #fff;
}

.single-fun-fact-style-one .box-icon {
    font-size: 105px;
    color: rgba(255, 255, 255, 0.12);
    position: absolute;
    top: 50%;
    right: 40px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.single-fun-fact-style-two h3,
.single-fun-fact-style-two p {
    margin: 0;
}

.single-fun-fact-style-two h3 {
    color: #F9C807;
    font-size: 72px;
    line-height: 1em;
}

.single-fun-fact-style-two h3 span.counter-text {
    font-size: 30px;
}

.single-fun-fact-style-two p {
    font-weight: bold;
    font-size: 18px;
    color: #83888D;
    margin-bottom: 15px;
}







/******************************** 
* search popup styles
************************************/
.search_area {
    position: relative;
    max-width: 950px;
    margin: auto;
}

.search_area .search_box_inner {
    background: transparent;
    padding: 100px 60px;
    display: block;
    overflow: hidden;
}

.search_area .search_box_inner h3 {
    padding-bottom: 20px;
    font-family: "Hind", sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    color: #fff;
}

.search_area .search_box_inner .input-box {
    display: block;
    position: relative;
}

.search_area .search_box_inner .input-box input {
    background: transparent;
    border-radius: 0px;
    outline: none;
    height: 50px;
    padding: 0px;
    border: none;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    font-family: "Hind", sans-serif;
    font-size: 24px;
    font-weight: normal;
}

.search_area .search_box_inner .input-box input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff;
}

.search_area .search_box_inner .input-box input::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
}

.search_area .search_box_inner .input-box input:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
}

.search_area .search_box_inner .input-box input:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
}

.search_area .search_box_inner .input-box .input-box-btn {
    position: absolute;
    right: 5px;
    top: 14px;
    z-index: 4;
}

.search_area .search_box_inner .input-box .input-box-btn .btn-default {
    padding: 0px;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    background: transparent;
    color: #fff;
    font-size: 18px;
}

.search_area .mfp-close {
    right: 0px;
    color: #fff;
}

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.98;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}

/**
 * Fade-move animation for second dialog
 */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
    opacity: 0;
    transition: all 0.2s ease-out;
    -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
    transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: translateY(0) perspective(600px) rotateX(0);
    transform: translateY(0) perspective(600px) rotateX(0);
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
    opacity: 0;
    -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
    transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-bg {
    background: #2e2e2e;
}

/******************************** 
* tabbed styles
************************************/
.tabbed-funfact-wrapper {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 54px;
}

.tabbed-funfact-wrapper .tab-content {
    padding-top: 65px;
}

.tabbed-funfact-wrapper .tab-title {
    margin: 0;
    padding: 0;
    border: none;
    border-top: 5px solid #1B996C;
    background: #fff;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.tabbed-funfact-wrapper .tab-title .nav-item {
    float: left;
    width: 33.3333333333333%;
    text-align: center;
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link {
    border: none;
    position: relative;
    padding: 41px 0;
    border-radius: 0;
    box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.08);
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link h3 {
    color: #3A3A3A;
    font-size: 20px;
    transition: all .4s ease;
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link p {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .4s ease;
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link:after {
    content: '\e93f';
    font-family: 'energy-icon';
    color: #F9A03B;
    font-size: 24px;
    position: absolute;
    top: 94%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    opacity: 0;
    transition: all .4s ease;
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link:before {
    content: '';
    width: 100%;
    height: 5px;
    background: #F9A03B;
    position: absolute;
    bottom: 100%;
    left: 0;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: right top;
    transform-origin: right top;
    transition: all .4s ease;
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link.active {
    background-color: #F9A03B;
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link.active:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: left top;
    transform-origin: left top;
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link.active:after {
    -webkit-transform: translateX(-50%) translateY(0%);
    transform: translateX(-50%) translateY(0%);
    opacity: 1;
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link.active h3 {
    color: #fff;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.tabbed-funfact-wrapper .tab-title .nav-item .nav-link.active p {
    opacity: 1;
    -webkit-transform: translate(-50%, 10%);
    transform: translate(-50%, 10%);
}

.single-tab-wrapper .single-tab-content .top-box {
    margin-bottom: 50px;
}

.single-tab-wrapper .single-tab-content .top-box .icon-block,
.single-tab-wrapper .single-tab-content .top-box .text-block {
    display: table-cell;
    vertical-align: middle;
}

.single-tab-wrapper .single-tab-content .top-box .icon-block i {
    font-size: 75px;
    color: #fff;
}

.single-tab-wrapper .single-tab-content .top-box .text-block {
    padding-left: 35px;
}

.single-tab-wrapper .single-tab-content .top-box .text-block span.tag-line {
    display: block;
    color: #F9A03B;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
}

.single-tab-wrapper .single-tab-content .top-box .text-block h3 {
    color: #FFFFFF;
    font-size: 40px;
    margin-top: 14px;
}

.single-tab-wrapper .single-tab-content .content-box p {
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
}

.single-tab-wrapper .single-tab-content .content-box p.highlite {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
}

.single-tab-wrapper .single-tab-content .name-box {
    margin-top: 30px;
}

.single-tab-wrapper .single-tab-content .name-box .text-block,
.single-tab-wrapper .single-tab-content .name-box .image-box {
    display: table-cell;
    vertical-align: middle;
}

.single-tab-wrapper .single-tab-content .name-box .text-block {
    padding-right: 40px;
}

.single-tab-wrapper .single-tab-content .name-box .text-block h4 {
    color: #fff;
    margin: 0;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: bold;
}

.single-tab-wrapper .single-tab-content .name-box .text-block span {
    color: #F9A03B;
}

/******************************** 
* easy step styles
************************************/
.easy-steps {
    position: relative;
}

.easy-steps .overlayed-background {
    position: relative;
}

.easy-steps .overlayed-background:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #192521;
    opacity: 0.92;
}

.easy-steps .container {
    position: relative;
}

.easy-steps .title-block {
    position: relative;
    width: 100%;
    max-width: 585px;
    margin-left: auto;
}

.easy-steps .title-block .sec-title-three {
    margin: 0;
}

.easy-steps .title-block p {
    color: #A0A7A5;
    font-size: 16px;
    list-style: 26px;
    margin: 0;
    width: 60%;
    margin: 40px 0;
}

.easy-steps .content-box {
    padding-left: 110px;
    position: relative;
    padding-top: 105px;
    padding-bottom: 105px;
    margin-right: auto;
    width: 100%;
    max-width: 635px;
}

.easy-steps .content-box .single-content-box+.single-content-box {
    margin-top: 54px;
}

.easy-steps .content-box .single-content-box h3,
.easy-steps .content-box .single-content-box p {
    margin: 0;
}

.easy-steps .content-box .single-content-box h3 {
    font-size: 20px;
    list-style: 28px;
    color: #3A3A3A;
    margin-bottom: 26px;
}

.easy-steps .content-box .single-content-box p {
    color: #83888D;
    font-size: 16px;
    line-height: 26px;
}

.easy-steps .easy-steps-count-box {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}

.easy-steps .easy-steps-count-box .single-count-box {
    border-radius: 5px;
    background: #FFFFFF;
    text-align: center;
    width: 110px;
    height: 110px;
    position: relative;
}

.easy-steps .easy-steps-count-box .single-count-box+.single-count-box {
    margin-top: 70px;
}

.easy-steps .easy-steps-count-box .single-count-box:before,
.easy-steps .easy-steps-count-box .single-count-box:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    top: 0;
    left: 0;
    transition: all .4s ease;
    border-radius: 5px;
}

.easy-steps .easy-steps-count-box .single-count-box:before {
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    border-top: 4px solid #F9A03B;
    border-bottom: 4px solid #F9A03B;
}

.easy-steps .easy-steps-count-box .single-count-box:after {
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    border-left: 4px solid #F9A03B;
    border-right: 4px solid #F9A03B;
}

.easy-steps .easy-steps-count-box .single-count-box i {
    color: #28B381;
    font-size: 48px;
    line-height: 110px;
}

.easy-steps .easy-steps-count-box .single-count-box .count-text {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    line-height: 30px;
    font-size: 14px;
    background: #F9A03B;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 15px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.easy-steps .easy-steps-count-box .single-count-box:hover:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.easy-steps .easy-steps-count-box .single-count-box:hover:after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.easy-steps .video-box .icon-block,
.easy-steps .video-box .text-block {
    display: table-cell;
    vertical-align: middle;
}

.easy-steps .video-box .icon-block .inner-block {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    background: #F9A03B;
}

.easy-steps .video-box .icon-block .inner-block i {
    color: #fff;
    line-height: 80px;
}

.easy-steps .video-box .text-block {
    padding-left: 20px;
}

.easy-steps .video-box .text-block h3 {
    margin: 0;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
}

.easy-steps .video-box .text-block span {
    display: block;
    font-size: 16px;
    line-height: 1em;
    color: #F9A03B;
}

.easy-steps.home-four .title-block {
    width: 100%;
    max-width: 100%;
    position: relative;
}

.easy-steps.home-four .title-block .video-popup {
    width: 110px;
    height: 110px;
    border: 2px solid #FFFFFF;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
}

.easy-steps.home-four .title-block .video-popup i {
    font-size: 40px;
    line-height: 106px;
    color: #fff;
}

.easy-steps.home-four .title-block h3 {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    margin-top: 26px;
}

.easy-steps.home-four .easy-steps-count-box .single-count-box .count-text {
    background: #34964d;
}

.easy-steps.home-four .easy-steps-count-box .single-count-box:before {
    border-top-color: #34964d;
    border-bottom-color: #34964d;
}

.easy-steps.home-four .easy-steps-count-box .single-count-box::after {
    border-left-color: #34964d;
    border-right-color: #34964d;
}

.easy-steps.home-four .easy-steps-count-box .single-count-box i {
    color: #a1d15d;
}

.easy-steps.home-four .overlayed-background:before {
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #185F8B 0%, #34964D 42%, #91C942 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#185F8B', endColorstr='#91C942', GradientType=1);
    /* ie6-9 */
}

/******************************** 
* brand styles
************************************/
.brand-wrapper {
    padding: 80px 0;
}

.brand-wrapper .item {
    line-height: 30px;
}

.brand-wrapper .item img {
    width: auto;
    display: inline-block !important;
    transition: all .4s ease;
}

.brand-wrapper .item:hover img {
    opacity: 0.5;
}

@media (min-width: 1200px) {
    .brand-wrapper .container {
        max-width: 1080px;
    }
}

.brand-style-two-carousel .owl-item {
    border: 1px solid #F2F2F2;
}

.brand-style-two-carousel .owl-item img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all .4s ease;
}

.brand-style-two-carousel .owl-item img:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
}

.brand-style-two-carousel .owl-dots {
    position: relative;
    display: block !important;
    margin-top: 50px !important;
    line-height: 14px;
}

.brand-style-two-carousel .owl-dots .owl-dot {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: transparent;
    border: 2px solid #CAD0CD;
    margin: 0px 5px;
    padding: 0px;
    border-radius: 50%;
    transition: all .3s ease;
}

.brand-style-two-carousel .owl-dots .owl-dot span {
    display: none;
}

.brand-style-two-carousel .owl-dots .owl-dot:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: "";
    background: #f9a03b;
    border-radius: 50%;
    opacity: 0;
    transition: all .3s ease;
}

.brand-style-two-carousel .owl-dots .owl-dot.active {
    border: 0px solid #CAD0CD;
}

.brand-style-two-carousel .owl-dots .owl-dot.active:before {
    opacity: 1;
}

/******************************** 
* team styles
************************************/
.single-team-style-one {
    margin-bottom: 30px;
}

.single-team-style-one .image-block>img {
    width: 100%;
}

.single-team-style-one .text-block {
    margin-top: 10px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.single-team-style-one .text-block .inner-block {
    text-align: center;
    padding: 25px 0;
    position: relative;
    overflow: hidden;
}

.single-team-style-one .text-block .main-content {
    transition: all .4s ease;
}

.single-team-style-one .text-block .hover-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(48deg, #185F8B 0%, #34964D 42%, #91C942 100%);
    /* w3c */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#185F8B', endColorstr='#91C942', GradientType=1);
    /* ie6-9 */
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 5px;
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: all .4s ease;
}

.single-team-style-one .text-block .hover-content .social a {
    display: inline-block;
    vertical-align: middle;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    text-align: center;
    line-height: 47px;
    color: #83888D;
    font-size: 14px;
    background: #fff;
    transition: all .4s ease;
}

.single-team-style-one .text-block .hover-content .social a:hover {
    color: #91C942;
}

.single-team-style-one .text-block .hover-content .social a+a {
    margin-left: 6px;
}

.single-team-style-one h3 {
    margin: 0;
    color: #3A3A3A;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.single-team-style-one span {
    color: #34964D;
    font-size: 16px;
    display: block;
    line-height: 1em;
}

.single-team-style-one:hover .text-block .main-content {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
}

.single-team-style-one:hover .text-block .hover-content {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

/******************************** 
* video styles
************************************/
.video-box-one {
    position: relative;
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;
}

.video-box-one>img {
    width: 100%;
    border-radius: 5px;
}

.video-box-one .box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.video-box-one .box a.video-popup {
    display: inline-block;
    vertical-align: middle;
    width: 85px;
    height: 85px;
    border: 3px solid #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 79px;
}

.video-box-one .box a.video-popup i {
    font-size: 25px;
    color: #fff;
}

.home-five .video-box-one>img {
    border-radius: 0;
}



/*** 
=============================================
    Featured Style2 Area style
=============================================
***/
.featured-style2-area {
    position: relative;
    display: block;
    margin-top: -100px;
    z-index: 100;
    background: transparent;
}
.single-featured-style1{
    position: relative;
    display: block;    
}
.single-featured-style1 .img-holder{
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 7px;
}
.single-featured-style1 .img-holder img{
    width: 100%;
    transform: scale(1.0);
    transition: all 700ms ease;    
}
.single-featured-style1:hover .img-holder img {
    transform:scale(1.2) rotate(2deg);	
}
.single-featured-style1 .img-holder .static-content{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding-bottom: 53px;
    text-align: center;
}
.single-featured-style1 .img-holder .static-content h3{
    color: #ffffff;
    line-height: 28px;
    font-weight: 400;
}

.single-featured-style1 .img-holder .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #e9b531;
    display: block;
    padding: 0 40px;
    transition: all 700ms ease 100ms;
    opacity: 0;
    transform-style: preserve-3d;
    transform: perspective(1200px) rotateX(-45deg) translateZ(-50px);
}
.single-featured-style1:hover .img-holder .overlay-content{
	transform: perspective(1200px) rotateX(0deg) translateZ(0px);
	opacity: 1;    
}
.single-featured-style1 .img-holder .overlay-content .content{
    display: table;
    width: 100%;
    height: 100%;
}
.single-featured-style1 .img-holder .overlay-content .content .inner{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.single-featured-style1 .img-holder .overlay-content .content .inner h3{
    color: #ffffff;
    line-height: 28px;
    margin: 0 0 17px;
}
.single-featured-style1 .img-holder .overlay-content .content .inner p{
    color: #ffffff;
    margin: 0;
}
.single-featured-style1 .button{
    position: absolute;
    left: 0;
    bottom: -30px;
    right: 0;
}
.single-featured-style1 .button a{
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    background: #f5f5f5;
    border-radius: 50%;
    margin: 0 auto;
    transition: all 700ms ease 400ms;
}
.single-featured-style1:hover .button a{
    background: #ffffff;
    box-shadow: 0px 3px 5px 1px #f4f4f4;
}
.single-featured-style1 .button a span:before {
    position: relative;
    display: block;
    color: #5f5f5f;
    font-size: 20px;
    line-height: 14px;
    transform: rotate(-90deg);
    padding: 23px 0 23px;
    text-align: center;
    transition: all 700ms ease 500ms;
}
.single-featured-style1:hover .button a span:before{
    color: #e9b531;
    transform: rotate(0deg);    
}



/*** 
=============================================
    About Style2 Area style
=============================================
***/
.about-style2-area {
    position: relative;
    display: block;
    padding: 121px 0 92px;
}
.about-style2-image-box {
    position: relative;
    display: block;
    margin-top: 9px;
}
.about-style2-image-box img{
    width: 100%;
}
.about-style2-text{
    position: relative;
    display: block;    
}
.about-style2-text .top{
    position: relative;
    display: block;       
}
.about-style2-text .top .icon{
    width: 80px;
}
.about-style2-text .top .icon span:before{
    color: #009865;
    font-size: 75px;
    line-height: 75px;
}
.about-style2-text .top .icon,
.about-style2-text .top .title{
    display: table-cell;
    vertical-align: middle;
}
.about-style2-text .top .title{
    padding-left: 20px;    
}
.about-style2-text .top .title h1{
    color: #3a3a3a;
    font-size: 40px;
    line-height: 50px;
}
.about-style2-text .inner-content {
    position: relative;
    display: block;
    padding: 34px 0 16px;
}
.about-style2-text .inner-content h3{
    color: #e9b531;
    margin: 0 0 28px;
}
.about-style2-text .inner-content .text{
    position: relative;
    display: block;     
}
.about-style2-text .bottom{
    position: relative;
    display: block;     
}
.about-style2-text .bottom .name{
    width: 200px;    
}
.about-style2-text .bottom .name h3{
    color: #3a3a3a;
    margin: 0 0 3px;
}
.about-style2-text .bottom .name span{
    color: #e9b531;
}
.about-style2-text .bottom .name,
.about-style2-text .bottom .signature{
    display: table-cell;
    vertical-align: middle;      
}
.about-style2-text .bottom .signature{
  
}



/*** 
=============================================
    Fact Counter Style2 Area style
=============================================
***/
.fact-counter-style3-area {
    position: relative;
    display: block;
    padding-bottom: 83px;
}
.faq-title-box{
    position: relative;
    display: block; 
    z-index: 1;
}
.faq-title-box:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ddd;
    content: "";
    z-index: -1;
    margin-top: -1px;
}
.faq-title-box span{
    color: #3a3a3a;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    display: inline-block;
    background: #ffffff;
    padding: 0 45px;
}
.fact-counter.style3 {
    margin-top: 65px;
}
.single-fact-counter-style3{
    position: relative;
    display: inline-block;
    float: left;
    max-width: 390px;
    width: 100%;
}
.single-fact-counter-style3 .title{
    position: relative;
    display: block;
    padding: 0 0 7px;
}
.single-fact-counter-style3 .title h3{
    color: #83888d;
    font-size: 18px;
}
.single-fact-counter-style3 .count-box{
    position: relative;
    display: block;    
}
.single-fact-counter-style3 .count-box h1{
    color: #e9b531;
    font-size: 30px;
    font-weight: 700;
}
.single-fact-counter-style3 .count-box h1 span{
    font-size: 72px;
}
.single-fact-counter-style3 .count-box h1 i:before {
    position: relative;
    display: inline-block;
    top: -4px;
    color: #e9b531;
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
}



/*** 
=============================================
    Service We Offer Area style
=============================================
***/
.service-we-offer-area{
    position: relative;
    display: block; 
    background: #f5f5f5;
    padding-top: 100px;
    padding-bottom: 80px;
}
.single-service-offer-box{
    position: relative;
    display: block;
    background: #ffffff;
    padding: 60px 30px 52px;
    -webkit-box-shadow: 0px 3px 7px 2px #ececec; 
    box-shadow: 0px 3px 7px 2px #ececec;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
.single-service-offer-box:hover{
    -webkit-box-shadow: 0px -2px 7px 2px #f5f5f5; 
    box-shadow: 0px -2px 7px 2px #f5f5f5;
}
.single-service-offer-box .icon {
    position: relative;
    display: block;
    padding: 0 0 30px;
}
.single-service-offer-box .icon span:before{
    color: #009865;
    font-size: 65px;
    line-height: 65px;
}
.single-service-offer-box .title{
    position: relative;
    display: block;
    padding: 0 0 18px;    
}
.single-service-offer-box .title span{
    color: #e9b531;
}
.single-service-offer-box .title h3{
    color: #2e2e2e;
    margin: 2px 0 0;
}
.single-service-offer-box .text{
    position: relative;
    display: block;
    padding-bottom: 24px;
}
.single-service-offer-box .text p{
    margin: 0;
}
.single-service-offer-box .button{
    position: relative;
    display: block;    
}
.single-service-offer-box .button a{
    color: #2e2e2e;
    transition: all 300ms ease 100ms;
}
.single-service-offer-box:hover .button a{
    color: #e9b531;    
}

.service-offer-carousel.owl-carousel .owl-stage-outer {
    padding-bottom: 20px;
}


.service-offer-carousel.owl-carousel .owl-nav {
    position: absolute;
    right: 0;
    top: -120px;
    margin: 0;
    display: block;
}
.service-offer-carousel.owl-carousel .owl-nav [class*="owl-"] {
    position: relative;
    display: inline-block;
    background: #f5f5f5;
    color: #83888d;
    font-size: 26px;
    font-weight: 300;
    width: 60px;
    height: 60px;
    border: 2px solid #e5e4e4;
    line-height: 54px;
    margin: 0;
    padding: 0;
    transition: all 700ms ease 100ms;
    border-radius: 50%;
}
.service-offer-carousel.owl-carousel .owl-nav [class*="owl-"] p {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    color: #009865;
    font-size: 16px;
    font-weight: 700;
    line-height: 58px;
    margin: 0;
    text-transform: capitalize;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: all 700ms ease 200ms;
}
.service-offer-carousel.owl-carousel .owl-nav .owl-next {
    margin-left: 10px;
}
.service-offer-carousel.owl-carousel .owl-nav [class*="owl-"]:hover{
    border-color: #009865;
}
.service-offer-carousel.owl-carousel .owl-nav [class*="owl-"]:hover p{
    transform: scale(1.0); 
}



/*** 
=============================================
    Service We Offer Area style
=============================================
***/
.excellent-projects-area{
    position: relative;
    display: block;
    background: #0a0a0a;
    width: 100%;
    padding: 100px 15px 98px;
}
.excellent-projects-area .sec-title-style2{}
.excellent-projects-area .sec-title-style2 .title{
    color: #ffffff;
}


.single-excellent-project .img-holder{
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 10;
}
.single-excellent-project .img-holder:before,
.single-excellent-project .img-holder:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    transition: all 700ms ease 500ms;
    border-radius: 0px;
    z-index: 1;
}
.single-excellent-project .img-holder:before {
    transform: scale(0, 1);
    transform-origin: bottom center;
    border-top: 2px solid #009865;
    border-bottom: 2px solid #009865;
}
.single-excellent-project:hover .img-holder:before {
    transform: scale(1, 1);
}
.single-excellent-project .img-holder:after {
    transform: scale(1, 0);
    transform-origin: left center;
    border-left: 2px solid #009865;
    border-right: 2px solid #009865;
}
.single-excellent-project:hover .img-holder:after{
    transform: scale(1, 1);    
}

.single-excellent-project .img-holder img{
    width: 100%;
    transform: scale(1.01);
    transition: all 700ms ease;
}
.single-excellent-project:hover .img-holder img {
    transform:scale(1.2) rotate(2deg);	
}
.single-excellent-project .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(9, 9, 9, 0.95);
    transform: scaleX(0);
    border: 2px solid #009865;
    transition: all 700ms ease 100ms;
    z-index: 3;
}
.single-excellent-project:hover .overlay-content{
    transform: scaleY(1);
}
.single-excellent-project .overlay-content .inner-content{
    display: table;
    width: 100%;
    height: 100%;
}
.single-excellent-project .overlay-content .inner-content .links-icons{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    transform: scale(0);
    transition: all 700ms ease 500ms;
}
.single-excellent-project:hover .overlay-content .inner-content .links-icons{
    transform: scale(1.0);    
}
.single-excellent-project .overlay-content .inner-content .links-icons li{
    display: inline-block;
    margin: 0 5.5px;
}
.single-excellent-project .overlay-content .inner-content .links-icons li a span:before{
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    background: #e9b531;
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    line-height: 60px;
    font-weight: 100;
    transition: all 500ms ease;
}
.single-excellent-project .overlay-content .inner-content .links-icons li a:hover span:before{
    background: #009865;    
}
.excellent-projects-area .owl-dots {
    position: relative;
    display: block !important;
    margin-top: 50px !important;
    line-height: 14px;
}
.excellent-projects-area .owl-dots .owl-dot{
    position: relative;
	display: inline-block;
	width: 14px;
	height: 14px;
    background: #0a0a0a;
    border: 2px solid #4c4c4c;
	margin: 0px 5px;
	padding: 0px;
	border-radius: 50%;
	transition: all 500ms ease;
}
.excellent-projects-area .owl-dots .owl-dot span{
	display:none;	
}
.excellent-projects-area .owl-dots .owl-dot:before{
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    content: "";
    background: #e9b531; 
    border-radius: 50%;
    opacity: 0;
    transition: all 500ms ease;
}
.excellent-projects-area .owl-dots .owl-dot.active{
    border: 2px solid #0a0a0a;
}
.excellent-projects-area .owl-dots .owl-dot.active:before{
    opacity: 1;    
}



/*** 
=============================================
    Testimonial Style2 Area style
=============================================
***/
.testimonial-style2-area{
    position: relative;
    display: block;
    padding: 100px 0 100px;
}

.single-testimonial-style2{
    position: relative;
    display: block; 
    border: 1px solid #ececec;
    padding: 50px 30px 41px;
    z-index: 1;
}
.single-testimonial-style2:before{
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border: 2px solid #009865;
    content: "";
    z-index: -1;
    opacity: 0;
    transition: all 700ms ease 900ms;
}
.single-testimonial-style2 .client-info-box{
    position: relative;
    display: block;      
}
.single-testimonial-style2 .client-info-box .image{
    position: relative;
    width: 85px;
    z-index: 1;
}
.single-testimonial-style2 .client-info-box .image:before{
    position: absolute;
    top: -15px;
    right: -15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #009865;
    font-family: 'icomoon';
    content: "\e920";
    color: #ffffff;
    font-size: 20px;
    line-height: 40px;
    font-weight: 400;
    text-align: center;
    display: block;
    z-index: -1;
}
.single-testimonial-style2 .client-info-box .image img{
    border-radius: 50%;
}

.single-testimonial-style2 .client-info-box .image,
.single-testimonial-style2 .client-info-box .title{
    display: table-cell;
    vertical-align: middle;
}
.single-testimonial-style2 .client-info-box .title{
    padding-left: 40px;    
}
.single-testimonial-style2 .client-info-box .title h3{
    color: #3a3a3a;
    margin: 0 0 3px;
}
.single-testimonial-style2 .client-info-box .title span{
    color: #e9b531;
}
.single-testimonial-style2 .text{
    position: relative;
    display: block;
    padding-top: 24px;
}
.single-testimonial-style2 .text p{
    margin: 0;
}

.testimonial-style2-area .inner-content{
	overflow: hidden;
}
.testimonial-style2-area .owl-carousel{
	display: block;
	max-width: 370px;
	width:100%;
	margin: 0 auto;
}
.testimonial-style2-area .owl-carousel .owl-stage-outer{
	overflow: visible;
	padding: 0px 0px 50px;
}
.testimonial-style2-area .owl-carousel .owl-stage-outer .owl-item.active .single-testimonial-style2:before{
    opacity: 1;
}

.testimonial-style2-carousel.owl-carousel .owl-nav {
    position: relative;
    right: 0;
    right: 0;
    margin: 0;
    display: block;
}
.testimonial-style2-carousel.owl-carousel .owl-nav [class*="owl-"] {
    position: relative;
    display: inline-block;
    background: #ffffff;
    color: #83888d;
    font-size: 26px;
    font-weight: 300;
    width: 60px;
    height: 60px;
    border: 2px solid #e5e4e4;
    line-height: 54px;
    margin: 0;
    padding: 0;
    transition: all 700ms ease 100ms;
    border-radius: 50%;
}
.testimonial-style2-carousel.owl-carousel .owl-nav [class*="owl-"] p {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    color: #009865;
    font-size: 16px;
    font-weight: 700;
    line-height: 58px;
    margin: 0;
    text-transform: capitalize;
    font-family: 'TrebuchetMS', sans-serif;
    transition: all 500ms ease;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: all 700ms ease 200ms;
}
.testimonial-style2-carousel.owl-carousel .owl-nav .owl-next {
    margin-left: 10px;
}
.testimonial-style2-carousel.owl-carousel .owl-nav [class*="owl-"]:hover{
    border-color: #009865;
}
.testimonial-style2-carousel.owl-carousel .owl-nav [class*="owl-"]:hover p{
    transform: scale(1.0); 
}



/*** 
=============================================
    Pricing Plan Area style2 style
=============================================
***/
.pricing-plan-area.style2{
    background: #f5f5f5;    
}
.single-price-box.style2 {
    border-top: 2px solid #e9b531;
}
.single-price-box.style2 .table-header .top h3 {
    color: #3a3a3a;
}
.single-price-box.style2 .table-header .top span b {
    color: #009865;
}
.single-price-box.style2 .table-header .package h1 {
    color: #009865;
}
.single-price-box.style2 .button a{
    color: #3a3a3a;
    background: #f5f5f5;
}
.single-price-box.style2:hover .button a{
    color: #ffffff;
    background: #e9b531;
}
.single-price-box.style2 .discount-box {
    background: #e9b531;
}



/*** 
=============================================
    Discuss With Expert Area style
=============================================
***/
.discuss-with-expert-area {
    position: relative;
    display: block;
    box-shadow: 0px 4px 10px 1px rgba(237,237,237,1);
    -webkit-box-shadow: 0px 4px 10px 1px rgba(237,237,237,1);
    -moz-box-shadow: 0px 4px 10px 1px rgba(237,237,237,1);
    margin: 0px 0 20px;
}

.contact-details{
    position: relative;
    display: block;
    margin-right: 30px;
}
.contact-details img{
    max-width: none;
    float: right;
}

.contact-details .overlay-content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.contact-details .overlay-content .box{
    display: table;
    width: 100%;
    height: 100%;
}
.contact-details .overlay-content .box .inner{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.contact-details .overlay-content .box .inner .info-box{
    display: inline-block;
    background: #ffffff;
    text-align: left;
    padding: 57px 40px 59px;
    border-radius: 5px;
}
.contact-details .overlay-content .box .inner .info-box h3{
    color: #3a3a3a;
    font-size: 24px;
    font-weight: 600;
}
.contact-details .overlay-content .box .inner .info-box ul{
    position: relative;
    display: block;
    padding-top: 30px;
}
.contact-details .overlay-content .box .inner .info-box ul li{
    position: relative;
    display: block;
    padding-left: 35px;
    margin-bottom: 16px;
}
.contact-details .overlay-content .box .inner .info-box ul li:last-child{
    margin-bottom: 0;
}
.contact-details .overlay-content .box .inner .info-box ul li .icon {
    position: absolute;
    top: 4px;
    left: 0;
}
.contact-details .overlay-content .box .inner .info-box ul li .icon span:before{
    color: #009865;
    font-size: 20px;
    line-height: 20px;
}
.contact-details .overlay-content .box .inner .info-box ul li .text{}
.contact-details .overlay-content .box .inner .info-box ul li .text p{
    margin: 0;
}



.discuss-form-box {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}
.discuss-form-box .outer-box{
    display: table;
    width: 100%;
    height: 100%;
}
.discuss-form-box .outer-box .inner-content{
    display: table-cell;
    vertical-align: middle;
}
.discuss-form-box .sec-title-style2{}
.discuss-form-box .sec-title-style2 .title {
    font-size: 30px;
    line-height: 40px;
    top: -8px;
    margin: 0 0 6px;
}

.discuss-form{
    position: relative;
    display: block;    
}
.discuss-form .single-box{
    position: relative;
    display: block;
}
.discuss-form input[type="text"], 
.discuss-form input[type="email"], 
.discuss-form textarea {
    position: relative;
    display: block;
    padding: 0 20px;
    border: 1px solid #ececec;
    background: #ffffff;
    color: #83888d;
    font-size: 16px;
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    font-weight: 400;
    border-radius: 0px;
    transition: all 500ms ease; 
}
.discuss-form input[type="text"]:focus{
    border-color: #e9b531;    
}
.discuss-form input[type="email"]:focus{
    border-color: #e9b531;     
}
.discuss-form .single-box button{
    color: #ffffff;
    background: #e9b531;
    display: block;
    width: 100%;
    border-radius: 30px;
}
.discuss-form .single-box button:hover{
    background: #2e8806;
}
.discuss-form input::-webkit-input-placeholder {
    color: #83888d;
}
.discuss-form input:-moz-placeholder {
    color: #83888d;
}
.discuss-form input::-moz-placeholder {
    color: #83888d;
}
.discuss-form input:-ms-input-placeholder {
    color: #83888d;
}

.discuss-form .single-box .bootstrap-select {
    position: relative;
    display: block;
}
.discuss-form .single-box .bootstrap-select>.dropdown-toggle {
    outline: none !important;
    border-radius: 0px;
    border: 1px solid #ececec;
    height: 60px;
    background-color: transparent;
    padding: 0;
    padding-left: 20px;
    color: #83888d;
    line-height: 60px;
    margin-bottom: 0;
    margin: 0;
    box-shadow: none !important;
}
.discuss-form .single-box .bootstrap-select>.dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 20px;
    width: auto;
    height: auto;
    font-family: 'FontAwesome';
    content: '\f107';
    color: #83888d;
    font-size: 18px;
    transform: translateY(-50%);
    margin: 0;
    border: none;
}
.discuss-form .single-box .bootstrap-select .dropdown-menu {
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: 0px solid #ddd;
    background: #f9f9f9;
    font-size: 16px;
    color: #000000;
}
.discuss-form .single-box .dropdown-item {
    display: block;
    width: 100%;
    padding: 9px 20px 9px;
    font-weight: 400;
    color: #222222;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    transition: all 500ms ease;
}
.discuss-form .single-box .dropdown-item.active,
.discuss-form .single-box .dropdown-item:active {
    background: #e9b531;
    color: #fff;
}
.discuss-form .single-box .dropdown-item:hover {
    background: #e9b531;
    color: #fff;
}



/*** 
=============================================
    Latest Blog Area Style6 style
=============================================
***/
.latest-blog-area.style6{
    padding-top: 80px;
    padding-bottom: 50px;    
}
.single-blog-post.style6{
    margin-bottom: 42px;
}
.single-blog-post.style6 .post-date {
    position: absolute;
    top: 20px;
    left: 20px;
    bottom: auto;
    background: #e9b531;
}
.single-blog-post.style6 .overlay-style-two:before {
    background: rgba(9, 9, 9, 0.85);
}
.single-blog-post.style6 .overlay-style-two:after {
    background: rgba(9, 9, 9, 0.85);
}
.single-blog-post.style6 .text-holder .blog-title a{
    color: #3a3a3a;
}
.single-blog-post.style6 .text-holder .blog-title a:hover{
    color: #e9b531;
}
.single-blog-post.style6 .meta-box .meta-info li a b {
    color: #009865;
}
.single-blog-post.style6 .meta-box .meta-info li a:hover{
    color: #009865;
}
.single-blog-post.style6 .text-holder .text a.btn-two {
    color: #3a3a3a;
}



/*** 
=============================================
    Footer Area Style2 style
=============================================
***/
.footer-area.style2 {
    background: #0a0a0a;
    padding-top: 100px;
    padding-bottom: 91px;
}
.single-footer-widget .masstech-info-box{
    position: relative;
    display: block;
}
.single-footer-widget .masstech-info-box .footer-logo{
    display: block;
    margin-bottom: 34px;
}
.single-footer-widget .masstech-info-box .footer-logo a{
    display: inline-block;
}
.single-footer-widget .masstech-info-box .text{
    position: relative;
    display: block;
    padding-bottom: 10px;
}
.single-footer-widget .masstech-info-box .text p{
    color: #888888;
}
.masstech-info-box .footer-social-links {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
}
.masstech-info-box .footer-social-links .social-info{
    position: relative;
    display: block; 
    overflow: hidden;
}
.masstech-info-box .footer-social-links .social-info li{
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 25px;
}
.masstech-info-box .footer-social-links .social-info li:last-child{
    margin-right: 0;
}
.masstech-info-box .footer-social-links .social-info li a i{
    color: #ffffff;
    font-size: 14px;
    transition: all 500ms ease;
}
.masstech-info-box .footer-social-links .social-info li a:hover i{
    color: #009865;
}

.single-footer-widget .title-style2 {
    position: relative;
    display: block;
    padding-bottom: 26px;
    margin-top: -5px;
    margin-bottom: 33px;
}
.single-footer-widget .title-style2:before{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50px;
    height: 3px;
    background: #e9b531;
    content: "";
}
.single-footer-widget .title-style2 h3 {
    color: #ffffff;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    text-transform: capitalize;
}


.single-footer-widget .usefull-links-style2{
    position: relative;
    display: block;
}
.single-footer-widget .usefull-links-style2 ul{
    position: relative;
    display: block;
    overflow: hidden;
}
.single-footer-widget .usefull-links-style2 ul li {
    line-height: 28px;
    margin-bottom: 12px;
}
.single-footer-widget .usefull-links-style2 ul li:last-child{
    margin-bottom: 0;
}
.single-footer-widget .usefull-links-style2 ul li a{
    position: relative;
    display: block;
    color: #888888;
    font-size: 16px;
    font-weight: 400;
    transition: all 500ms ease;
}
.single-footer-widget .usefull-links-style2 ul li a:hover{
    color: #ffffff;
}

.single-footer-widget .recent-posts {
    position: relative;
    display: block;
    padding-top: 6px;
}
.single-footer-widget .recent-posts li {
    position: relative;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 23px;
    margin-bottom: 29px;
}
.single-footer-widget .recent-posts li:last-child{
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.single-footer-widget .recent-posts li p{
    color: #888888;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
}
.single-footer-widget .recent-posts li p i{
    color: #009865;
    display: inline-block;
    padding-right: 10px;
}
.single-footer-widget .recent-posts li h3{
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    margin: 12px 0 0;
}
.single-footer-widget .recent-posts li h3 a{
    color: #ffffff; 
    transition: all 500ms ease;
}
.single-footer-widget .recent-posts li h3 a:hover{
    color: #009865;
}


.single-footer-widget .case-studies-style2 {
    position: relative;
    display: block;
    overflow: hidden;
    padding-top: 7px;
}
.single-footer-widget .case-studies-style2 li {
    position: relative;
    display: inline-block;
    float: left;
}
.single-footer-widget .case-studies-style2 li .img-holder {
    position: relative;
    display: block;
    overflow: hidden;
}
.single-footer-widget .case-studies-style2 li .img-holder img{
    transition: all 0.5s ease-in-out 0.6s;
    width: 100%;
    transform: scale(1.0, 1.0); 
}
.single-footer-widget .case-studies-style2 li:hover .img-holder img{
    transform: scale(1.2, 1.2);
} 
.single-footer-widget .case-studies-style2 li .img-holder .overlay-style-one{
    background: rgba(9, 9, 9, 0.90);    
}
.single-footer-widget .case-studies-style2 li:hover .img-holder .overlay-style-one{
    opacity: 1;    
}
.single-footer-widget .case-studies-style2 li .img-holder .overlay-style-one .content a span:before{
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    filter: alpha(opacity=0);
    opacity: 0;
    transform: scale(5);
    transition: all 0.5s ease-in-out 0.3s;
}
.single-footer-widget .case-studies-style2 li:hover .img-holder .overlay-style-one .content a span:before{
    filter: alpha(opacity=100);
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.1s;   
}



/*** 
=============================================
    Footer Bottom Area style2 Style      
=============================================
***/
.footer-bottom-area-style2 {
    position: relative;
    display: block;
    background: #141414;
    padding: 29px 0 29px;
}

.footer-bottom-area-style2 .copyright-text {
    margin: 0;
}
.footer-bottom-area-style2 .copyright-text p {
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin: 0;
}
.footer-bottom-area-style2 .copyright-text p a {
    color: #e9b531;
    font-weight: 500;
    transition: all 500ms ease;
}
.footer-bottom-area-style2 .copyright-text p a:hover{
    color: #ffffff;
}

.footer-menu-right {
    position: relative;
    display: block;
}
.footer-menu-right li {
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 30px;
    line-height: 22px;
}
.footer-menu-right li:last-child{
    margin-right: 0;
}
.footer-menu-right li a{
    position: relative;
    display: block;
    color: #888888;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    transition: all 500ms ease;
    transition-delay: .2s;
}
.footer-menu-right li a:hover{
    color: #ffffff;
}















