	/*
  	Flaticon icon font: Flaticon
  	Creation date: 17/03/2019 10:13
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../../../assets/fonts/flaticon/Flaticon.eot");
  src: url("../../../assets/fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../../../assets/fonts/flaticon/Flaticon.woff2") format("woff2"),
       url("../../../assets/fonts/flaticon/Flaticon.woff") format("woff"),
       url("../../../assets/fonts/flaticon/Flaticon.ttf") format("truetype"),
       url("../../../assets/fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 0px;
}

.flaticon-add:before { content: "\f100"; }
.flaticon-percentage:before { content: "\f101"; }
.flaticon-information:before { content: "\f102"; }
.flaticon-envelope:before { content: "\f103"; }
.flaticon-refresh:before { content: "\f104"; }
.flaticon-cancel-button:before { content: "\f105"; }